







































import Vue, { PropType } from "vue";
import SingleBarChart from "@/components/single-bar-chart";
import { Nullable, Project, ProcessEntry, ProcessState } from "@/types";

import projectStates from "@/apps/office/static/project-states.json";
import moProjectStates from "@/apps/office/static/mo-states.json";
import contractStates from "@/apps/office/static/contract-states.json";
import installationStates from "@/apps/office/static/installation-states.json";
import planningStates from "@/apps/office/static/planning-states.json";
import postInstallationStates from "@/apps/office/static/post-installation-states.json";
import postInstallationIssues from "@/apps/office/static/post-installation-issues.json";
import ProjectStatus from "@/apps/office/components/project-status";

export default Vue.extend({
  components: {
    ProjectStatus,
    SingleBarChart
  },

  data() {
    return {
      selected: [],
      regularProcess: projectStates as ProcessEntry[],
      moProcess: moProjectStates as ProcessEntry[],
      contractStates: contractStates as ProcessState[],
      installationStates: installationStates as ProcessState[],
      planningStates: planningStates as ProcessState[],
      postInstallationStates: postInstallationStates as ProcessState[],
      postInstallationIssueStates: postInstallationIssues as ProcessState[],
      tab: 0,
      fetchTypesRegular: [
        "project",
        "installation",
        "contract",
        "planning",
        "postInstallation"
      ],
      fetchTypesMonitoringOnly: ["project", "planning"]
    };
  },

  props: {
    project: Object as PropType<Project>
  },

  computed: {
    values(): ProcessState[] {
      return this.$store.getters[`projects/progress/project/get:items`](
        this.project.id
      );
    },

    historicalValues(): ProcessState[] {
      return this.project?.progress ?? [];
    },

    installationValues(): ProcessState[] {
      const values: ProcessState[] = this.$store.getters[
        "projects/progress/installation/get:items"
      ](this.project.id);

      return this.installationStates.map(state => ({
        ...state,
        count: values.find(v => v.state === state.state)?.count || 0
      }));
    },

    contractValues(): ProcessState[] {
      const values: ProcessState[] = this.$store.getters[
        "projects/progress/contract/get:items"
      ](this.project.id);

      return this.contractStates.map(state => ({
        ...state,
        count: values.find(v => v.state === state.state)?.count || 0
      }));
    },

    planningValues(): ProcessState[] {
      const values: ProcessState[] = this.$store.getters[
        "projects/progress/planning/get:items"
      ](this.project.id);

      return this.planningStates.map(state => ({
        ...state,
        count: values.find(v => v.state === state.state)?.count || 0
      }));
    },

    postInstallationValues(): ProcessState[] {
      const values: ProcessState[] = this.$store.getters[
        "projects/progress/postInstallation/get:items"
      ](this.project.id);

      return this.postInstallationStates.map(state => ({
        ...state,
        count: values.find(v => v.state === state.state)?.count || 0
      }));
    },

    postInstallationIssues(): ProcessState[] {
      const values: ProcessState[] = this.$store.getters[
        "projects/issues/postInstallation/get:items"
      ](this.project.id);

      return this.postInstallationIssueStates.map(state => ({
        ...state,
        count: values.find(v => v.state === state.state)?.count || 0
      }));
    },

    substates() {
      return (code: string): ProcessState[] | undefined => {
        switch (code) {
          case "P230":
            return this.installationValues;
          case "P220":
            return this.contractValues;
          case "P221":
            return this.planningValues;
          case "P240":
            return [
              ...this.postInstallationValues,
              ...this.postInstallationIssues
            ];
          case "M200":
            return this.planningValues;
          default:
            return undefined;
        }
      };
    },

    monitoringOnly(): boolean {
      return this.project?.installationType === 2;
    },

    process(): ProcessEntry[] {
      return this.monitoringOnly ? this.moProcess : this.regularProcess;
    },

    processWithValues(): ProcessEntry[] {
      return this.process.map(entry => ({
        ...entry,
        value: this.values.find(v => v.state === entry.code)?.count ?? 0,
        substates: this.substates(entry.code!) ?? []
      }));
    },

    historicalProcessWitValues(): ProcessEntry[] {
      return this.process.map(entry => ({
        ...entry,
        value:
          this.historicalValues.find(v => v.state === entry.code)?.count ?? 0,
        substates: this.substates(entry.code!) ?? []
      }));
    },

    formatNumber() {
      return (val: number): string => val.toLocaleString("nl-NL");
    },

    totalInProcess(): number {
      return this.processWithValues.reduce((sum, val) => (sum += val.value), 0);
    },

    totalInHistory(): number {
      return this.historicalProcessWitValues.reduce(
        (sum, val) => (sum += val.value),
        0
      );
    },

    barChart(): any[] {
      const items =
        this.tab === 0
          ? this.processWithValues
          : this.historicalProcessWitValues;

      return items
        .filter(item => item.active)
        .map(item => ({
          text: this.$t(item.label).toString(),
          value: item.value,
          color: item.color
        }))
        .filter(item => item.value > 0);
    }
  },

  watch: {
    project: {
      handler(val: Project) {
        if (val?.status === 2) {
          this.tab = 1;
        }

        if (val.installationType != 2) {
          this.fetchTypesRegular.forEach(type => {
            this.$store.dispatch(`projects/progress/${type}/fetch:all`, {
              relationId: val.id
            });
          });
          this.$store.dispatch("projects/issues/postInstallation/fetch:all", {
            relationId: val.id
          });
        } else {
          this.fetchTypesMonitoringOnly.forEach(type => {
            this.$store.dispatch(`projects/progress/${type}/fetch:all`, {
              relationId: val.id
            });
          });
        }
      },
      immediate: true
    }
  },

  methods: {
    // TODO: is this even used?
    navToHouses(code: Nullable<string>) {
      this.$router.push({
        path: `/projecten/${this.$router.currentRoute.params.id}/woningen/huidig`,
        query: {
          projectStates: [code === null ? "0" : code, ""]
        }
      });
    }
  }
});
