import { ItemId, BaseItem, EndpointStrategy, Endpoint } from './base';
import { AsyncModule } from 'vuex-async-mutations';
import { FetchParams } from '@/types';

export const module = <T extends BaseItem>(
  endpoint: string | EndpointStrategy,
  idProp: ItemId = 'id',
) =>
  ({
    actionsAsync: {
      async ['item:create'](
        { commit, dispatch, getters },
        { item, relationId }: { item: T; relationId?: string },
      ) {
        let url: Endpoint;

        if (typeof endpoint !== 'string') {
          url = endpoint(relationId);
        } else {
          url = { endpoint };
        }

        item = await this.$axios.post<T>(url.endpoint, item, {
          params: url.params,
        });

        commit('item:update', item);

        dispatch('fetch:all:page', getters.lastFetch);

        return item;
      },

      async ['item:update']({ commit }, item) {
        item = await this.$axios.patch<T>(`${endpoint}/${item[idProp]}`, item);

        commit('item:update', item);

        return item;
      },

      async ['item:delete']({ commit }, item) {
        item = await this.$axios.delete(`${endpoint}/${item[idProp]}`);

        commit('item:delete', item);

        return item;
      },
    },
  } as AsyncModule<any, any>);

export default module;
