import { DirectiveFunction } from 'vue';
import { Store } from 'vuex/types';

export default (store: Store<any>): DirectiveFunction =>
  function(el, binding) {
    const roles: string[] = store.getters['profile/roles'] || [];
    const requiredRoles: string[] = binding.value || [];
    const isAuthenticated: boolean = store.getters['account/isAuthenticated'];

    if (
      !isAuthenticated ||
      (requiredRoles.length && !roles.some(role => requiredRoles.includes(role)))
    ) {
      el.style.display = 'none';
    } else {
      el.style.removeProperty('display');
    }
  };
