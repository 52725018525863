import { ActionDialogAttributes, TablePreset } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import PresetList from './PresetList.vue';
import SelectFile from './SelectFile.vue';

import { isTablePresets } from '@/util/guards';

type FormData = {
  selected: TablePreset[];
  items: TablePreset[];
};

type FormAttrs = ActionDialogAttributes<TablePreset> & { settings: string };

export default WithSteps<FormData, FormAttrs>({
  title: 'office.dialogs.import.title',

  width: 500,

  formData() {
    return {
      selected: [],
      items: [],
    };
  },

  steps: [
    {
      component: SelectFile,

      isValid() {
        return this.$data.items.length > 0;
      },

      props: {
        accept: 'application/json',
        guard: isTablePresets,
      },

      value: {
        get() {
          return this.$attrs.items;
        },

        set(items: TablePreset[]) {
          this.$data.items = items;
          this.$data.selected = items.slice();
        },
      },
    },
    {
      component: PresetList,

      isValid() {
        return this.$data.selected.length > 0;
      },

      props() {
        return {
          items: this.$data.items,
        };
      },

      value: {
        get() {
          return this.$data.selected;
        },

        set(items: TablePreset[]) {
          this.$data.selected = items;
        },
      },

      async afterStep() {
        await this.$store.dispatch(`${this.$attrs.settings}/preset:import`, this.$data.selected);
      },

      labels: {
        action: 'office.dialogs.import.action',
      },
    },
  ],
});
