var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h4 mt-8 mb-5 font-weight-bold",domProps:{"textContent":_vm._s(_vm.$t('office.labels.projectForecast'))}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"items":_vm.activeProjects,"multiple":"","outlined":"","label":_vm.$t('office.labels.fields.visibleProjects'),"loading":_vm.loading,"item-text":"region","return-object":"","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',{staticClass:"mt-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.selectedProjects.length)+" / "+_vm._s(_vm.activeProjects.length)+" projecten")]):_vm._e()]}},{key:"empty",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',[_vm._v("Geen projecten")]):_vm._e()]}}]),model:{value:(_vm.selectedProjects),callback:function ($$v) {_vm.selectedProjects=$$v},expression:"selectedProjects"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"show-week":"","clearable":!!_vm.lastUsed.params.startDate,"allowed-dates":_vm.date.onlyMondays,"label":_vm.$t('office.labels.fields.startedAt')},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":_vm.$t('office.labels.fields.amountWeeks'),"outlined":"","type":"number","min":"1","max":"100","step":"1"},model:{value:(_vm.weeks),callback:function ($$v) {_vm.weeks=_vm._n($$v)},expression:"weeks"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"items":_vm.activeInstallers,"multiple":"","outlined":"","label":_vm.$t('office.labels.fields.visibleInstallers'),"loading":_vm.loading,"item-text":"name","return-object":"","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',{staticClass:"mt-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.selectedInstallers.length)+" / "+_vm._s(_vm.activeInstallers.length)+" installateur(s)")]):_vm._e()]}},{key:"empty",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',[_vm._v("Geen installateurs")]):_vm._e()]}}]),model:{value:(_vm.selectedInstallers),callback:function ($$v) {_vm.selectedInstallers=$$v},expression:"selectedInstallers"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-combobox',{attrs:{"items":_vm.activeHocos,"multiple":"","outlined":"","label":_vm.$t('office.labels.fields.visibleHousingcoorporations'),"loading":_vm.loading,"item-text":"name","return-object":"","item-value":"id","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',{staticClass:"mt-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.selectedHocos.length)+" / "+_vm._s(_vm.activeHocos.length)+" Woco(s)")]):_vm._e()]}},{key:"empty",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!index)?_c('v-chip',[_vm._v("Geen woco's")]):_vm._e()]}}]),model:{value:(_vm.selectedHocos),callback:function ($$v) {_vm.selectedHocos=$$v},expression:"selectedHocos"}})],1)],1),_c('v-card',{staticClass:"pa-2"},[_c('projects-forecast',{attrs:{"data":_vm.forecast,"start":_vm.start,"number-of-weeks":_vm.weeks,"projects":_vm.selectedProjectIds,"project-details":_vm.projects,"installers":_vm.selectedInstallerIds,"hocos":_vm.selectedHocoIds,"preview":_vm.loading,"totals":_vm.totals,"show-quarters":"","show-totals":"","show-stats":""},on:{"update:totals":function($event){_vm.totals=$event}}})],1),(_vm.totals)?_c('div',{staticClass:"mt-5 dashboard-container"},[_c('DashboardNumberBox',{attrs:{"title":_vm.$t('office.labels.fields.workStockDate'),"value":_vm.formatted(_vm.totals.workQueue.value)}}),_c('DashboardNumberBox',{attrs:{"title":_vm.$t('office.labels.fields.workStockPeriod'),"value":_vm.formatted(_vm.totals.distributedWorkQueue.value)}}),_c('DashboardNumberBox',{attrs:{"title":_vm.$t('office.labels.fields.anticipatedWorkStock'),"value":_vm.formatted(_vm.totals.expectedWorkQueue.value)}}),_c('DashboardNumberBox',{attrs:{"title":_vm.$t('office.labels.fields.planned'),"value":_vm.formatted(_vm.totals.planned.value)}}),_c('DashboardNumberBox',{attrs:{"title":_vm.$t('office.labels.fields.approved'),"value":_vm.formatted(_vm.totals.finished.value)}}),_c('DashboardNumberBox',{attrs:{"target":_vm.formatted(_vm.totals.targets),"title":_vm.$t('office.labels.fields.total'),"value":_vm.formatted(_vm.totalOfTotals),"year":_vm.totals.year}})],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }