<template>
  <v-dialog :value="value" persistent :width="width" :max-width="maxWidth">
    <v-card class="pa-0">
      <v-alert
        border="bottom"
        text
        prominent
        class="pa-10 ma-0 text-h6"
        elevation="2"
        icon="mdi-alert-outline"
        color="warning"
      >
        <v-row align="center">
          <v-col class="grow">
            <span v-t="warningText" />
          </v-col>
          <v-col class="shrink">
            <v-btn
              :disabled="disabled"
              large
              @click="cancelBatch"
              :loading="checkingProgress"
              v-t="cancelText"
            >
              <v-icon v-if="!batchEnded" left>mdi-alert-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn
              :disabled="disabled"
              large
              @click="$emit('close')"
              v-t="$t('office.dialogs.batchProgress.no')"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  props: {
    value: Boolean,
    width: {
      type: [String, Number],
      default: '80vw',
    },

    maxWidth: {
      type: [String, Number],
      default: '1200',
    },
    batch: Object,
  },
  data() {
    return {
      batchEnded: false,
      disabled: false,
      checkingProgress: false,
    };
  },

  watch: {
    batch(val) {
      if (!this.checkingProgress && val.finishedAt) {
        this.$emit('close');
      }
    },
  },

  computed: {
    warningText() {
      return this.$t('office.dialogs.batchProgress.warningText');
    },
    cancelText() {
      const batchEnded = this.$t('office.dialogs.batchProgress.cancelText');
      const yes = this.$t('office.dialogs.batchProgress.yes');
      return this.batchEnded ? batchEnded : yes;
    },
  },

  methods: {
    async checkProgress() {
      this.checkingProgress = true;
      this.disabled = true;

      const batch = await this.$store.dispatch('batches/batch:delete', {
        batchId: this.batch.id,
      });

      if (!batch.cancelledAt) {
        window.setTimeout(() => this.checkProgress(), 3000);
      } else {
        this.batchEnded = true;
        this.checkingProgress = false;
        setTimeout(() => {
          this.$emit('cancelBatch');
        }, 2000);
      }
    },
    async cancelBatch() {
      await this.checkProgress();
    },
  },
});
</script>
