import { AsyncModule } from 'vuex-async-mutations';

export default {
  namespaced: true,

  async: true,

  modules: {
    dictionaries: {
      namespaced: true,

      actions: {
        ['fetch']() {
          this.dispatch('dictionaries/tags/fetch');
          this.dispatch('dictionaries/panels/fetch');
          this.dispatch('dictionaries/inverters/fetch');
          this.dispatch('dictionaries/inverterConfigurations/fetch');
          this.dispatch('dictionaries/housingCooperatives/fetch');
        },
      },
    },
  },
} as AsyncModule<any, any>;
