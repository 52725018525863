import { $t } from "@/plugins/i18n";
import { LocaleMessages } from "vue-i18n";

type ValidationResult = true | string | LocaleMessages;
type ValidationRule =
  | ((value: string) => ValidationResult)
  | ((value: number) => ValidationResult);
type CustomValidationRules = (...args: any[]) => ValidationRule;

export type ValidationRules = Record<
  string,
  CustomValidationRules | ValidationRule
>;

export const rules: ValidationRules = {
  required: (value?: string) => !!value || $t("validation.required"),
  requiredNumber: (value: number) =>
    typeof value === "number" || $t("validation.required"),

  requiredCustom: (message: string) => (value?: string) =>
    !!value || $t(message),

  enum: (value: number) => value >= 0 || $t("validation.required"),

  range: (min: number, max: number) => (value: number | undefined | "") =>
    value === "" ||
    value === undefined ||
    (value >= min && value <= max) ||
    $t("validation.range", [min, max]),
  year: (value: number) =>
    (value >= 2000 && value <= 2100) || $t("office.validation.projectYear"),
  password: (v: string) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(v) ||
    $t("validation.password.valid"),
  passwordRequired: (v: string) => !!v || $t("validation.password.required"),

  email: (v: string) => /.+@.+/.test(v) || $t("validation.email.valid"),
  zipcode: (v: string) =>
    /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) ||
    $t("validation.zipcode.valid"),

  houseNumber: (v: string) =>
    /^\d+$/.test(v) || $t("validation.houseNumber.valid"),

  meterNumber: (v: string) =>
    /^\d{4}$/.test(v) || $t("validation.meterNumber.valid"),

  isDomain: (domain: string) => (value?: string) =>
    value === "" ||
    value === undefined ||
    value.startsWith(domain) ||
    $t("validation.isDomain", [domain]),

  url: (value?: string) =>
    !value ||
    !!value?.match(
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    ) ||
    $t("validation.url")
};

export default rules;
