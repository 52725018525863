import { AsyncModule } from 'vuex-async-mutations';

import details from '@/store/base.details';
import crud from '@/store/base.crud';
import paginated from '@/store/base.items.paginated';
import table from '@/store/modules/tables';
import { InstallerCapacity, Project } from '@/types';

import columns from '@/apps/office/components/table/planning/capacity/columns';
import presets from '@/apps/office/components/table/planning/capacity/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<Project>('/installerCapacity'),
    items: paginated<Project>(id => ({
      endpoint: `/installerCapacity`,
      params: { installerId: id! },
    })),
    crud: crud<Project>('/installerCapacity'),
    settings: table('planning/installerCapacity', presets, columns, renderers),
    dictionaries: {
      namespaced: true,

      actions: {
        ['fetch']() {
          this.dispatch('dictionaries/installers/fetch');
        },
      },
    },
  },

  getters: {},

  actionsAsync: {
    fill: {
      handler(context, value: InstallerCapacity) {
        return this.$axios.post('/installerCapacity/fill', value);
      },
    },
  },
} as AsyncModule<any, any>;
