












import Vue from 'vue';
import AppBar from '@/apps/office/containers/layout/AppBar.vue';
import Toastr from '@/components/toastr';

export default Vue.extend({
  components: {
    AppBar,
    Toastr,
  },
});
