import { RouteConfig } from 'vue-router';

import capacityRoutes from './routes.planning.capacity';
import forecastRoutes from './routes.planning.forecast';
import wocotargets from './routes.planning.targets';
import calendar from './routes.planning.calendar';

export default [
  {
    path: '/planning',
    redirect: '/planning/calendar',
  },
  ...forecastRoutes,
  ...capacityRoutes,
  ...wocotargets,
  ...calendar,
] as RouteConfig[];
