<template>
  <div class="relative" :ref="`editableEvent-${index}`" :style="gridColumnStyle">
    <div class="events-edit-mode d-flex justify-center align-center">
      <v-icon color="#00838f" :class="`event-icon-color-${event.type}`">{{ event.icon }}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: true,
  props: {
    event: {
      type: Object,
    },
    weeks: {
      type: Array,
    },
    editableEvents: {
      type: Array,
    },
    index: {
      type: Number,
    },
    container: {
      default: {},
    },
  },

  mounted() {
    this.setup();
  },

  data() {
    return {
      ref: '',
      gridArea: { start: null, end: null },
      dragging: false,
    };
  },

  watch: {
    event() {
      this.setup();
    },
  },

  computed: {
    gridColumnStyle() {
      return {
        'grid-area': `1 / ${this.gridArea.start} / span 1 / span 1`,
      };
    },

    start() {
      const week = this.weeks[this.gridArea.start - 1];

      return week ? week.number : 0;
    },
  },

  methods: {
    setup() {
      this.gridArea = { start: this.event.start, end: 'span 1' };
      this.ref = this.$refs[`editableEvent-${this.index}`];
    },

    base(e) {
      return {
        weeks: 0,
        start: e.clientX || 0,
        columnStart: Number(this.ref.style.gridColumnStart),
        period: this.period,
        fixedWeeks: 0,
      };
    },

    resize(e) {
      this.dragging = true;
      const updateGridArea = val => (this.gridArea = val);
      const updateDates = () => this.updateDates(this.event, fixedWeeks);

      let { fixedWeeks, weeks, start, columnStart } = this.base(e);

      let outSideBorder = this.weeks.length;

      this.container.onmousemove = e => {
        weeks = Math.round((e.pageX - start) / 32);

        let border = columnStart + weeks;

        if (border >= 1 && border <= outSideBorder) {
          fixedWeeks = weeks;

          let gridArea = { start: columnStart + fixedWeeks, end: 'span 1' };

          updateGridArea(gridArea);
        }
      };
      this.container.onmouseup = () => {
        this.container.onmousemove = this.container.onmouseup = null;

        this.dragging = false;
        updateDates();
      };
    },

    updateDates(periodToUpdate, weeks) {
      const updatedEvents = this.editableEvents.map(event => {
        if (event === periodToUpdate) {
          return {
            ...event,
            start: event.start + weeks,
          };
        }

        return event;
      });

      this.$emit('update:editableEvents', updatedEvents);
    },
  },
};
</script>

<style lang="scss">
.event-tooltip {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 30px;
  background-color: #00838f;
  border-radius: 8px !important;
}

.relative {
  position: relative;
}

.events-edit-mode {
  z-index: 0;
  border-radius: 3px;
  background-color: white;
  border: 2px solid #00838f !important;
  box-shadow: 0px 2px 7px 0px #00838f;
  position: relative;
  height: 25px;
}

.dragEvent {
  cursor: move;
  width: 100%;
  background-color: inherit;
}
</style>
