import details from '@/store/base.details';
import paginated from '@/store/base.items.paginated';
import crud from '@/store/base.crud';
import { House } from '@/types';
import { AsyncModule } from 'vuex-async-mutations';

import table from '@/store/modules/tables';

import columns from '@/apps/office/components/table/batches/columns';
import presets from '@/apps/office/components/table/batches/presets';
import renderers from '@/apps/office/components/table/batches/renderers';

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<House>('/batches'),
    items: paginated<House>('/batches'),
    crud: crud<House>('/batches'),
    settings: table('batches', presets, columns, renderers),
  },

  actionsAsync: {
    ['batch:delete']: {
      handler(context, { batchId }: { batchId: string }) {
        return this.$axios.delete(`batches/${batchId}`);
      },
    },
  },
} as AsyncModule<any, any>;
