import { AsyncModule } from 'vuex-async-mutations';

import details from '@/store/base.details';
import crud from '@/store/base.crud';
import paginated from '@/store/base.items.paginated';
import table from '@/store/modules/tables';
import { Installation } from '@/types';
import itemsExport from '@/store/base.items.export';

import columns from '@/apps/office/components/table/installations/regular/columns';
import presets from '@/apps/office/components/table/installations/regular/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';
import map from '@/store/base.items.map';

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<Installation>('/installations'),
    items: paginated<Installation>('/installations/default'),
    mapView: map<Installation>('/installations-mapview/default'),
    crud: crud<Installation>('/installations'),
    settings: table('installations/postalcoderose', presets, columns, renderers),
    export: itemsExport('/installations/default/export'),
    dictionaries: {
      namespaced: true,
      actions: {
        fetch() {
          this.dispatch('dictionaries/installers/fetch');
          this.dispatch('dictionaries/housingCooperatives/fetch');
        },
      },
    },
  },

  getters: {},

  actionsAsync: {},
} as AsyncModule<any, any>;
