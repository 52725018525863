import Vue from 'vue';
import { AsyncModule } from 'vuex-async-mutations';

type ProcessState = {
  stateCode: string;
  count: string;
};

type ProjectProcessState = {
  states: Record<string, ProcessState[]>;
};

const stateFactory = (): ProjectProcessState => ({
  states: {},
});

export const module: AsyncModule<ProjectProcessState, any> = {
  namespaced: true,

  async: true,

  state: stateFactory,

  getters: {
    ['getState'](state) {
      return (uniqid: string): ProcessState[] => state.states[uniqid] || [];
    },
  },

  /* eslint-disable no-param-reassign */
  mutations: {},
  /* eslint-disable no-param-reassign */

  actionsAsync: {
    ['fetch']: {
      handler({ commitAsync }, uniqid: string) {
        return commitAsync(
          this.$axios.get(`/management/projects/${uniqid}/projectProcessStates`),
          uniqid,
        );
      },

      resolved(state, states: ProcessState[], uniqid: string) {
        Vue.set(state.states, uniqid, states);
      },
    },
  },

  actions: {},
};

export default module;
