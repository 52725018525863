import { Store } from 'vuex/types';
import VueI18n from 'vue-i18n/types';

export default (i18n: VueI18n) => (store: Store<any>): void => {
  store.$t = i18n.t.bind(i18n);
};

declare module 'vuex/types' {
  interface Store<S> {
    $t: typeof VueI18n.prototype.t;
  }
}
