import { AsyncModule } from 'vuex-async-mutations';

import details from '@/store/base.details';
import paginated from '@/store/base.items.paginated';
import table from '@/store/modules/tables';
import itemsExport from '@/store/base.items.export';

import houseColumns from '@/apps/office/components/table/houses/other/columns';
import housePresets from '@/apps/office/components/table/houses/other/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

import { House, Project } from '@/types';

export const mod: AsyncModule<any, any> = {
  namespaced: true,

  async: true,

  modules: {
    details: details<House>('/houses', 'pchn'),
    items: paginated<House>(id => ({ endpoint: `/projects/${id!}/houses` }), 'pchn'),
    settings: table('projects/regular/houses', housePresets, houseColumns, renderers),
    export: itemsExport(id => ({ endpoint: `/projects/${id}/houses/export` })),
  },

  actionsAsync: {
    ['bulk:clearForInstallation']: {
      handler(context, { houses, id }: { houses: House[]; id: Number }) {
        return this.$axios.post(`projects/${id}/bulk/clear-for-installation`, {
          pchn: houses.map(({ pchn }) => pchn),
        });
      },
    },

    ['bulk:closeContract']: {
      handler(context, { houses, projectId }: { houses: House[]; projectId: Number }) {
        return this.$axios.post(`projects/${projectId}/bulk/close-contract`, {
          pchn: houses.map(({ pchn }) => pchn),
        });
      },
    },

    ['bulk:deleteContracts']: {
      handler(context, { houses, projectId }: { houses: House[]; projectId: Number }) {
        return this.$axios.post(`projects/${projectId}/bulk/delete-contract`, {
          pchn: houses.map(({ pchn }) => pchn),
        });
      },
    },

    ['bulk:generateContract']: {
      handler(
        context,
        {
          projectId,
          houses,
          panelId,
          inverterConfigurationId,
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        }: {
          projectId: Number;
          houses: House[];
          panelId?: number;
          inverterConfigurationId?: number;
          testimonialCity: string;
          testimonialSentence: string;
          testimonialTenant: string;
        },
      ) {
        return this.$axios.post(`projects/${projectId}/bulk/generate-contract`, {
          pchn: houses.map(({ pchn }) => pchn),
          panelId,
          inverterConfigurationId,
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        });
      },
    },

    ['bulk:generateLaunch']: {
      handler(
        context,
        {
          projectId,
          houses,
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        }: {
          projectId: Number;
          houses: House[];
          testimonialCity: string;
          testimonialSentence: string;
          testimonialTenant: string;
        },
      ) {
        return this.$axios.post(`projects/${projectId}/bulk/generate-launch`, {
          pchn: houses.map(({ pchn }) => pchn),
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        });
      },
    },

    ['preview:contract']: {
      handler(
        context,
        {
          projectId,
          houseId,
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        }: {
          projectId: Number;
          houseId: Number;
          testimonialCity: string;
          testimonialSentence: string;
          testimonialTenant: string;
        },
      ) {
        return this.$axios.post(`/projects/${projectId}/preview-contract/${houseId}`, {
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        });
      },
    },

    ['preview:launch']: {
      handler(
        context,
        {
          projectId,
          houseId,
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        }: {
          projectId: Number;
          houseId: Number;
          testimonialCity: string;
          testimonialSentence: string;
          testimonialTenant: string;
        },
      ) {
        return this.$axios.post(`/projects/${projectId}/preview-launch/${houseId}`, {
          testimonialCity,
          testimonialSentence,
          testimonialTenant,
        });
      },
    },

    ['bulk:transferHouses']: {
      handler(
        context,
        {
          houses,
          toProject,
          fromProject,
        }: { houses: House[]; fromProject: Project; toProject: Project },
      ) {
        return this.$axios.post(`/projects/${fromProject.id}/transfer-houses/${toProject.id}`, {
          pchn: houses.map(({ pchn }) => pchn),
        });
      },
    },

    ['bulk:downloadDocuments']: {
      handler(
        context,
        {
          houses,
          documentType,
          projectId,
        }: { houses: House[]; documentType: any; projectId: Number },
      ) {
        return this.$axios.post(`projects/${projectId}/bulk/download-documents`, {
          pchn: houses.map(({ pchn }) => pchn),
          documentType,
        });
      },
    },
  },
};

export default mod;
