<script>
import { Bar, mixins } from 'vue-chartjs';
import Chart from 'chart.js';

Chart.defaults.global.defaultFontFamily = 'Roboto';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    chart: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.rerender();
  },

  methods: {
    rerender() {
      this.renderChart(this.chartData, this.options);
    },
  },

  computed: {
    charts() {
      return this.$data._chart;
    },
  },

  watch: {
    options() {
      if (!this.charts) return;
      this.charts.options = this.options;
      this.charts.update();
    },

    charts: {
      immediate: true,
      handler(val) {
        this.$emit('update:chart', val);
      },
    },
  },
};
</script>
