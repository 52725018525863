






























import { Column, Project } from "@/types";
import Vue, { PropType } from "vue";
import TableList from "@/components/table-list";

import columns from "@/apps/office/components/table/projects/allprojects/columns";
import columnsMO from "@/apps/office/components/table/projects/monitoring-only/columns";
import renderers from "@/apps/office/components/table/shared/items/renderers";

export default Vue.extend({
  components: {
    TableList
  },

  props: {
    project: Object as PropType<Project>
  },

  data() {
    return {
      renderers
    };
  },

  computed: {
    columns(): Column[] {
      if (this.project.installationType === 2) return columnsMO;

      return columns;
    },

    values(): string[] {
      if (this.project.status == 2 && this.project.installationType === 2) {
        return [
          "expectedHouses",
          "housesCountFinal",
          "finished.expectedInstallations",
          "moInstallationsCount",
          "finished.conversion",
          "actualConversion",
          "contractConversion"
        ];
      } else if (
        this.project.status == 2 &&
        this.project.installationType != 2
      ) {
        return [
          "expectedHouses",
          "housesCountFinal",
          "finished.expectedInstallations",
          "installationsCount",
          "finished.conversion",
          "actualConversion",
          "contractConversion"
        ];
      } else {
        return [
          "expectedHouses",
          "housesCountFinal",
          "finished.expectedInstallations",
          "finished.conversion",
          "actualConversion"
        ];
      }
    }
  }
});
