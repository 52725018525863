var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.project)?_c('v-card',{staticClass:"pa-4 pt-1 mb-4"},[_c('dialog-activate-project',{attrs:{"item":_vm.project},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}),_c('v-card-title',{staticClass:"subheading font-weight-bold"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('office.dictionaries.projectStatus[0]').text)}}),_c('v-spacer'),(_vm.project.status === 0)?[(!_vm.isValid)?_c('v-tooltip',{staticClass:"elevation-1",attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","disabled":""}},[_c('v-icon',{attrs:{"color":"error","left":""}},[_vm._v("mdi-alert")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('office.labels.activateProject'))}})],1)],1)]}}],null,false,1830989261)},[_c('div',{staticClass:"subtitle-1 font-weight-medium"},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-alert")]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.validation.requiredFields'),expression:"'office.validation.requiredFields'"}]})],1),_c('table-list',{attrs:{"color":"transparent","dark":"","values":_vm.missingValues,"item":_vm.project,"columns":_vm.columns,"renderers":_vm.renderers}})],1):_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){_vm.showDialog = true}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('office.labels.activateProject'))}})])]:_vm._e(),(_vm.project.status === 0)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-circle-slice-2")]):_vm._e()],2),_c('v-divider'),(!_vm.project)?_c('v-progress-linear',{attrs:{"indeterminate":"","query":""}}):[_c('table-list',{attrs:{"values":[
        'expectedHouses',
        'planned.conversion',
        'planned.expectedInstallations',
        'projectCertainty'
      ],"editable":_vm.editable,"item":_vm.project,"columns":_vm.columns,"renderers":_vm.renderers},on:{"edit":function($event){return _vm.$emit('edit', $event)}}})]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }