























































































































































import Vue from 'vue';
import moment from 'moment';

import ProjectsForecast from '@/apps/office/containers/charts/projects-forecast';
import DatePicker from '@/components/date-picker';
import DashboardNumberBox from './dashboardNumberBox.vue';

moment.locale('nl-NL');

import {
  ForecastOverview,
  ForecastTotals,
  HocoTarget,
  HousingCooperative,
  Installer,
  InstallerCapacity,
  Nullable,
  Pagination,
  Project,
  TablePreset,
  TableSettings,
  TableSortOptions,
  WeekForecast,
} from '@/types';

import date from '@/util/date';
import _ from 'underscore';
import filters from '@/apps/office/components/table/shared/items/filters';

export default Vue.extend({
  components: {
    DatePicker,
    ProjectsForecast,
    DashboardNumberBox,
  },

  data() {
    return {
      projects: [] as Project[],
      installers: [] as Installer[],
      hocos: [] as HousingCooperative[],
      searchResult: [] as number[],
      forecast: {
        planned: [],
        expectedWorkQueue: [],
        workQueue: [],
        distributedWorkQueue: [],
        finished: [],
        capacity: [],
        targets: [],
      } as ForecastOverview,
      loading: false,
      date,
      totals: null as Nullable<ForecastTotals>,
      filters: filters,
    };
  },
  computed: {
    totalOfTotals(): number {
      if (!this.totals) return 0;

      let { expectedWorkQueue, distributedWorkQueue, workQueue, planned, finished } = this.totals;

      return (
        expectedWorkQueue.value +
        distributedWorkQueue.value +
        workQueue.value +
        planned.value +
        finished.value
      );
    },

    formatted() {
      return (val: number) =>
        val.toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    },

    resource(): string {
      return 'projects';
    },

    tableSettings(): TableSettings {
      return this.$store.getters[`planning/forecast/settings/get`];
    },

    preset(): TablePreset {
      return this.tableSettings.lastUsed;
    },

    lastSearch(): number {
      return this.tableSettings.lastSearch;
    },

    sortOptions: {
      get(): TableSortOptions | undefined {
        return this.tableSettings.sortOptions;
      },

      set(options: TableSortOptions) {
        this.$store.dispatch(`planning/forecast/settings/set:sortOptions`, options);
      },
    },

    lastUsed: {
      get(): TablePreset {
        return this.tableSettings.lastUsed;
      },

      set(preset: TablePreset) {
        this.$store.commit(`planning/forecast/settings/set:lastUsed`, preset);
      },
    },

    activeFilters(): number {
      const params = _.pick(this.lastUsed.params, Object.values(this.filters));

      let filters = Object.keys(params).reduce((amount, key) => {
        const param = params[key];

        switch (true) {
          case param === undefined:
          case param === null:
          case param instanceof Array && param.length === 0:
            return amount;
          default:
            return amount + 1;
        }
      }, 0);

      if (this.lastUsed.searchTerm) {
        filters++;
      }

      return filters;
    },

    activeProjects(): Project[] {
      return this.projects.filter(project => this.searchResult.includes(project.id!));
    },

    activeInstallers(): Installer[] {
      return this.installers.filter(installer =>
        this.selectedProjects.some(project => installer.id === project.installerId),
      );
    },

    activeHocos(): HousingCooperative[] {
      return this.hocos.filter(hoco =>
        this.selectedProjects.some(project => hoco.id === project.housingCooperativeId),
      );
    },

    selectedProjects: {
      get(): Project[] {
        const excluded = this.lastUsed.params.excludedProjects ?? [];

        return this.activeProjects.filter(item => !excluded.includes(item.id!));
      },

      set(val: Project[]) {
        const items: number[] = this.activeProjects
          .filter(item => !val.includes(item))
          .map(item => item.id!);

        this.setParam('excludedProjects', items);
      },
    },

    selectedInstallers: {
      get(): Installer[] {
        const excluded = this.lastUsed.params.excludedInstallers ?? [];

        return this.activeInstallers.filter(item => !excluded.includes(item.id!));
      },

      set(val: Installer[]) {
        const items: number[] = this.activeInstallers
          .filter(item => !val.includes(item))
          .map(item => item.id!);

        this.setParam('excludedInstallers', items);
      },
    },

    selectedHocos: {
      get(): HousingCooperative[] {
        const excluded = this.lastUsed.params.excludedHocos ?? [];

        return this.activeHocos.filter(item => !excluded.includes(item.id!));
      },

      set(val: Installer[]) {
        const items: number[] = this.activeHocos
          .filter(item => !val.includes(item))
          .map(item => item.id!);

        this.setParam('excludedHocos', items);
      },
    },

    selectedProjectIds(): number[] {
      if (this.projects.length) return this.selectedProjects.map(project => project.id!);

      return this.searchResult;
    },

    selectedInstallerIds(): number[] {
      if (this.installers.length) return this.selectedInstallers.map(project => project.id!);

      return this.activeInstallers.map(installer => installer.id!);
    },

    selectedHocoIds(): number[] {
      if (this.hocos.length) return this.selectedHocos.map(hoco => hoco.id!);

      return this.activeHocos.map(hoco => hoco.id!);
    },

    start: {
      get(): string {
        if (typeof this.lastUsed.params.startDate === 'string') {
          return this.lastUsed.params.startDate;
        }

        return moment()
          .set('weekday', 0)
          .subtract(12, 'weeks')
          .format('YYYY-MM-DD');
      },

      set(val: string) {
        window.setTimeout(() => this.setParam('startDate', val), 10);
      },
    },

    weeks: {
      get(): number {
        if (typeof this.lastUsed.params.duration === 'number') {
          return Math.min(this.lastUsed.params.duration, 100);
        }

        return 78;
      },

      set(val: number) {
        if (val > 100) val = 100;

        this.setParam('duration', val);
      },
    },
  },

  async mounted() {
    await this.search();
    await this.getForecast();
    await this.getCapacity();
    await this.getProjects();
    await this.getInstallers();
    await this.getTargets();
    await this.getHocos();
  },

  watch: {
    lastSearch() {
      this.search();
    },

    activeFilters: {
      immediate: true,
      handler(val) {
        this.$store.commit('ui/set:activeFilters', val);
      },
    },
  },

  methods: {
    setParam(key: string, val: any) {
      if (val === null) {
        this.$delete(this.lastUsed.params, key);
      } else {
        this.$set(this.lastUsed.params, key, val);
      }

      this.lastUsed = this.lastUsed;
    },

    async search() {
      this.loading = true;

      const list: number[] = await this.$store.dispatch(`${this.resource}/list:all`, {
        params: this.preset.params,
        sort: this.sortOptions,
        query: this.preset.searchTerm,
      });

      this.searchResult = list;
      this.loading = false;
    },

    async getProjects() {
      this.loading = true;

      const page: Pagination<Project> = await this.$store.dispatch(
        `${this.resource}/get:all:page`,
        {
          page: 1,
          perPage: 10000,
          params: { props: ['id', 'region', 'installerId', 'code', 'housingCooperativeId'] },
        },
      );

      this.projects = page.data;
      this.loading = false;
    },

    async getInstallers() {
      this.loading = true;

      const page: Pagination<Installer> = await this.$store.dispatch(`installers/fetch:all:page`, {
        page: 1,
        perPage: 10000,
      });

      this.installers = page.data;
      this.loading = false;
    },

    async getHocos() {
      this.loading = true;

      const hocos = await this.$store.dispatch(`dictionaries/housingCooperatives/fetch`);

      this.hocos = hocos;
      this.loading = false;
    },

    async getCapacity() {
      this.loading = true;

      const page: Pagination<InstallerCapacity> = await this.$store.dispatch(
        `planning/capacity/fetch:all:page`,
        {
          page: 1,
          perPage: 10000,
        },
      );
      this.forecast.capacity = page.data;
      this.loading = false;
    },

    async getTargets() {
      this.loading = true;

      const page: Pagination<HocoTarget> = await this.$store.dispatch(
        `planning/targets/fetch:all:page`,
        {
          params: { housingCooperativeId: this.preset.params.housingCooperativeId },
          page: 1,
          perPage: 10000,
        },
      );

      this.forecast.targets = page.data;
      this.loading = false;
    },

    async getForecast() {
      this.loading = true;

      this.forecast.planned = await this.$store.dispatch(
        'planning/forecast/fetch:all',
        'planned-expected',
      );
      this.forecast.expectedWorkQueue = await this.$store.dispatch(
        'planning/forecast/fetch:all',
        'active-expected',
      );

      this.forecast.workQueue = await this.$store.dispatch(
        'planning/forecast/fetch:all',
        'active-with-date',
      );

      this.forecast.distributedWorkQueue = await this.$store.dispatch(
        'planning/forecast/fetch:all',
        'active-without-date',
      );

      this.forecast.finished = await this.$store.dispatch(
        'planning/forecast/fetch:all',
        'active-installations',
      );

      this.loading = false;
    },
  },
});
