import { MapViewMarker, MapViewResponse, Nullable } from '@/types';
import { BaseItem, EndpointStrategy, Endpoint } from './base';
import { AsyncModule } from 'vuex-async-mutations';

type MapViewState = {
  markers: MapViewMarker[];
  markerCount: number;
  totalCount: number;
  lastFetch: Nullable<FetchParams>;
};

type FetchParams = {
  relationId?: string;
  query?: string;
  params?: Record<string, any>;
  queryParams?: Record<string, any>;
};

const stateFactory = () => ({
  markers: [],
  markerCount: 0,
  totalCount: 0,
  lastFetch: null,
});

export const module = <T extends BaseItem>(endpoint: string | EndpointStrategy) =>
  ({
    state: stateFactory,

    mutations: {
      ['reset'](state) {
        Object.assign(state, stateFactory());
      },
    },

    getters: {
      ['markers'](state) {
        return state.markers;
      },
      ['markerCount'](state) {
        return state.markerCount;
      },
      ['totalCount'](state) {
        return state.totalCount;
      },
    },

    actions: {
      ['reset']: {
        root: true,
        handler({ commit }) {
          commit('reset');
        },
      },

      ['get:all:map'](_context, fetchParams: FetchParams = {}) {
        let url: Endpoint;
        const { relationId, query, params, queryParams } = fetchParams;

        if (typeof endpoint !== 'string') {
          url = endpoint(relationId);
        } else {
          url = { endpoint };
        }
        return this.$axios.get(`${url.endpoint}`, {
          params: {
            query: query === null || query === '' ? undefined : query,
            ...url.params,
            ...params,
            ...queryParams,
          },
        });
      },
    },

    actionsAsync: {
      ['fetch:all:map']: {
        async handler({ commit, dispatch, commitAsync }, fetchParams: FetchParams = {}) {
          const payload = dispatch('get:all:map', fetchParams);

          await commitAsync(payload, {
            lastFetch: fetchParams,
          });
        },

        pending(state, { lastFetch }: { lastFetch: FetchParams }) {
          state.lastFetch = lastFetch;
        },

        resolved(state, response: MapViewResponse) {
          state.markers = response.data;
          state.totalCount = response.allCount;
          state.markerCount = response.geospatialCount;
        },
      },
    },
  } as AsyncModule<MapViewState, any>);

export default module;
