import { ProjectConversion } from '@/types';
import moment from 'moment';
import { FunctionalComponentOptions } from 'vue/types';

type Props = {
  item: { conversionValues: ProjectConversion[] };
};

export default (
  status: number,
  field: 'installationStartAt' | 'installationEndAt',
  format: string = 'DD/MM/YYYY',
): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    const item = props.item.conversionValues.find(conversion => conversion.forStatus === status);

    let value = item?.[field];

    let date;

    if (typeof value === 'string') date = moment(value);
    else if (typeof value === 'number') date = moment(value * 1000);

    return createElement('span', date?.format(format) ?? '');
  },
});
