





















import Vue from 'vue';
import AppBar from '@/components/layout/AppBar.vue';
import i18n from '@/apps/office/i18n';
import { Language } from '@/types';

export default Vue.extend({
  props: {
    appName: String,
    default: () => 'App',
  },

  components: {
    AppBar,
  },

  mounted() {
    this.language = this.language;
  },

  computed: {
    languages(): Language[] {
      return this.$store.getters['ui/languages'];
    },

    language: {
      get(): string {
        return this.$store.state.ui.language.code;
      },

      set(code: string): void {
        this.$store.commit(
          'ui/set:language',
          this.languages.find(l => l.code === code),
        );
        i18n.locale = code;
      },
    },
  },
});
