











































import Vue, { PropType } from 'vue';
import moment from 'moment';
import _ from 'underscore';

import { Installer, HocoTarget } from '@/types';

import rules, { ValidationRules } from '@/util/forms';

export default Vue.extend({
  props: {
    item: {
      type: Object as PropType<HocoTarget>,
    },

    value: Boolean,
  },

  computed: {
    year: {
      get(): number {
        return this.item.year;
      },

      set({ value }: { value: number }) {
        this.item.year = value;
      },
    },

    years(): any[] {
      return new Array(100).fill(null).map((d, i) => {
        const year = 2000 + i;

        return {
          value: year,
          text: year,
        };
      });
    },

    numberOfWeeks(): number {
      return moment(`${this.item.year}-01-01`).isoWeeksInYear();
    },

    formValid: {
      get(): boolean {
        return this.value;
      },

      set(value: boolean) {
        this.$emit('input', value);
      },
    },

    rules(): ValidationRules {
      return {
        ...rules,
      };
    },

    housingCooperatives(): Installer[] {
      return _.sortBy(this.$store.state.dictionaries.housingCooperatives.items, 'name');
    },
  },
});
