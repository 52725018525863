






































import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import {
  Installation,
  MapViewMarker,
  ParamSettings,
  TablePreset,
  TableSettings
} from "@/types";
import _ from "underscore";
import { TranslateResult } from "vue-i18n";
import projectStates from "@/apps/office/static/project-states.json";
import monitoringStates from "@/apps/office/static/mo-states.json";
import GmapPopup from "./GmapPopup.vue";
import { Route } from "vue-router";

export default Vue.extend({
  components: {
    GmapCluster,
    GmapPopup
  },
  props: {
    widget: {
      type: Boolean
    },
    cluster: {
      type: Boolean,
      default: true
    },
    fitBounds: {
      type: Boolean
    },
    resource: {
      type: String,
      default: () => ""
    },
    params: {
      type: Object as () => ParamSettings
    },
    settings: {
      type: String,
      default: null
    },
    filters: {
      type: Object as () => Record<string, boolean>
    },
    zoom: {
      type: Number,
      required: false,
      default: () => 9
    },
    center: {
      type: Object,
      required: false,
      default: () => ({ lat: 52.0814558, lng: 5.0569913 })
    }
  },
  data() {
    return {
      mapOptions: {
        mapTypeControl: true,
        rotateControl: false,
        minZoom: 8,
        restriction: {
          latLngBounds: {
            north: 54,
            south: 50,
            west: 2.5,
            east: 8
          }
        }
      },
      markerDetails: null as Installation | null,
      infoWindowOpened: false,
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -50
        }
      },
      openedMarker: { lat: 0, lon: 0 } as MapViewMarker,
      isLoading: true
    };
  },
  methods: {
    async onClickMarker(marker: MapViewMarker) {
      this.infoWindowOpened = true;
      this.openedMarker = marker;
      this.markerDetails = null;
      this.markerDetails = await this.$store.dispatch(
        "installations/regularInstallation/installation:fetch",
        {
          pchn: marker.pchn
        }
      );
    },
    onCloseMarker() {
      this.infoWindowOpened = false;
      this.markerDetails = null;
    },
    async search() {
      this.isLoading = true;
      const query = !this.widget
        ? this.tableSettings.lastUsed.searchTerm
        : null;
      const fetchParams = Object.assign(
        {},
        !this.widget && _.pick(this.preset?.params, Object.keys(this.filters)),
        this.params
      );
      const queryParams = this.route.query;
      await this.$store.dispatch(`${this.resource}/fetch:all:map`, {
        query,
        params: fetchParams,
        queryParams
      });
      this.isLoading = false;
    },
    panToBounds(): void {
      if (this.google && this.$refs.mapRef && this.markers?.length) {
        (this.$refs.mapRef as any).$mapPromise.then((map: any) => {
          const bounds = new (this.google as any).maps.LatLngBounds();
          this.markers?.forEach(marker => {
            bounds.extend({ lng: marker.lon, lat: marker.lat });
          });
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
        });
      }
    }
  },
  computed: {
    route(): Route {
      return this.$store.state.route;
    },
    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },
    lastSearch(): number {
      return this.tableSettings?.lastSearch;
    },
    preset(): TablePreset {
      return this.tableSettings?.lastUsed;
    },
    google: VueGoogleMaps.gmapApi,
    markers(): MapViewMarker[] | null {
      const markers = this.$store.getters[`${this.resource}/markers`];
      if (markers?.length) {
        return markers;
      }
      return null;
    },
    markerImages(): { [key: string]: any } {
      const point = (this.google as any)?.maps.Point;
      const anchor = typeof point === "function" ? new point(12, 17) : null;
      const labelOrigin =
        typeof point === "function" ? new point(12, 15) : null;
      const result: { [key: string]: any } = {};
      [...projectStates, ...monitoringStates].forEach(state => {
        result[state.code || "default"] = {
          path:
            "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
          anchor,
          fillOpacity: 1,
          fillColor: state.color || "#A6E9E3",
          strokeColor: "#FFFFFF",
          strokeWeight: 2,
          scale: 2,
          labelOrigin
        };
      });
      return result;
    },
    markerCount(): TranslateResult {
      const totalCount =
        this.$store.getters[`${this.resource}/totalCount`] || 0;
      const markerCount =
        this.$store.getters[`${this.resource}/markerCount`] || 0;
      return totalCount === 1
        ? this.$t("office.labels.oneAddressFound", [totalCount, markerCount])
        : this.$t("office.labels.multipleAddressesFound", [
            totalCount,
            markerCount
          ]);
    },
    infoWindowPosition(): { lat: number; lng: number } {
      return {
        lat: this.openedMarker.lat,
        lng: this.openedMarker.lon
      };
    }
  },
  watch: {
    markers() {
      this.panToBounds();
    },
    lastSearch() {
      this.search();
    }
  },
  mounted() {
    this.search();
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDwXEeDzfXd0r8K9oNJdCN65dM361UM-Dk",
    libraries: "places"
  }
});
