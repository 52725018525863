



















import Vue, { PropType } from "vue";
import { Project } from "@/types";

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<Project>
    },
    autofocus: {
      type: String,
      default: ""
    }
  }
});
