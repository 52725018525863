





























import moment from 'moment';
import { DateRange, Nullable } from '@/types';
import Vue, { PropType } from 'vue';
import DatePicker from '../date-picker';

export default Vue.extend({
  components: { DatePicker },
  props: {
    from: {
      type: [String, null] as PropType<Nullable<string>>,
      default: null,
    },

    to: {
      type: [String, null] as PropType<Nullable<string>>,
      default: null,
    },
  },

  computed: {
    value: {
      get(): DateRange {
        return [this.from, this.to];
      },

      set(val: DateRange) {
        this.$emit('update:from', val[0]);
        this.$emit('update:to', val[1]);
      },
    },

    datesAfter(): ((val: string) => boolean) | undefined {
      if (!this.to) return undefined;

      const to = moment(this.to);

      return (val: string) => moment(val).isBefore(to);
    },

    datesBefore(): ((val: string) => boolean) | undefined {
      if (!this.from) return undefined;

      const from = moment(this.from);

      return (val: string) => moment(val).isAfter(from);
    },
  },
});
