


















import Vue, { PropType } from "vue";

import { Project } from "@/types";
import rules from "@/util/forms";

import Planning from "./substeps/Planning.vue";
import Dates from "./substeps/Dates.vue";
import General from "./substeps/General.vue";
import Materials from "./substeps/Materials.vue";
import ProjectTeam from "./substeps/ProjectTeam.vue";
import InstallerProgress from "./substeps/InstallerProgress.vue";
import ProjectDescription from "./substeps/ProjectDescription.vue";

export default Vue.extend({
  components: {
    General,
    ProjectTeam,
    InstallerProgress,
    Planning,
    Materials,
    Dates,
    ProjectDescription
  },

  props: {
    item: {
      type: Object as PropType<Project>
    },

    autofocus: {
      type: String,
      default: "region"
    },

    value: Boolean
  },

  data() {
    return {
      panels: [] as number[]
    };
  },

  watch: {
    values: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        (this.$refs.form as Vue & { validate: () => boolean }).validate();
      }
    },
    region: {
      immediate: true,
      handler() {
        this.panels = this.activePanels;
      }
    }
  },

  computed: {
    activePanels(): number[] {
      switch (this.autofocus) {
        case "office.dashboard.general":
        case "zmCustomer":
        case "housingCooperativeName":
        case "sequenceNumber":
        case "projectStatus":
        case "projectCertainty":
        case "projectTypes":
        case "buildingTypes":
        case "installationTypes":
        case "expectedHouses":
        case "ZipcodeTwoArea":
        case "housesCount":
        case "expectedInstallations":
        case "conversion":
        case "teamsUrl":
        case "urlProjectFiles":
          return [0];

        case "office.labels.headers.project_team":
        case "accountManagerName":
        case "projectManagerName":
        case "installerName":
        case "zmSubcontractorGroups":
        case "zmProjectManagerName":
        case "zmForemanName":
        case "zmPlannerName":
        case "zmRequiredExpertise":
          return [2];

        case "office.labels.headers.progress_installer":
        case "zmScreeningReady":
        case "zmVgmTraReady":
        case "zmBlueprintReady":
        case "zmProjectPlanReady":
        case "zmQuoteReady":
        case "zmHandoverReady":
        case "zmWiringDiagramReady":
          return [4];

        case "office.labels.headers.materials":
        case "panelTypeLabel":
        case "inverterConfigurationLabel":
        case "inverterType":
        case "powerMainsType":
        case "slantedRoofPercentage":
        case "flatRoofPercentage":
        case "averageAmountPanels":
          return [6];

        case "office.labels.headers.planning":
        case "planningType":
        case "planned.installationStartAndEndDate":
        case "planned.planningStartAndEndDate":
        case "planned.conversionStartAndEndDate":
        case "planned.screeningStartAndEndDate":
          return [3];

        case "office.labels.headers.conversion":
        case "screeningStartedAt":
        case "webinarAt":
        case "tenantMeetingDate":
        case "preAnnouncementAt":
        case "contractsSentAt":
        case "contractsDeadlineAt":
          return [5];
        case "description":
          return [1];

        default:
          return [0, 1, 2, 3, 4, 5, 6];
      }
    },

    rules() {
      return {
        ...rules,
        default: [
          (value?: string) => !!value || this.$t("validation.required")
        ],
        enum: [(value: number) => value >= 0 || this.$t("validation.required")],
        year: [
          (value: number) =>
            (value >= 2000 && value <= 2100) ||
            this.$t("office.validation.projectYear")
        ],
        min: (low: number) => [
          (value: number | "" | undefined | null) =>
            value === "" ||
            value === undefined ||
            value === null ||
            value >= low ||
            this.$t("validation.min", [low])
        ],
        range: (low: number, high: number) => [
          (value: number | "" | undefined | null) =>
            value === "" ||
            value === undefined ||
            value === null ||
            (value >= low && value <= high) ||
            this.$t("validation.range", [low, high])
        ]
      };
    },

    formValid: {
      get(): boolean {
        return this.value;
      },

      set(value: boolean) {
        this.$emit("input", value);
      }
    }
  }
});
