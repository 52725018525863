import { AsyncModule } from 'vuex-async-mutations';

import table from '@/store/modules/tables';

import columns from '@/apps/office/components/table/planning/capacity/columns';
import presets from '@/apps/office/components/table/planning/capacity/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

import mockData from '@/apps/office/static/mock/scenarios/data/gantt.json';

export default {
  namespaced: true,

  async: true,

  modules: {
    settings: table('planning/calendar', presets, columns, renderers),
  },

  actionsAsync: {
    ['fetch']: {
      async handler(_context, { params, query }) {
        return this.$axios.get(`/planning`, {
          params: {
            query: query === null || query === '' ? undefined : query,
            status: [0, 1],
            ...params,
            sortBy: ['installationStartAt'],
          },
        });
      },
    },
    ['update:projectPeriods']: {
      async handler(_context, { id, item }) {
        return this.$axios.patch(`/projects/${id}`, item);
      },
    },
  },
} as AsyncModule<any, any>;
