










import { TablePreset } from '@/types';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<TablePreset[]>,
      default: () => [],
    },

    items: {
      type: Array as PropType<TablePreset[]>,
      default: () => [],
    },
  },

  computed: {
    selected: {
      get(): TablePreset[] {
        return this.value;
      },

      set(val: TablePreset[]) {
        this.$emit('input', val);
      },
    },
  },
});
