import { Module } from 'vuex/types';
import { v1 as uuid } from 'uuid';

import {
  TablePreset,
  TableSettings,
  Column,
  CellRenderers,
  TableSortOptions,
  UserPreferences,
} from '@/types';
import { $t } from '@/plugins/i18n';

const standardPreset = (columns: Column[]): TablePreset => ({
  name: 'Standaard',
  readonly: true,
  columns: columns.filter(col => !col.hidden).map(col => col.value),
  id: 'standaard',
  params: {},
  searchTerm: '',
});

export default (
  tableName: string,
  presets: TablePreset[] = [],
  columns: Column[] = [],
  renderers?: CellRenderers,
): Module<TableSettings, any> => ({
  namespaced: true,

  state: {
    current: null,
    currentPage: 1,
    selected: [],
    isVisible: true,
    perPage: 25,
    presets: [],
    lastSearch: 0,
    sortOptions: { sortDesc: [], sortBy: [], multiSort: true },
    lastUsed: { columns: [], params: {}, searchTerm: '' },
  },

  getters: {
    ['get'](state) {
      return state;
    },

    ['presets'](state) {
      return [standardPreset(columns), ...presets, ...state.presets];
    },

    ['presets:current'](state, getters): TablePreset {
      const presets: TablePreset[] = getters.presets;

      if (state.current) {
        return presets.find(preset => preset.id === state.current) || presets[0];
      }

      return presets[0];
    },

    ['columns']() {
      return columns.map(col => ({
        ...col,
        text: col.text ? $t(col.text) : col.text,
        title: col.title ? $t(col.title) : col.title,
      }));
    },

    ['columns:selection'](state, getters) {
      return (selection: string[]): (Column | undefined)[] => {
        const columns: Column[] = getters.columns;

        if (!selection?.length) {
          return columns;
        }

        // this will always return the columns in a predefined order
        // return columns.filter(col => selection.includes(col.value));

        // this returns in the order the items are selected
        return selection
          .map(col => columns.find(({ value }) => col === value))
          .filter(col => !!col);
      };
    },

    ['columns:preset'](state, getters) {
      return (preset: TablePreset): (Column | undefined)[] => {
        const columns: Column[] = getters['columns:selection'](preset.columns);

        return columns.filter(col => !col.hidden);
      };
    },

    ['columns:current'](state, getters) {
      const preset: TablePreset = state.lastUsed || getters['presets:current'];

      return getters['columns:preset'](preset);
    },

    ['renderers']() {
      return renderers;
    },
  },

  mutations: {
    ['presets:init'](state, presets?: TablePreset[]) {
      if (presets?.length) {
        state.presets = presets;
      }
    },

    ['preset:add'](state, preset: TablePreset) {
      preset = { ...preset, readonly: false, id: uuid() };
      state.current = preset.id!;
      state.presets.push(preset);
      state.lastUsed = JSON.parse(JSON.stringify(preset));
    },

    ['presets:set'](state, preset: TablePreset) {
      state.current = preset.id!;
      state.lastUsed = JSON.parse(JSON.stringify(preset));
    },

    ['preset:update'](state, preset: TablePreset) {
      const index = state.presets.findIndex(p => p.id === preset.id);

      if (index < 0) {
        return;
      }

      state.presets.splice(index, 1, preset);
      state.lastUsed = JSON.parse(JSON.stringify(preset));
    },

    ['preset:import'](state, presets: TablePreset[]) {
      presets.forEach(preset => {
        const index = state.presets.findIndex(p => p.id === preset.id);

        if (index < 0) {
          state.presets.push(preset);
        } else {
          state.presets.splice(index, 1, preset);
        }

        if (state.lastUsed.id === preset.id) {
          state.lastUsed = JSON.parse(JSON.stringify(preset));
        }
      });
    },

    ['preset:remove'](state, preset: TablePreset) {
      const index = state.presets.findIndex(p => p.id === preset.id);

      if (index < 0) {
        return;
      }

      state.presets.splice(index, 1);

      if (state.presets.length) {
        state.current = state.presets[0].id!;
        state.lastUsed = JSON.parse(JSON.stringify(state.presets[0]));
      } else if (presets.length) {
        state.current = presets[0].id!;
        state.lastUsed = JSON.parse(JSON.stringify(presets[0]));
      } else {
        state.current = 'standaard';
        state.lastUsed = JSON.parse(JSON.stringify(standardPreset(columns)));
      }
    },

    ['set:selected'](state, selected: unknown[]) {
      state.selected = selected;
    },

    ['set:isVisible'](state, visible: boolean) {
      state.isVisible = !!visible;
    },

    ['set:perPage'](state, perPage: number) {
      state.perPage = perPage;
    },

    ['set:sortOptions'](state, options: TableSortOptions) {
      state.sortOptions = { ...options, multiSort: true };
    },

    ['set:currentPage'](state, currentPage: number) {
      state.currentPage = currentPage;
    },

    ['set:lastUsed'](state, options: TablePreset) {
      state.lastUsed = options;
    },

    ['set:lastSearch'](state, timestamp: number) {
      state.lastSearch = timestamp;
      state.currentPage = 1;
      state.selected = [];
    },
  },

  actions: {
    ['preferences:updated']: {
      root: true,
      handler: ({ commit }, preferences: UserPreferences) => {
        commit('presets:init', preferences?.tablePresets?.[tableName]);
      },
    },

    ['save']({ state, rootState }) {
      const preferences: UserPreferences = rootState.preferences;

      preferences.tablePresets[tableName] = state.presets;

      this.dispatch('preferences/save', preferences);
    },

    ['preset:update']({ dispatch, commit }, preset: TablePreset) {
      commit('preset:update', preset);

      return dispatch('save');
    },

    ['preset:add']({ dispatch, commit }, preset: TablePreset) {
      commit('preset:add', preset);

      return dispatch('save');
    },

    ['preset:remove']({ dispatch, commit }, preset: TablePreset) {
      commit('preset:remove', preset);

      return dispatch('save');
    },

    ['preset:import']({ dispatch, commit }, presets: TablePreset[]) {
      commit('preset:import', presets);

      return dispatch('save');
    },

    ['reset']({ dispatch, getters, commit }, params?: Record<string, any>) {
      const current: TablePreset = getters['presets:current'];
      const lastUsed = JSON.parse(JSON.stringify(current));

      lastUsed.params = { ...lastUsed.params, ...params };

      commit('set:lastUsed', lastUsed);

      dispatch('search');
    },

    ['search']({ commit }) {
      commit('set:lastSearch', Date.now());
    },

    ['set:sortOptions']({ commit, dispatch, state }, options: TableSortOptions) {
      const currentOptions = state.sortOptions;

      commit('set:sortOptions', options);

      if (
        currentOptions?.sortBy !== options.sortBy ||
        currentOptions?.sortDesc !== options.sortDesc
      ) {
        dispatch('search');
      }
    },
  },
});
