export function formatCurrency(amount: number) {
  let digits = 0;

  if (amount && Math.abs(amount) < 10) {
    digits = 2;
  }

  const str = amount.toLocaleString('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return str;
}

export function formatNumber(amount: number, maxDigits = 2) {
  let digits = maxDigits;

  // if (amount && Math.abs(amount) < 10) {
  //   digits = maxDigits;
  // }

  const str = amount.toLocaleString('nl-NL', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return str;
}

export function formatPercentage(amount: number) {
  return (amount / 100).toLocaleString('nl-NL', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}
