











































import Vue from 'vue';
import rules from '@/util/forms';

export default Vue.extend({
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },

    capacity: {
      type: Number,
      default: 0,
    },

    overwrite: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rules,
    };
  },

  computed: {
    overwriteValue: {
      get(): boolean {
        return this.overwrite;
      },

      set(val: boolean): void {
        this.$emit('update:overwrite', val);
      },
    },

    formValid: {
      get(): boolean {
        return this.value;
      },

      set(val: boolean): void {
        this.$emit('input', val);
      },
    },

    years(): any[] {
      return new Array(100).fill(null).map((d, i) => 2000 + i);
    },

    yearValue: {
      get(): number {
        return this.year;
      },

      set(val: number): void {
        this.$emit('update:year', val);
      },
    },

    capacityValue: {
      get(): number {
        return this.capacity;
      },

      set(val: number): void {
        this.$emit('update:capacity', val);
      },
    },
  },
});
