


























































































import { ProcessEntry, Project } from '@/types';
import Vue, { PropType } from 'vue';
import cells from '@/apps/office/components/table/shared/renderers/cell';
import projectStates from '@/apps/office/static/project-states.json';
import moProjectStates from '@/apps/office/static/mo-states.json';
import propositions from '@/apps/office/static/project-propositions.json';

export default Vue.extend({
  components: {
    TextNumber: cells.Number(0),
  },

  data() {
    return {
      regularProcess: projectStates as ProcessEntry[],
      monitoringOnlyProcess: moProjectStates as ProcessEntry[],
      propositions: propositions,
    };
  },

  props: {
    values: {
      type: Array as PropType<ProcessEntry[]>,
      default: () => [],
    },

    monitoringOnly: {
      type: Boolean,
      default: false,
    },

    link: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    project(): Project {
      return this.$store.getters['projects/details'](this.$store.state.route.params.id);
    },
    process(): ProcessEntry[] {
      return this.monitoringOnly ? this.monitoringOnlyProcess : this.regularProcess;
    },
    housesLink() {
      return (code: string | null) => ({
        path: `${this.$router.currentRoute.params.id}/${
          propositions[this.project?.installationType].project_name
        }/woningen/huidig`,
        query: {
          projectStates: [code === null ? '0' : code, ''],
        },
      });
    },

    total(): number {
      return this.values.filter(item => item.active).reduce((sum, val) => (sum += val.value), 0);
    },
  },
});
