import headers from "@/apps/office/components/table/shared/renderers/headers";

const allHeaders = {
  "header.id": headers.CustomHeader,
  "header.createdAt": headers.CustomHeader,
  "header.updatedAt": headers.CustomHeader,
  "header.conversion": headers.CustomHeader,
  "header.contractConversion": headers.CustomHeader,
  "header.actualConversion": headers.CustomHeader,
  "header.planned.conversion": headers.CustomHeader,
  "header.planned.expectedInstallations": headers.CustomHeader,
  "header.active.conversion": headers.CustomHeader,
  "header.active.expectedInstallations": headers.CustomHeader,
  "header.finished.conversion": headers.CustomHeader,
  "header.finished.expectedInstallations": headers.CustomHeader,
  "header.expectedHouses": headers.CustomHeader,
  "header.expectedInstallations": headers.CustomHeader,
  "header.housesCount": headers.CustomHeader,
  "header.housesCountFinal": headers.CustomHeader,
  "header.region": headers.CustomHeader,
  "header.teamsUrl": headers.CustomHeader,
  "header.buildingType": headers.CustomHeader,
  "header.status": headers.CustomHeader,
  "header.type": headers.CustomHeader,
  "header.installationType": headers.CustomHeader,
  "header.progress": headers.CustomHeader,
  "header.installationsCount": headers.CustomHeader,
  "header.expectedWorkQueue": headers.CustomHeader,
  "header.workQueue": headers.CustomHeader,
  "header.NotInProcess": headers.CustomHeader,
  "header.P200": headers.CustomHeader,
  "header.P210": headers.CustomHeader,
  "header.P220": headers.CustomHeader,
  "header.P221": headers.CustomHeader,
  "header.P222": headers.CustomHeader,
  "header.P230": headers.CustomHeader,
  "header.P240": headers.CustomHeader,
  "header.P250": headers.CustomHeader,
  "header.P297": headers.CustomHeader,
  "header.P298": headers.CustomHeader,
  "header.P299": headers.CustomHeader,
  "header.webinarAt": headers.CustomHeader,
  "header.preAnnouncementAt": headers.CustomHeader,
  "header.contractsSentAt": headers.CustomHeader,
  "header.contractsDeadlineAt": headers.CustomHeader,
  "header.accountManagerName": headers.CustomHeader,
  "header.code": headers.CustomHeader,
  "header.duration": headers.CustomHeader,
  "header.installerName": headers.CustomHeader,
  "header.projectManagerName": headers.CustomHeader,
  "header.year": headers.CustomHeader,
  "header.sequence": headers.CustomHeader,
  "header.notConvertedYet": headers.CustomHeader,
  "header.housingCooperativeName": headers.CustomHeader,
  "header.M200": headers.CustomHeader,
  "header.M221": headers.CustomHeader,
  "header.M230": headers.CustomHeader,
  "header.M250": headers.CustomHeader,
  "header.M299": headers.CustomHeader,
  "header.pchn": headers.CustomHeader,
  "header.address": headers.CustomHeader,
  "header.city": headers.CustomHeader,
  "header.contractSentDate": headers.CustomHeader,
  "header.contractState": headers.CustomHeader,
  "header.crmUrl": headers.CustomHeader,
  "header.inverter": headers.CustomHeader,
  "header.panel": headers.CustomHeader,
  "header.powershareName": headers.CustomHeader,
  "header.projectState": headers.CustomHeader,
  "header.tags": headers.CustomHeader,
  "header.zipcode": headers.CustomHeader,
  "header.tenantName": headers.CustomHeader,
  "header.approved": headers.CustomHeader,
  "header.housingCooperativeId": headers.CustomHeader,
  "header.installerId": headers.CustomHeader,
  "header.inverterConfigurationLabel": headers.CustomHeader,
  "header.issues": headers.CustomHeader,
  "header.meterDocument": headers.CustomHeader,
  "header.meterInstalled": headers.CustomHeader,
  "header.meterMechInstalled": headers.CustomHeader,
  "header.meterState": headers.CustomHeader,
  "header.panelAmount": headers.CustomHeader,
  "header.panelTypeLabel": headers.CustomHeader,
  "header.panelsDocument": headers.CustomHeader,
  "header.panelsInstalled": headers.CustomHeader,
  "header.panelsMechInstalled": headers.CustomHeader,
  "header.panelsState": headers.CustomHeader,
  "header.planned": headers.CustomHeader,
  "header.plannedInstallationDate": headers.CustomHeader,
  "header.projectCode": headers.CustomHeader,
  "header.projectId": headers.CustomHeader,
  "header.registered": headers.CustomHeader,
  "header.released": headers.CustomHeader,
  "header.solarEnvironment": headers.CustomHeader,
  "header.tenantDetails": headers.CustomHeader,
  "header.panelProcessStateAndDocumentation": headers.CustomHeader,
  "header.meterProcessStateAndDocumentation": headers.CustomHeader,
  "header.mechUrl": headers.CustomHeader,
  "header.capacity": headers.CustomHeader,
  "header.week": headers.CustomHeader,
  "header.name": headers.CustomHeader,
  "header.installationStartAndEndDate": headers.CustomHeader,
  "header.screeningStartAndEndDate": headers.CustomHeader,
  "header.conversionStartAndEndDate": headers.CustomHeader,
  "header.planningStartAndEndDate": headers.CustomHeader,
  "header.installationStartAt": headers.CustomHeader,
  "header.installationEndAt": headers.CustomHeader,
  "header.inverters": headers.CustomHeader,
  "header.housingCooperativeNorm": headers.CustomHeader,
  "header.panelsLocation": headers.CustomHeader,
  "header.roofType": headers.CustomHeader,
  "header.orientation": headers.CustomHeader,
  "header.roofInclination": headers.CustomHeader,
  "header.radiationFreedom": headers.CustomHeader,
  "header.breakerStatus": headers.CustomHeader,
  "header.zipcodeTwoArea": headers.CustomHeader,
  "header.zmProjectManagerName": headers.CustomHeader,
  "header.zmForemanName": headers.CustomHeader,
  "header.zmPlannerName": headers.CustomHeader,
  "header.planningType": headers.CustomHeader,
  "header.projectCertainty": headers.CustomHeader,
  "header.screeningStartDate": headers.CustomHeader,
  "header.startAt": headers.CustomHeader,
  "header.endAt": headers.CustomHeader,
  "header.urlRegion": headers.CustomHeader
};

export default allHeaders;
