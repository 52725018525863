import { RouteConfig, Route } from "vue-router";

import Table from "@/apps/office/layouts/table.vue";

import PlanningNavigation from "@/apps/office/pages/planning/containers/Navigation";
import CapacityDialogEdit from "@/apps/office/containers/table/planning/capacity/dialogs/Edit";
import CapacityDialogFill from "@/apps/office/containers/table/planning/capacity/dialogs/Fill";
import CapacityDialogAdd from "@/apps/office/containers/table/planning/capacity/dialogs/Add";
import CapacityDialogRemove from "@/apps/office/containers/table/planning/capacity/dialogs/Remove";
import CapacityFilters from "@/apps/office/containers/table/planning/capacity/Filters.vue";

import { TableViewProps, AuthorizedRoute } from "@/types";

import filters from "@/apps/office/components/table/shared/items/filters";

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: "/planning",
      title: "office.navigation.root.planning",
      icon: "mdi-calendar-month",
      permission: "CanViewInstallations"
    },
    ...path
  ];
}

const tableViewProps = (props: TableViewProps) => (
  route: Route
): TableViewProps => ({
  relationId: route.params.id,
  resource: "planning/capacity",
  settings: "planning/capacity/settings",
  dictionaries: "planning/capacity/dictionaries",
  filters: CapacityFilters,
  ...props,
  scope: { ...props.scope, query: route.query, filters }
});

export default [
  {
    path: "/planning/capaciteit",
    components: { default: Table, navigation: PlanningNavigation },
    meta: {
      authorize: ["CanViewInstallations"],
      hasFilters: true,
      hasSideNav: true,
      breadcrumb: breadcrumb([
        { title: "office.navigation.planning.weeklyCapacity" }
      ])
    },
    props: {
      default: tableViewProps({
        actions: [
          {
            name: "add",
            component: CapacityDialogAdd,
            permission: "CanEditInstallations",
            icon: "mdi-plus",
            label: "office.dialogs.toolbar.add"
          },

          {
            name: "edit",
            component: CapacityDialogEdit,
            permission: "CanEditInstallations",
            requiresSelection: true,
            icon: "mdi-pencil",
            label: "office.dialogs.toolbar.edit"
          },

          {
            name: "remove",
            component: CapacityDialogRemove,
            permission: "CanEditInstallations",
            requiresSelection: true,
            icon: "mdi-minus",
            label: "office.dialogs.toolbar.remove"
          }
        ]
      })
    }
  },

  {
    path: "/planning/capaciteit/vullen",
    components: { default: Table, navigation: PlanningNavigation },
    meta: {
      hasFilters: true,
      hasSideNav: true,
      breadcrumb: breadcrumb([
        { title: "office.navigation.planning.weeklyCapacity" }
      ])
    },
    props: {
      default: tableViewProps({
        resource: "installers",
        settings: "installers/settings",
        filters: undefined,
        actions: [
          {
            name: "fill",
            component: CapacityDialogFill,
            requiresSelection: true,
            permission: "CanEditInstallations",
            icon: "fa-fill",
            label: "office.dialogs.toolbar.add"
          }
        ]
      })
    }
  }
] as RouteConfig[];
