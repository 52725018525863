<template>
  <v-main>
    <div class="gantt-header">
      <h1 class="ml-2">{{ $t("office.navigation.planning.gantt") }}</h1>
      <div>
        <v-checkbox
          class="hide-installation-per-week"
          hide-details
          v-model="showInstallationsPerWeek"
          label="Installaties per week"
          :color="events[3].color"
        ></v-checkbox>
      </div>
      <calendar-legend :periods="periods" :events="events" />
      <calendar-info v-model="showInfo" />
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      height="4"
    ></v-progress-linear>
    <div id="container" ref="ganttContainer">
      <div id="dates" class="ganttContainer" v-bind:style="columns">
        <div
          class="block "
          v-for="year in uniqueYears"
          :key="year"
          v-bind:style="yearColumns(year)"
        >
          <span>{{ year }}</span>
        </div>
        <div
          class="block "
          v-for="month in uniqueMonths"
          :key="month"
          v-bind:style="monthColumns(month)"
        >
          {{ getMonthName(month) }}
        </div>
        <div
          class="block gantt-weeks"
          :class="
            selectedWeek.number === week.number &&
            selectedWeek.year === week.year
              ? 'week-selected'
              : ''
          "
          v-for="(week, i) in weeks"
          :key="i"
        >
          <button @click="orderProjectsByInstallationsPerWeek(week)">
            {{ week.number }}
          </button>
        </div>
      </div>
      <div
        id="total-installations-per-week"
        v-bind:style="columns"
        v-if="showInstallationsPerWeek"
        class="ganttContainer"
      >
        <div
          class="block"
          v-for="(week, i) in totalInstallationPerWeek"
          :key="i"
          :style="{ backgroundColor: `${week.backgroundColor} !important` }"
        >
          <span>{{ week.totalInstallations }}</span>
        </div>
      </div>
      <div id="column-headers" class="ganttContainer">
        <div v-if="projects.length">
          <span>{{
            `${projects.length} ${$t("office.navigation.root.projects")}`
          }}</span>

          <div class="show-region-column">
            <v-checkbox
              hide-details
              v-model="showRegion"
              label="Toon regio"
            ></v-checkbox>
          </div>
        </div>
      </div>
      <div
        id="column-footer"
        class="ganttContainer"
        v-if="showInstallationsPerWeek"
      >
        <div v-if="projects.length">
          <span>Installaties per week</span>
        </div>
      </div>
      <CalendarHeaders
        :projects="projects"
        :editablePeriods.sync="editablePeriods"
        :editableEvents.sync="editableEvents"
        :projectPeriods="projectPeriods"
        :headers="['code', showRegion ? 'region' : null]"
        @updateProject="projectId => updateProject(projectId)"
        @handleEditablePeriods="arg => handleEditablePeriods(arg)"
      />
      <div ref="periods" id="periods" :style="table">
        <div
          v-if="!loading && weekIndexMap"
          class="todayIndicatorLine"
          :style="todayIndicatorLine()"
        ></div>
        <div
          v-for="period in projectPeriods"
          :key="period.id"
          :style="[plotPeriod(period)]"
          class="periods"
          :class="[
            `period-color-${period.type}`,
            `period-status-${period.status}-${period.type}`
          ]"
        ></div>
        <div
          v-for="(event, index) in projectEvents"
          :key="index"
          :style="[plotSingleBlock(event)]"
          class="events d-flex justify-center align-center"
        >
          <v-icon color="#00838f">{{ event.icon }}</v-icon>
        </div>
        <div
          v-for="installation in projectsInstallationPerWeek"
          :key="installation.projectId + '-' + installation.start"
          :style="[plotSingleBlock(installation)]"
          class="installations-per-week d-flex justify-center align-center"
        >
          <div>
            <span>{{ installation.count }}</span>
          </div>
        </div>
        <div
          v-if="editablePeriods.length"
          class="period-editor-area ganttContainer"
          :style="[periodEditorArea]"
        >
          <event-editor
            v-for="(event, i) in editableEvents"
            :container="container"
            :editableEvents.sync="editableEvents"
            :weeks="weeks"
            :key="event.id"
            :event="event"
            :index="i"
          />
          <period-editor
            v-for="(period, i) in editablePeriods"
            :container="container"
            :editablePeriods.sync="editablePeriods"
            :weeks="weeks"
            :key="period.id"
            :period="period"
            :index="i"
          />
        </div>
      </div>
      <div :class="{ periodsOverlay: editablePeriods.length }"></div>
    </div>
    <div class="d-flex justify-end align-center py-2">
      <div class="status not-started mr-2"></div>
      <span>{{ $t("office.labels.status.notStarted") }}</span>
      <div class="status started mx-2"></div>
      <span>{{ $t("office.labels.status.started") }}</span>
      <div class="status finished mx-2"></div>
      <span class="mr-2">{{ $t("office.labels.status.finished") }}</span>
      <v-btn icon @click="showInfo = !showInfo">
        <v-icon size="25" color="blue darken-2">
          mdi-information-outline
        </v-icon>
      </v-btn>
    </div>
  </v-main>
</template>

<script>
import moment from "moment";
import _ from "underscore";
import filters from "@/apps/office/components/table/shared/items/filters";

import CalendarInfo from "@/apps/office/pages/planning/containers/CalendarInfo";
import PeriodEditor from "@/apps/office/components/calendar/PeriodEditor";
import CalendarHeaders from "@/apps/office/pages/planning/containers/CalendarHeaders";
import CalendarLegend from "@/apps/office/pages/planning/containers/CalendarLegend";
import EventEditor from "@/apps/office/components/calendar/EventEditor";

export default {
  components: {
    CalendarInfo,
    CalendarHeaders,
    PeriodEditor,
    CalendarLegend,
    EventEditor
  },

  data() {
    return {
      projects: [],
      weeks: [],
      weekIndexMap: {},
      range: this.initialRange(),
      periods: [
        { type: "screening", color: "#80cbc4" },
        { type: "conversion", color: "#ce93d8" },
        { type: "planning", color: "#e6b635" },
        { type: "installation", color: "#42c5fe" }
      ],
      events: [
        {
          type: "screeningStartedAt",
          icon: "mdi-clock-start",
          color: "#00838F"
        },
        { type: "webinarAt", icon: "mdi-webrtc", color: "#00838F" },
        {
          type: "preAnnouncementAt",
          icon: "mdi-rocket-launch-outline",
          color: "#00838F"
        },
        {
          type: "contractsSentAt",
          icon: "mdi-email-fast-outline",
          color: "#00838F"
        }
      ],
      loading: false,
      showInfo: false,
      pos: { top: 0, left: 0, x: 0, y: 0 },
      editablePeriods: [],
      editableEvents: [],
      container: {},
      item: [],
      filters: filters,
      installationsPerWeek: [],
      showInstallationsPerWeek: true,
      showRegion: false,
      selectedWeek: {}
    };
  },

  async mounted() {
    this.container = this.$refs.ganttContainer;

    await this.search();
    this.scrollToCurrentWeek();
  },

  watch: {
    async lastSearch() {
      await this.search();
      this.scrollToCurrentWeek();
    },
    activeFilters: {
      immediate: true,
      handler(val) {
        this.$store.commit("ui/set:activeFilters", val);
      }
    }
  },

  methods: {
    orderProjectsByInstallationsPerWeek(selectedWeek) {
      this.selectedWeek = selectedWeek;
      const installationsInSelectedWeek = this.installationsPerWeek.find(
        ({ week, year }) =>
          week === selectedWeek.number && year === selectedWeek.year
      );

      if (!installationsInSelectedWeek) {
        return;
      }

      const installationsMap = Object.fromEntries(
        installationsInSelectedWeek.data.map(
          ({ projectId, installationsThisWeek }) => [
            projectId,
            installationsThisWeek
          ]
        )
      );

      const [
        projectsWithInstallations,
        projectsWithoutInstallations
      ] = this.projects.reduce(
        ([withInstallations, withoutInstallations], project) => {
          if (installationsMap[project.id] != null) {
            withInstallations.push({
              ...project,
              installationsThisWeek: installationsMap[project.id]
            });
          } else {
            withoutInstallations.push(project);
          }
          return [withInstallations, withoutInstallations];
        },
        [[], []]
      );

      projectsWithInstallations.sort(
        (a, b) => installationsMap[b.id] - installationsMap[a.id]
      );

      this.projects = [
        ...projectsWithInstallations,
        ...projectsWithoutInstallations
      ];
    },

    async handleEditablePeriods({ index, projectId }) {
      this.item = await this.$store.dispatch(`projects/fetch`, projectId);

      this.editablePeriods = this.projectPeriods.filter(
        project => project.row === index + 1
      );
      this.editableEvents = this.projectEvents.filter(
        event => event.row === index + 1
      );
    },

    async updateProject(id) {
      this.editablePeriods.map(period => {
        const { start, end, type } = period;
        this.$set(this.item, `${type}StartAt`, this.weeks[start - 1].date);
        this.$set(this.item, `${type}EndAt`, this.weeks[end].date);
        this.$set(this.item, `${type}Duration`, Math.abs(start - end));
      });

      this.editableEvents.map(event => {
        const { start, type, webinarTime } = event;
        this.$set(
          this.item,
          type,
          type === "webinarAt"
            ? this.webinar(this.weeks[start - 1].date, webinarTime)
            : this.weeks[start - 1].date
        );
      });

      await this.$store.dispatch("planning/calendar/update:projectPeriods", {
        id,
        item: this.item
      });

      await this.search();
    },

    async search() {
      this.loading = true;
      this.projects = [];
      this.selectedWeek = {};
      this.installationsPerWeek = [];

      const projects = await this.$store.dispatch(`planning/calendar/fetch`, {
        params: this.preset.params,
        query: this.preset.searchTerm
      });

      const splitIndex = projects.findIndex(project =>
        project.periods?.some(period => period.start && period.end)
      );

      this.determineRange(projects);
      this.determineWeeks(projects);

      this.projects = [
        ...projects.slice(splitIndex, projects.length),
        ...projects.slice(0, splitIndex)
      ];

      this.installationsPerWeek = await this.getInstallationPerWeekForAllProjects(
        this.projects.map(project => project.id)
      );

      this.editablePeriods = [];
      this.editableEvents = [];
      this.loading = false;
    },

    determineRange(projects) {
      const startDates = [];
      const endDates = [];

      // Collect all start and end dates from periods and events in projects
      projects.forEach(project => {
        project.periods.forEach(period => {
          if (period.start) startDates.push(period.start);
          if (period.end) endDates.push(period.end);
        });
        project.events.forEach(event => {
          if (event.date) startDates.push(event.date);
        });
      });

      // Check if dates are available, otherwise set initial range
      if (!startDates.length || !endDates.length) {
        this.range = this.initialRange();
        return;
      }

      const earliestStart = moment.min(startDates.map(date => moment(date)));
      const latestEnd = moment.max(endDates.map(date => moment(date)));

      // Set the range with a 2-week buffer before start and 28 weeks after end
      this.range = [
        this.setDateToMonday(earliestStart.subtract(2, "weeks")),
        this.setDateToMonday(latestEnd.add(28, "weeks"))
      ].map(date => moment(date).format("YYYY-MM-DD"));
    },

    determineWeeks() {
      const start = moment(this.range[0]);
      const end = moment(this.range[1]);
      const weekCount = end.diff(start, "weeks") + 1;

      this.weeks = [];
      this.weekIndexMap = {};

      for (let i = 0; i < weekCount; i++) {
        const weekDate = start.clone().add(i, "weeks");
        const formattedDate = weekDate.format("YYYY-MM-DD");

        this.weeks.push({
          year: weekDate.year(),
          number: weekDate.week(),
          date: formattedDate
        });

        this.weekIndexMap[formattedDate] = i + 1;
      }
    },

    scrollToCurrentWeek() {
      const container = this.$refs.ganttContainer;
      const pixels = this.dateIndex(moment().format("YYYY-MM-DD")) * 32 - 32;
      if (container) {
        container.scrollTo({
          top: 0,
          left: pixels,
          behavior: "smooth"
        });
      }
      this.pos.left = pixels;
    },

    initialRange() {
      return [
        moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD"),
        moment()
          .add(1, "years")
          .format("YYYY-MM-DD")
      ];
    },

    yearColumns(year) {
      return {
        "grid-area": `auto / auto / auto / span ${
          this.weeks
            .map(week => week.date)
            .filter(item => item.slice(0, 4) === year).length
        }`
      };
    },

    monthColumns(month) {
      return {
        "grid-area": `auto / auto / auto / span ${
          this.weeks
            .map(week => week.date)
            .filter(item => item.slice(0, 7) === month).length
        }`
      };
    },

    plotPeriod(data) {
      return {
        "grid-row": data.row,
        "grid-column-start": data.start,
        "grid-column-end": data.end
      };
    },

    plotSingleBlock(data) {
      return {
        "grid-row": data.row,
        "grid-column": `${data.start} / span 1`
      };
    },

    dateIndex(date) {
      const dateOnlyMonday = this.setDateToMonday(date);
      return this.weekIndexMap[dateOnlyMonday] || 0;
    },

    setDateToMonday(date) {
      let weekNumber = moment(date).isoWeekday();

      if (weekNumber != 1) {
        date = moment(date)
          .subtract(weekNumber - 1, "days")
          .format("YYYY-MM-DD");
      }
      return date;
    },

    getMonthName(date) {
      return moment(date)
        .locale(`${this.$store.state.ui.language.code}`)
        .format("MMMM");
    },

    webinar(date, webinarTime) {
      return moment(`${date}${webinarTime}`, "YYYY-MM-DD:hh:mm").format(
        "YYYY-MM-DD hh:mm"
      );
    },

    async getInstallationPerWeekForAllProjects(ids) {
      return await this.$store.dispatch("planning/forecast/fetch:aggregate", {
        ids,
        from: this.range[0],
        until: this.range[1]
      });
    },

    todayIndicatorLine() {
      const today = this.setDateToMonday(moment().format("YYYY-MM-DD"));
      return {
        "grid-area": `1 / ${this.weekIndexMap[today.toString()]} / 2 / span 1`
      };
    }
  },

  computed: {
    projectPeriods() {
      const periodTypes = this.periods.flatMap(period => period.type);
      return this.projects?.flatMap((project, index) =>
        project.periods
          .filter(period => {
            return (
              period.start && period.end && periodTypes.includes(period.type)
            );
          })
          .map(period => ({
            row: index + 1,
            start: this.dateIndex(period.start),
            end: this.dateIndex(period.end) + 1,
            type: period.type,
            status: period.status
          }))
      );
    },

    projectEvents() {
      const eventTypes = this.events.flatMap(event => event.type);
      return this.projects?.flatMap((project, index) =>
        project.events
          .filter(event => {
            return event.date && eventTypes.includes(event.type);
          })
          .map(event => ({
            row: index + 1,
            start: this.dateIndex(event.date),
            type: event.type,
            icon: this.events.find(e => e.type === event.type)?.icon,
            webinarTime:
              event.type === "webinarAt"
                ? moment(event.date).format("hh:mm")
                : null
          }))
      );
    },

    projectsInstallationPerWeek() {
      if (!this.showInstallationsPerWeek) return [];

      return this.projects.flatMap((project, rowIndex) => {
        return this.installationsPerWeek.flatMap(weekData => {
          const { year, week } = weekData;
          return weekData.data
            .filter(installation => installation.projectId === project.id)
            .map(installation => {
              const weekStartDate = moment()
                .isoWeekYear(year)
                .isoWeek(week)
                .startOf("isoWeek")
                .format("YYYY-MM-DD");
              const weekIndex = this.weekIndexMap[weekStartDate];

              if (!weekIndex) return null;

              return {
                row: rowIndex + 1,
                start: weekIndex,
                count:
                  Math.round(installation.installationsThisWeek * 10) / 10 || 0,
                projectId: installation.projectId
              };
            })
            .filter(Boolean)
            .filter(installation => installation.count > 0);
        });
      });
    },

    totalInstallationPerWeek() {
      const total = this.weeks.map(week => {
        const matchingData = this.installationsPerWeek.find(weekData => {
          const { year, week: weekNumber } = weekData;

          return (
            year === moment(week.date).isoWeekYear() &&
            weekNumber === moment(week.date).isoWeek()
          );
        });

        return {
          ...week,
          totalInstallations:
            matchingData && matchingData.totalInstallations > 0
              ? Math.round(matchingData.totalInstallations * 10) / 10
              : null
        };
      });

      const installations = total
        .map(week => week.totalInstallations)
        .filter(value => value !== null);

      const min = Math.min(...installations);
      const max = Math.max(...installations);

      return total.map(({ week, totalInstallations }) => {
        let backgroundColor = "inherit";
        if (totalInstallations !== null) {
          const percentage = ((totalInstallations - min) / (max - min)) * 100;
          const alpha = (percentage / 100) * 0.7;
          backgroundColor = `rgba(0, 123, 255, ${alpha})`;
        }
        return {
          week,
          totalInstallations,
          backgroundColor
        };
      });
    },

    periodEditorArea() {
      if (this.editablePeriods.length) {
        return {
          "grid-area": `${this.editablePeriods[0].row} / 1 / auto / ${this.weeks
            .length + 1}`,
          display: "grid",
          "grid-gap": "2px",
          "grid-template-columns": `repeat(${this.weeks.length}, 30px)`
        };
      }

      return {};
    },

    columns() {
      return {
        display: "grid",
        "grid-gap": "2px",
        "grid-template-columns": `repeat(${this.weeks.length}, 30px)`
      };
    },

    rows() {
      return {
        display: "grid",
        "grid-gap": "2px",
        "grid-template-rows": `repeat(${this.projects.length}, 30px)`
      };
    },

    table() {
      return {
        display: "grid",
        "grid-gap": "2px",
        "grid-template-columns": `repeat(${this.weeks.length}, 30px)`,
        "grid-template-rows": `repeat(${this.projects.length}, 30px)`
      };
    },

    uniqueYears() {
      return [...new Set(this.weeks.map(week => week.date.slice(0, 4)))];
    },

    uniqueMonths() {
      return [...new Set(this.weeks.map(week => week.date.slice(0, 7)))];
    },

    tableSettings() {
      return this.$store.getters[`planning/calendar/settings/get`];
    },

    preset() {
      return this.tableSettings.lastUsed;
    },

    lastSearch() {
      return this.tableSettings.lastSearch;
    },

    lastUsed: {
      get() {
        return this.tableSettings.lastUsed;
      },

      set(preset) {
        this.$store.commit(`planning/calendar/settings/set:lastUsed`, preset);
      }
    },

    activeFilters() {
      const params = _.pick(this.lastUsed.params, Object.values(this.filters));

      let filters = Object.keys(params).reduce((amount, key) => {
        const param = params[key];

        switch (true) {
          case param === undefined:
          case param === null:
          case param instanceof Array && param.length === 0:
            return amount;
          default:
            return amount + 1;
        }
      }, 0);

      if (this.lastUsed.searchTerm) {
        filters++;
      }

      return filters;
    }
  }
};
</script>
<style lang="scss">
$periods: (
  "screening": #80cbc4,
  "conversion": #ce93d8,
  "planning": #e6b635,
  "installation": #42c5fe
);

$border-color-light: rgba(189, 189, 189, 0.4);
$grey-color: #bdbdbd;
$highlight-color: #00838f;
$box-shadow: 3px 0 5px rgba(0, 0, 0, 0.2);
$highlight-box-shadow: 0px 2px 7px 0px $highlight-color;
$block-height: 30px;
$block-width: 32px;
$status-border-width: 4px;
$sticky-z-index: 3;

@mixin sticky-top {
  position: sticky;
  top: 0;
  z-index: $sticky-z-index;
}

@mixin sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: $sticky-z-index;
}

@mixin centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin gradient-background($color) {
  background: repeating-linear-gradient(
    45deg,
    $color,
    $color 5px,
    transparent 5px,
    transparent 10px
  );
}

@each $period, $color in $periods {
  .period-color-#{$period} {
    background-color: $color;
    border: $status-border-width solid $color;
  }
  .period-status-not_started-#{$period} {
    background-color: mix(white, $color, 80%);
  }
  .period-status-started-#{$period} {
    opacity: 0.9;
    @include gradient-background(mix(white, $color, 80%));
  }
}

#container {
  width: 100%;
  height: calc(100vh - 220px);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  overflow: auto;
  border-top: 3px solid $border-color-light;
  cursor: grab;
}

#dates,
#total-installations-per-week,
#column-headers,
#column-footer {
  background-color: inherit;
}

#dates {
  grid-area: 1 / 2 / 2 / 3;
  @include sticky-top;
  z-index: $sticky-z-index;
  background-image: linear-gradient(
      to left,
      $border-color-light 6.25%,
      transparent 6.25%
    ),
    linear-gradient(to top, $border-color-light 6.25%, transparent 6.25%);
  background-size: $block-width $block-width;
  background-repeat: repeat;
  border-bottom: 3px solid $border-color-light;
  min-height: 97px;
  border-bottom: 3px solid $border-color-light !important;
}

#total-installations-per-week {
  grid-area: 4 / 2 / 5 / 3;
  @include sticky-bottom;
  border-top: 3px solid $border-color-light !important;
  & span {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}

#column-headers {
  grid-area: 1 / 1 / 2 / 2;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $sticky-z-index;
  min-width: 165px;
  border-bottom: 3px solid $border-color-light !important;
  border-right: 3px solid $border-color-light !important;

  & div {
    & span {
      @include centered;
      justify-content: flex-end;
      margin: 1rem;
    }
    & .show-region-column {
      margin-left: 0.5rem;
      margin-top: 1rem;
    }
  }
}

#column-footer {
  grid-area: 4 / 1 / 5 / 2;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: $sticky-z-index;
  min-width: 165px;
  height: 5rem;
  border-top: 3px solid $border-color-light !important;
  border-right: 3px solid $border-color-light !important;

  & div {
    height: 100%;
    @include centered;
    justify-content: flex-end;
    margin: 0 1rem;
  }
}

#periods {
  grid-area: 2 / 2 / 3 / 3;
  position: relative;
  opacity: 1;
  background-image: linear-gradient(
      to left,
      $border-color-light 6.25%,
      transparent 6.25%
    ),
    linear-gradient(to top, $border-color-light 6.25%, transparent 6.25%);
  background-size: $block-width $block-width;
  background-repeat: repeat;
  overflow: hidden;
  @include centered;
}

.block {
  min-height: $block-height;
  height: 100%;
  overflow: hidden;
  background-color: inherit;
  @include centered;
}

.status {
  width: 45px;
  height: 18px;

  &.not-started {
    background: rgba(189, 189, 189, 0.2);
    box-shadow: inset 0 0 0 4px $grey-color;
  }

  &.started {
    opacity: 0.8;
    box-shadow: inset 0 0 0 3px $grey-color;
    @include gradient-background($grey-color);
  }

  &.finished {
    background-color: $grey-color;
  }
}

.todayIndicatorLine:before {
  content: "";
  background-color: rgba(black, 0.3);
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.periods,
.period-editor-area,
.periodsOverlay {
  height: 100%;
}

.period-editor-area {
  background-image: linear-gradient(
    to left,
    rgba(172, 172, 172, 0.1) 6.25%,
    transparent 6.25%
  );
  background-size: $block-width $block-width;
  background-repeat: repeat;
  z-index: 1;
  @include centered;
}

.events {
  border-radius: 3px;
  background-color: white;
  border: 2px solid $highlight-color;
  box-shadow: $highlight-box-shadow;
  position: relative;
  height: 25px;
}

.gantt-weeks {
  &:hover {
    background-color: $highlight-color;
  }
  & button {
    unset: all;
  }
}

.week-selected {
  color: white;
  background-color: $highlight-color;
}

.installations-per-week {
  @include centered;
  z-index: 1;

  & > div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    @include centered;

    & span {
      color: black;
      font-size: 10px;
    }
  }
}

.hide-installation-per-week {
  margin: 0 1rem;
}

.gantt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    flex-grow: 1;
  }
}
</style>
