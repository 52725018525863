import Vue, { PropType } from 'vue';

import { VChip } from 'vuetify/lib';
import { Inverter } from '@/types';

type RouteFactory<T> = (item: T) => { value?: Inverter[][] };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    data() {
      const props = factory(this.item);

      return {
        inverters: props.value,
      };
    },

    components: {
      VChip,
    },

    template: `
      <div>
        <v-chip outlined label v-for="(inverter, i) in inverters" :key="i">
          <span class="mr-1">{{ inverter?.[0].brand }}: </span>
          <div v-for="(entry, i) in inverter" :key="i" class="d-flex justify-center align-center">
            <v-chip outlined color="purple" class="ml-1" label small >{{entry.amount}} x: {{entry.model}}</v-chip>
          </div>
        </v-chip>
      </div>
    `,
  });
