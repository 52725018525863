



























































































































































import Vue, { PropType } from 'vue';
import { House, Installation } from '@/types';
import ExternalLink from '@/apps/office/components/table/shared/renderers/cell/ExternalLink.vue';
import projectStates from '@/apps/office/static/project-states.json';
import monitoringStates from '@/apps/office/static/mo-states.json';
import { TranslateResult } from 'vue-i18n';
import { VChip } from 'vuetify/lib';

export default Vue.extend({
  components: {
    ExternalLink,
    VChip,
  },
  props: {
    installation: {
      type: Object as PropType<House & Installation>,
    },
  },
  computed: {
    installationTypes(): Map<number, string> {
      return new Map(
        ((this.$t(
          'office.dictionaries.installationTypes',
        ) as unknown) as any[]).map(({ text, value }) => [value, text]),
      );
    },
    panelLocations(): Map<string, string> {
      return new Map(
        ((this.$t(
          'office.dictionaries.panelLocation',
        ) as unknown) as any[]).map(({ text, value }) => [value, text]),
      );
    },
    roofTypes(): Map<string, string> {
      return new Map(
        ((this.$t('office.dictionaries.roofTypes') as unknown) as any[]).map(({ text, value }) => [
          value,
          text,
        ]),
      );
    },
  },
  methods: {
    getProjectStateStyle(projectState: string): string | undefined {
      const color = [...projectStates, ...monitoringStates].find(
        state => state.code === projectState,
      )?.color;
      if (color) {
        return `--project-state-color: ${color};`;
      }
    },
    getProjectStateLabel(projectState: string): TranslateResult {
      const label = [...projectStates, ...monitoringStates].find(
        state => state.code === projectState,
      )?.label;
      return label ? this.$t(label) : '';
    },
  },
});
