import details from '@/store/base.details';
import paginated from '@/store/base.items.paginated';
import crud from '@/store/base.crud';
import { House } from '@/types';
import { AsyncModule } from 'vuex-async-mutations';
import other from './other';
import monitoringOnly from './monitoring-only';

import table from '@/store/modules/tables';

import columns from '@/apps/office/components/table/houses/other/columns';
import presets from '@/apps/office/components/table/houses/other/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<House>('/houses'),
    items: paginated<House>(projectId => ({
      endpoint: `projects/${projectId!}/newHouses`,
    })),
    crud: crud<House>('/houses'),
    settings: table('houses', presets, columns, renderers),
    other,
    monitoringOnly,
  },
} as AsyncModule<any, any>;
