import VueI18n from 'vue-i18n';
import Vue from 'vue';
import DialogWithSteps from '@/apps/office/components/table/shared/dialogs/with-steps';
import { DialogStep, FormLabel, FormScope } from '@/types';

type DialogProps<FormData, FormAttributes> = {
  title: FormLabel;

  steps?: DialogStep<FormData, FormAttributes>[];

  width?: string | number;

  maxWidth?: string | number;

  onClose?: (this: FormScope<FormData, FormAttributes>) => void;

  formData?: (this: FormScope<FormData, FormAttributes>) => FormData;
};

const withDialog = <FormData, FormAttributes>(props: DialogProps<FormData, FormAttributes>) =>
  Vue.component('DialogWithSteps', {
    render(createElement) {
      return createElement(DialogWithSteps, {
        on: this.$listeners,
        attrs: this.$attrs,
        props: {
          ...props,
          formDataFactory: props.formData,
          ...this.$attrs,
        },
      });
    },
  });

export default withDialog;
