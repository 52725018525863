































































import { CellRenderers, Column, Project } from "@/types";
import Vue, { PropType } from "vue";
import TableList from "@/components/table-list";

import DialogActivateProject from "@/apps/office/containers/table/projects/dialogs/ActivateProject";

import columns from "@/apps/office/components/table/projects/allprojects/columns";
import renderers from "@/apps/office/components/table/shared/items/renderers";

import columnsMO from "@/apps/office/components/table/projects/monitoring-only/columns";
import renderersMO from "@/apps/office/components/table/shared/items/renderers";

export default Vue.extend({
  components: {
    TableList,
    DialogActivateProject
  },

  props: {
    project: Object as PropType<Project>
  },

  data() {
    return {
      showDialog: false
    };
  },

  computed: {
    columns(): Column[] {
      if (this.project.type === 5) return columnsMO;

      return columns;
    },

    renderers(): CellRenderers {
      if (this.project.type === 5) return renderersMO;

      return renderers;
    },

    editable(): string[] {
      if (this.project.status === 0) {
        return [
          "planned.conversion",
          "planned.expectedInstallations",
          "planned.startAt",
          "planned.duration",
          "planned.endAt",
          "expectedHouses",
          "zipcodeTwoArea",
          "planned.installationStartAndEndDate",
          "planned.planningStartAndEndDate",
          "planned.conversionStartAndEndDate",
          "planned.screeningStartAndEndDate",
          "panelTypeId",
          "inverterConfigurationId",
          "planned.percentageSlantedRoof",
          "planned.percentageFlatRoof",
          "averageAmountPanels",
          "projectCertainty"
        ];
      }

      return [];
    },

    missingValues() {
      const values: string[] = [];

      if (!this.project.conversion) values.push("conversion");
      if (!this.project.expectedHouses) values.push("expectedHouses");
      if (!this.project.expectedInstallations)
        values.push("expectedInstallations");
      if (!this.project.installationStartAt) values.push("installationStartAt");
      if (!this.project.installationEndAt) values.push("endAt");

      return values;
    },

    isValid(): boolean {
      return !this.missingValues.length;
    }
  }
});
