























import Vue from 'vue';

export default Vue.extend({
  props: {
    size: {
      type: Number,
    },
    meterStatus: {
      type: String,
    },
    breakerStatus: {
      type: String,
    },
  },
});
