import { AsyncModule } from 'vuex-async-mutations';
import { UserPreferences, UserProfile } from '@/types';

const stateFactory = () => ({
  tablePresets: {},
});

export const module: AsyncModule<UserPreferences, any> = {
  namespaced: true,

  async: true,

  state: stateFactory,

  getters: {},

  /* eslint-disable no-param-reassign */
  mutations: {
    ['reset']: state => {
      Object.assign(state, stateFactory());
    },

    ['save'](state, preferences: UserPreferences) {
      Object.assign(state, preferences);
    },
  },
  /* eslint-disable no-param-reassign */

  actionsAsync: {
    ['fetch']: {
      async handler({ state, commitAsync }) {
        const { user } = await commitAsync(
          this.$axios.get<{ user: UserProfile }>('/users/profile'),
        );

        this.dispatch('preferences:updated', user.settings?.projects);
        Object.assign(state, user.settings?.projects);
      },
    },

    ['save']: {
      handler({ state, commit, commitAsync }, preferences: UserPreferences) {
        commit('save', preferences);
        return commitAsync(this.$axios.post('/users/settings', state));
      },
    },
  },

  actions: {
    // "events"

    ['reset']: {
      root: true,
      handler: ({ commit }) => commit('reset'),
    },

    ['authenticated']: {
      root: true,
      handler: ({ dispatch }) => dispatch('fetch'),
    },
  },
};

export default module;
