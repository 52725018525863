<template>
  <div class="dashboard-box d-flex">
    <div class="d-flex flex-column">
      <h3 class="blue-grey--text text--darken-2">{{ title }}</h3>
      <p v-if="value" class="blue-grey--text text--lighten-3">{{ value }}</p>
    </div>
    <v-divider class="mx-4" v-if="target" vertical></v-divider>
    <div v-if="target" class="d-flex flex-column">
      <h3 class="blue-grey--text text--darken-2">{{ `Target ${year}` }}</h3>
      <p v-if="value" class="blue-grey--text text--lighten-3">{{ target }}</p>
    </div>
  </div>
</template>

<script>
import { any } from 'underscore';
export default {
  props: {
    title: {
      type: String,
    },
    value: {
      type: String,
      default: '0',
    },
    target: {
      type: String,
      default: null,
    },
    year: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.dashboard-box {
  font-size: 0.8rem;
  padding: 10px;
  border: 1px solid #37474f41;
  border-radius: 5px;
  -webkit-box-shadow: 1px 2px 3px -1px rgba(102, 102, 102, 0.5);
  box-shadow: 1px 2px 3px -1px rgba(102, 102, 102, 0.5);
}

.dashboard-box p {
  font-size: 3rem;
  margin-bottom: 0px !important;
  font-weight: 600;
  margin: 0px;
}
</style>
