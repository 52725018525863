import { OAuthTokens } from '@/types';
import { AsyncModule } from 'vuex-async-mutations';

interface AuthenticationState {
  tokens: {
    user: OAuthTokens | null;
    impersonation: OAuthTokens | null;
  };
  lastReset: number;
}

const stateFactory = (): AuthenticationState => ({
  tokens: { user: null, impersonation: null },
  lastReset: 0,
});

export const module: AsyncModule<AuthenticationState, any> = {
  state: stateFactory,

  getters: {
    ['isAuthenticated'](state) {
      return !!state.tokens.user;
    },

    ['isImpersonated'](state) {
      return !!state.tokens.impersonation;
    },

    ['apiToken'](state) {
      if (state.tokens.impersonation) {
        return state.tokens.impersonation.access_token;
      }

      if (state.tokens.user) {
        return state.tokens.user.access_token;
      }

      return undefined;
    },

    ['tokens'](state) {
      return state.tokens;
    },
  },

  mutations: {
    ['signOut'](state) {
      state.tokens.user = null;
      state.tokens.impersonation = null;
    },

    ['signOutAs'](state) {
      state.tokens.impersonation = null;
    },

    ['reset'](state) {
      Object.assign(state, stateFactory());
    },
  },

  actionsAsync: {
    ['signIn']: {
      async handler({ commitAsync, dispatch }, { email, password }) {
        await commitAsync(
          this.$axios.post(
            '/auth/login',
            { email, password },
            { headers: { Authorization: false } },
          ),
        );

        return dispatch('ping');
      },

      pending(state) {
        state.tokens = { user: null, impersonation: null };
      },

      resolved(state, user: OAuthTokens) {
        state.tokens = { user, impersonation: null };
      },
    },

    ['ping']: {
      handler({ dispatch, commitAsync }) {
        return commitAsync(dispatch('authenticated', true, { root: true }));
      },

      rejected(state) {
        state.tokens = { user: null, impersonation: null };
      },
    },

    ['signInAs']: {
      async handler({ dispatch, commitAsync }, { email, pchn }) {
        await commitAsync(this.$axios.post('/admin/impersonate', pchn ? { pchn } : { email }));

        return dispatch('ping');
      },

      pending(state) {
        state.tokens.impersonation = null;
      },

      resolved(state, tokens: OAuthTokens) {
        state.tokens.impersonation = tokens;
      },
    },

    ['reset:password']: {
      handler({ commitAsync }, { email }) {
        return commitAsync(
          this.$axios.post(
            '/auth/reset-password',
            { email },
            { headers: { Authorization: false } },
          ),
        );
      },

      resolved(state) {
        state.lastReset = Date.now();
      },
    },

    ['set:password']: {
      handler({ commitAsync }, { email, token, password, passwordConfirm }) {
        return commitAsync(
          this.$axios.post(
            '/auth/set-password',
            {
              email,
              token,
              password,
              password_confirmation: passwordConfirm,
            },
            { headers: { Authorization: false } },
          ),
        );
      },

      pending(state) {
        state.lastReset = 0;
      },
    },
  },

  actions: {
    ['signOut']({ commit }) {
      commit('signOut');

      return this.dispatch('reset', Date.now());
    },

    ['signOutAs']({ commit, dispatch }) {
      commit('signOutAs');

      return dispatch('ping');
    },

    // "events"

    ['created']: {
      root: true,
      async handler({ dispatch, getters }) {
        if (getters['isAuthenticated']) {
          await dispatch('ping');
          return Promise.resolve();
        }
      },
    },
  },
};

export default module;
