import Vue, { PropType } from 'vue';

import { VIcon } from 'vuetify/lib';

type Colors = Record<string, string>;

export default (icon: string, colors: Colors, size = 16) =>
  Vue.extend({
    props: {
      value: {
        type: String,
      },
    },

    components: {
      VIcon,
    },

    data() {
      const color = colors[this.value];

      return {
        size,
        icon,
        color,
      };
    },

    template: `<v-icon v-if="color" :color="color" left v-text="icon" :size="size" />`,
  });
