


























import Vue, { PropType } from 'vue';

import Answers from './Answers.vue';
import ItemType from './ItemType.vue';
import RichText from './RichText.vue';
import _ from 'underscore';

import {
  DynamicDocumentGroup,
  DynamicDocumentItem,
  DynamicDocumentItemData,
  DynamicDocumentItemValue,
} from '@/types.dynamic-document';
import { CellRenderer, CellRenderers } from '@/types';

type DynamicDocumentResult = DynamicDocumentItem & {
  answered: number;
  unanswered: number;
  total: number;
  answers: DynamicDocumentItemValue[];
};

export default Vue.extend({
  components: {
    Answers,
  },

  props: {
    group: {
      type: Object as PropType<DynamicDocumentGroup>,
    },
    data: {
      type: Array as PropType<DynamicDocumentItemData[]>,
      default: () => [],
    },
  },

  data() {
    return {
      renderers: {
        'item.type': ItemType,
        'item.title': RichText,
      } as CellRenderers,
      headers: [
        {
          value: 'type',
          text: `Type`,
          width: 80,
        },
        {
          value: 'title',
          text: `Vraag`,
        },
        {
          value: 'total',
          text: 'Totaal',
          width: 120,
        },
        {
          value: 'unanswered',
          text: 'Niet ingevuld',
          width: 120,
        },
        {
          value: 'answered',
          text: 'Antwoorden',
          width: 120,
        },
      ],
    };
  },

  computed: {
    propName() {
      return (slot: string) => slot.split('.').pop();
    },

    findValue() {
      return (item: any, slot: string) => {
        return _.property(slot.split('.').slice(1))(item);
      };
    },

    items(): DynamicDocumentResult[] {
      return this.group.items
        .filter(
          item =>
            item.type !== 'CUSTOM' &&
            item.type !== 'WARNING' &&
            item.type !== 'HIDDEN' &&
            item.type !== 'HEADER',
        )
        .map(item => {
          const answers: DynamicDocumentItemValue[] = this.data
            .filter(d => d.field === item.field)
            .map(d => d.value);

          const unanswered = answers.filter(d => {
            if (d === undefined || d === null || d === '') return true;
            if (d instanceof Array && d.length === 0) return true;

            return false;
          });

          return {
            ...item,
            answered: answers.length - unanswered.length,
            unanswered: unanswered.length,
            total: answers.length,
            answers,
          };
        });
    },
  },

  mounted() {},

  methods: {},
});
