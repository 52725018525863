







































import Vue from 'vue';
import AppBar from '@/components/layout/AppBar.vue';
import MockScenarios from '@/containers/mock-scenarios';

import i18n from '@/apps/office/i18n';
import { Language, AuthorizedRoute, Nullable } from '@/types';

export default Vue.extend({
  components: {
    AppBar,
    MockScenarios,
  },

  async mounted() {
    this.darkMode = this.darkMode;
    this.language = this.language;
  },

  data() {
    return {
      mock: null as Nullable<any>,
    };
  },

  computed: {
    routes(): AuthorizedRoute[] {
      return [
        {
          title: 'office.navigation.root.projects',
          to: '/projecten',
          permission: 'CanViewProjects',
          icon: 'mdi-briefcase',
        },
        {
          title: 'office.navigation.root.installations',
          to: '/installaties',
          permission: 'CanViewInstallations',
          icon: 'mdi-home',
        },
        {
          title: 'office.navigation.root.planning',
          to: '/planning',
          permission: 'CanViewInstallations',
          icon: 'mdi-calendar-month',
        },
      ];
    },

    languages(): Language[] {
      return this.$store.getters['ui/languages'];
    },

    environment(): string {
      const hostname = window.location.hostname.toLowerCase();

      if (hostname.includes('-woc-')) {
        const parts = hostname.split('-');
        const index = parts.indexOf('woc');

        return `WOC-${parts[index + 1]}`;
      }

      if (hostname === 'localhost' || hostname.includes('local.wocozon.dev')) {
        return 'dev';
      }

      if (window.location.hostname.includes('staging.wocozon.dev')) {
        return 'staging';
      }

      if (window.location.hostname.includes('test.wocozon.nl')) {
        return 'test';
      }

      return '';
    },

    darkMode: {
      get(): boolean {
        return this.$store.state.ui.darkMode;
      },

      set(darkMode: boolean): void {
        this.$store.commit('ui/set:darkMode', darkMode);
        this.$vuetify.theme.dark = darkMode;
      },
    },

    userName(): string {
      return this.$store.state.profile.user.name;
    },

    language: {
      get(): string {
        return this.$store.state.ui.language.code;
      },

      set(code: string): void {
        this.$store.commit(
          'ui/set:language',
          this.languages.find(l => l.code === code),
        );
        i18n.locale = code;
      },
    },
  },

  methods: {
    signOut() {
      localStorage.clear();
      this.$router.go(0);
    },
  },
});
