import { RouteConfig, Route } from 'vue-router';

import Blocks from '@/apps/office/pages/projects/_id/highrise/blocks.vue';
import HousesList from '@/apps/office/containers/block-editor/HousesList.vue';
import Navigation from '@/apps/office/pages/projects/_id/containers/Navigation';
import propositions from '@/apps/office/static/project-propositions.json';

import { Project, TableViewProps, AuthorizedRoute, Breadcrumb } from '@/types';

function breadcrumb(path: AuthorizedRoute[] = []): Breadcrumb {
  return (route, store) => {
    const project: Project = store.getters['projects/details'](route.params.id);
    return [
      {
        to: '/',
        title: 'office.navigation.root.projects',
        icon: 'mdi-briefcase-clock',
        permission: 'CanViewProjects',
      },
      {
        to: project
          ? `/projecten/${propositions[project.installationType].project_name}`
          : 'office.navigation.projects.allprojects',
        title: project
          ? `office.navigation.projects.${propositions[project.installationType].project_name}`
          : 'office.navigation.projects.allprojects',
        // icon: 'mdi-briefcase-clock',
      },
      {
        to: `/projecten/${route.params.id}`,
        custom: true,
        title: project
          ? `${project?.region} (${project?.housingCooperativeName})`
          : route.params.id,
        clipboard: project?.code,
      },
      ...path.map(p => ({ ...p, clipboard: p.clipboard ?? project?.code })),
    ];
  };
}

const pageMeta = (path: AuthorizedRoute[] = []): any => ({
  authorize: ['CanViewProjects'],
  hasFilters: true,
  filterIcon: 'mdi-home-outline',
  hasSideNav: true,
  breadcrumb: breadcrumb(path),
});

export default [
  ...propositions.map((item, i) => ({
    path: `projecten/:id/${item.project_name}/hoogbouw`,
    meta: pageMeta([
      { title: 'office.navigation.projects.highrise.root' },
      { title: 'office.navigation.projects.highrise.dashboard' },
    ]),
    components: {
      default: Blocks,
      navigation: Navigation,
      sidepanel: HousesList,
    },
    props: { default: true, sidepanel: true },
  })),
  ...propositions.map((item, i) => ({
    path: `projecten/:id/${item.project_name}/duplex`,
    meta: pageMeta([
      { title: 'office.navigation.projects.duplex.root' },
      { title: 'office.navigation.projects.duplex.dashboard' },
    ]),
    components: {
      default: Blocks,
      navigation: Navigation,
      sidepanel: HousesList,
    },
    props: { default: true, sidepanel: true },
  })),
] as RouteConfig[];
