import { RouteConfig, Route } from "vue-router";

import PlanningNavigation from "@/apps/office/pages/planning/containers/Navigation";
// import Filters from '@/apps/office/containers/table/projects/Filters.vue';
import Forecast from "@/apps/office/pages/installations/forecast.vue";
import TableFilters from "@/apps/office/containers/table-filters";
import ForecastFilters from "@/apps/office/containers/table/planning/forecast/Filters.vue";

import { TableViewProps, AuthorizedRoute } from "@/types";

import filters from "@/apps/office/components/table/shared/items/filters";

const tableViewProps = (props: TableViewProps) => (
  route: Route
): TableViewProps => ({
  resource: "planning",
  settings: "planning/settings",
  dictionaries: "projects/dictionaries",
  filters: ForecastFilters,
  ...props,
  scope: { ...props.scope, query: route.query, filters }
});

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: "/planning",
      title: "office.navigation.root.planning",
      icon: "mdi-calendar-month",
      permission: "CanViewInstallations"
    },
    ...path
  ];
}

export default [
  {
    path: "planning/forecast",
    components: {
      default: Forecast,
      navigation: PlanningNavigation,
      sidepanel: TableFilters
    },
    props: {
      settings: "planning/forecast/settings",
      sidepanel: tableViewProps({
        scope: {},
        showColumns: false,
        showPagination: false,
        settings: "planning/forecast/settings"
      })
    },
    meta: {
      authorize: ["CanViewProjects"],
      breadcrumb: breadcrumb([
        { title: "office.navigation.planning.forecast" }
      ]),
      hasFilters: true,
      hasSideNav: true
    }
  }
] as RouteConfig[];
