





















import Vue, { PropType } from 'vue';
import { Column, CellRenderers, DataTableOptions, Nullable, Pagination } from '@/types';

import WDataTable from '@/components/table';

export default Vue.extend({
  props: {
    resource: String,
    settings: String,
    columns: Array as PropType<string[]>,
    value: Array as PropType<any[]>,
    itemKey: {
      type: String,
      default: () => 'id',
    },
    itemClass: [String, Function] as PropType<string | ((item: any) => string)>,
    selectableFirst: Boolean,
    isSelectable: {
      type: Function as PropType<(item: any) => boolean>,
    },
    params: Object as PropType<Record<string, any>>,
    relationId: String,
    showSearch: Boolean,
  },

  components: {
    WDataTable,
  },

  data() {
    return {
      searchTerm: '',
      timeout: null as Nullable<number>,
      data: null as Nullable<Pagination<any>>,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      } as DataTableOptions,
    };
  },

  computed: {
    page(): number {
      return this.options.page;
    },

    serverItemsLength(): number {
      return this.data?.meta.total ?? 0;
    },

    items(): any[] {
      let items = this.data?.data ?? [];

      if (this.isSelectable) {
        items = items.map((item: any) => ({ ...item, isSelectable: this.isSelectable(item) }));
      }

      if (this.selectableFirst) {
        const selectable = items.filter(item => item.isSelectable);
        const notSelectable = items.filter(item => !item.isSelectable);

        return [...selectable, ...notSelectable];
      }

      return items;
    },

    headers(): Column[] {
      return this.$store.getters[`${this.settings}/columns:selection`](this.columns);
    },

    renderers(): CellRenderers {
      return this.$store.getters[`${this.settings}/renderers`];
    },

    isPending() {
      return this.$store.getters[`${this.resource}/isPending`];
    },
  },

  methods: {
    async fetch() {
      this.data = await this.$store.dispatch(`${this.resource}/fetch:all:page`, {
        query: this.searchTerm ?? undefined,
        page: this.options.page,
        perPage: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : this.serverItemsLength,
        params: this.params,
        sort: this.options,
        relationId: this.relationId,
      });
    },
  },

  watch: {
    searchTerm: {
      handler(val: string) {
        if (this.timeout) window.clearTimeout(this.timeout);

        this.timeout = window.setTimeout(() => this.fetch(), 500);
      },
    },
  },
});
