import SelectDocumentType from '@/apps/office/containers/table/houses/dialogs/steps/SelectDocumentType.vue';
import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';
import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import { House, ActionDialogAttributes, DocumentType, Project } from '@/types';

type FormData = {
  selectedHouses: House[];
  documentType: DocumentType[][];
  project: Project;
};

export default WithSteps<FormData, ActionDialogAttributes<House>>({
  title: 'office.dialogs.houses.sendDocumentFiles.title',

  formData() {
    const project: Project = this.$store.getters['projects/details'](
      this.$store.state.route.params.id,
    );

    return {
      selectedHouses: this.$attrs.items.slice(),
      documentType: [],
      project,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedHouses.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['pchn', 'address', 'city', 'tenantName', 'contractState', 'panel', 'inverter'],
          settings: 'houses/settings',
          itemKey: 'pchn',
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },
    },

    {
      component: SelectDocumentType,

      sync: {
        documentType: 'documentType',
      },

      isValid() {
        return this.$data.documentType.length > 0;
      },
    },

    {
      component: Confirm,

      value: {
        get() {
          let message: string = 'office.dialogs.houses.sendDocumentFiles.steps[1].title.regular';

          return this.$t(message, [
            `<span class="font-weight-bold">${this.$data.selectedHouses.length}</span>`,
            `<span class="font-weight-bold">${this.$store.state.profile.user.email}</span>`,
          ]);
        },
      },

      labels: {
        action() {
          return this.$t('office.dialogs.houses.sendDocumentFiles.action', [
            this.$data.selectedHouses.length,
          ]).toString();
        },
      },

      async afterStep() {
        const types: string[] = this.$data.documentType.flat().map(item => item.value);
        this.$store.dispatch('projects/regularHouses/bulk:downloadDocuments', {
          projectId: this.$data.project.id,
          houses: this.$data.selectedHouses,
          documentType: types,
        });
      },
    },
  ],
});
