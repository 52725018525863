export function createDiagonalPattern(color: string = 'black') {
  const patternCanvas = document.createElement('canvas');
  const pctx: CanvasRenderingContext2D = patternCanvas.getContext('2d', {
    antialias: true,
  })! as CanvasRenderingContext2D;
  const colour = color;

  const CANVAS_SIDE_LENGTH = 10;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 4;

  patternCanvas.width = WIDTH;
  patternCanvas.height = HEIGHT;
  pctx.fillStyle = colour;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  pctx.lineTo(0, 0);
  pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Middle line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  pctx.lineTo(0, HEIGHT);
  pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  pctx.lineTo(WIDTH, 0);
  pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Bottom line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.fill();

  return pctx.createPattern(patternCanvas, 'repeat')!;
}
