import { AsyncModule } from "vuex-async-mutations";

import details from "@/store/base.details";
import paginated from "@/store/base.items.paginated";
import itemsExport from "@/store/base.items.export";
import crud from "@/store/base.crud";
import table from "@/store/modules/tables";

import { Project } from "@/types";

import columns from "@/apps/office/components/table/projects/other/columns";
import presets from "@/apps/office/components/table/projects/allprojects/presets";
import renderers from "@/apps/office/components/table/shared/items/renderers";

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<Project>("/projects"),
    items: paginated<Project>("/projects/default"),
    crud: crud<Project>("/projects"),
    export: itemsExport("/projects/default/export"),
    settings: table("projects/postalcoderose", presets, columns, renderers)
  }
} as AsyncModule<any, any>;
