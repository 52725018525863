import Vue, { PropType } from "vue";
import { Nullable, Panel } from "@/types";

type RouteFactory<T> = (item: T) => { panelType: Nullable<string> };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      panelType(): string {
        const panel = factory(this.item)?.panelType;
        if (panel) {
          return panel;
        } else {
          return this.getDefaultPanel();
        }
      }
    },

    methods: {
      getDefaultPanel(): string {
        const panels: Panel[] = this.$store.state.dictionaries.panels.items;

        const defaultPanel = panels
          ?.filter(item => item.default)
          ?.map((item: Panel) => ({
            ...item,
            id: null,
            model: `Standaard (${item.brand} ${item.output} Wp)`,
            disabled: false
          }));

        return defaultPanel[0]?.model || "";
      }
    },

    template: `
      <div>
          <span>{{panelType}}</span>
      </div>
    `
  });
