import { ProjectConversion } from '@/types';
import { FunctionalComponentOptions } from 'vue/types';

type Props = {
  item: { conversionValues: ProjectConversion[] };
};

export default (status: number, locale: string = 'nl-NL'): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    const item = props.item.conversionValues.find(conversion => conversion.forStatus === status);

    let text = '';

    if (typeof item?.conversion === 'number') {
      text = item.expectedInstallations.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return createElement('span', text);
  },
});
