import Vue, { PropType } from 'vue';

type RouteFactory<T> = (item: T) => { percentage: number };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    computed: {
      percentage(): string {
        return factory(this.item).percentage.toLocaleString('nl-NL', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
    },

    template: `
    <span>{{ percentage }}</span>
    `,
  });
