import { RouteConfig } from 'vue-router';

import SignIn from '@/pages/Account/SignIn.vue';

export default [
  {
    path: '/inloggen',
    components: { default: SignIn },
  },
] as RouteConfig[];
