import Vue from 'vue';

function install(Vue: any) {
  Vue.prototype.$clone = <T>(obj: T) => JSON.parse(JSON.stringify(obj)) as T;
}

declare module 'vue/types/vue' {
  interface Vue {
    $clone<T>(object: T): T;
  }
}

Vue.use({ install });
