import { AsyncModule } from 'vuex-async-mutations';

interface ActivationState {
  isActivated: boolean;
}

const factory = (): ActivationState => ({ isActivated: false });

export const module: AsyncModule<ActivationState, any> = {
  state: factory,

  mutations: {
    ['reset'](state) {
      Object.assign(state, factory());
    },
  },

  getters: {
    ['isActivated'](state) {
      return state.isActivated;
    },
  },

  actionsAsync: {
    ['activate']: {
      handler({ commitAsync }, { email, token }) {
        return commitAsync(this.$axios.post('/users/activate', { email, token }));
      },

      pending(state) {
        state.isActivated = false;
      },

      resolved(state) {
        state.isActivated = true;
      },
    },
  },
};

export default module;
