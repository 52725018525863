import Vue from "vue";
import { EndpointStrategy, Endpoint } from "./base";
import { AsyncModule } from "vuex-async-mutations";

interface PaginatedState<T> {
  items: Record<string, T[]>;
}

const stateFactory = () => ({
  items: {}
});

export const module = <T>(endpoint: string | EndpointStrategy) =>
  ({
    namespaced: true,
    async: true,

    state: stateFactory,

    mutations: {
      ["reset"](state) {
        Object.assign(state, stateFactory());
      }
    },

    getters: {
      ["get:items"](state) {
        return (relationId: string = "*"): T[] => {
          return state.items[relationId] ?? [];
        };
      }
    },

    actions: {
      ["reset"]: {
        root: true,
        handler({ commit }) {
          commit("reset");
        }
      }
    },

    actionsAsync: {
      ["fetch:all"]: {
        async handler(
          { commitAsync },
          {
            relationId,
            query = undefined,
            params
          }: {
            relationId?: string;
            query?: string;
            params?: Record<string, any>;
          } = {}
        ) {
          let url: Endpoint;

          if (typeof endpoint !== "string") {
            url = endpoint(relationId);
          } else {
            url = { endpoint };
          }

          const payload = this.$axios.get<T[]>(`${url.endpoint}`, {
            params: {
              query: query === null || query === "" ? undefined : query,
              ...params,
              ...url.params
            }
          });

          return commitAsync(payload, relationId);
        },

        resolved(state, items: T[], relationId: string = "*") {
          Vue.set(state.items, relationId, items);
        }
      }
    }
  } as AsyncModule<PaginatedState<T>, any>);

export default module;
