import { TablePreset } from '@/types';

function equalArray(a: any, b: any) {
  return (
    a instanceof Array && b instanceof Array && a.length === b.length && a.every(i => b.includes(i))
  );
}

export default function(a: TablePreset, b: TablePreset): boolean {
  if (a.searchTerm !== b.searchTerm) return false;
  if (a.columns.length !== b.columns.length || !a.columns.every(c1 => b.columns.includes(c1)))
    return false;
  if (JSON.stringify(a.columns) !== JSON.stringify(b.columns)) return false;

  const keysA = Object.keys(a.params).filter(
    key => a.params[key] !== undefined && a.params[key] !== null && a.params[key] !== '',
  );
  const keysB = Object.keys(b.params).filter(
    key => b.params[key] !== undefined && b.params[key] !== null && b.params[key] !== '',
  );

  if (keysA.length !== keysB.length) return false;

  return keysA.every(key => {
    return (
      a.params[key] === b.params[key] ||
      ((a.params[key] === undefined || a.params[key] === null) &&
        (b.params[key] === undefined || b.params[key] === null)) ||
      equalArray(a.params[key], b.params[key])
    );
  });
}
