















































import Vue from 'vue';
import { VueConstructor } from 'vue/types';

import TableFilters from '@/containers/TableFilters';
import TableToolbar from '@/containers/TableToolbar';
import ResourceTable from '@/containers/ResourceTable';

import { TableSettings, TableAction, ParamSettings, CellRenderers } from '@/types';

export default Vue.extend({
  props: {
    itemKey: {
      type: String,
    },

    filters: {
      type: Function,
    },

    resource: {
      type: String,
    },

    settings: {
      type: String,
    },

    dictionaries: {
      type: [String, Array],
    },

    scope: {
      type: Object as () => ParamSettings,
      default: () => ({} as ParamSettings),
    },

    actions: {
      type: Array as () => TableAction[],
      default: () => [],
    },

    navigation: {
      type: Function,
    },

    navigateTo: {
      type: Function,
      default: () => {},
    },

    relationId: {
      type: [String, Number],
    },
  },

  components: {
    TableToolbar,
    TableFilters,
    ResourceTable,
  },

  created() {
    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    this.handleResize();
  },

  methods: {
    handleResize() {
      this.$store.commit('ui/set:mainHeight', 0);
      this.$nextTick(() => {
        const main = document.getElementsByClassName('v-main__wrap')[0];

        this.$store.commit('ui/set:mainHeight', main.clientHeight - 24);
      });
    },
  },

  computed: {
    showFullscreen: {
      get(): boolean {
        return this.$store.state.ui.showFullscreen;
      },

      set(val: boolean): void {
        this.$store.commit('ui/set:showFullscreen', val);
      },
    },

    limit(): Record<string, any> {
      return this.scope.limit || {};
    },

    includedFilters(): Record<string, boolean> {
      const filters = Object.values(this.scope.filters!).filter(
        key =>
          (!this.scope.include?.length || this.scope.include?.includes(key)) &&
          !this.scope.exclude?.includes(key),
      );

      return filters.reduce(
        (params, key) => ({
          ...params,
          [key]: true,
        }),
        {},
      );
    },

    tableSettings(): string {
      return this.settings || `${this.resource}/settings`;
    },

    renderers(): CellRenderers {
      return this.$store.getters[`${this.tableSettings}/renderers`];
    },
  },
});
