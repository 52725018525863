





























import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: Number,
      default: 0
    },

    label: {
      type: String
    },

    positive: {
      type: Boolean,
      default: true
    },

    integers: {
      type: Boolean,
      default: false
    },

    digits: {
      type: Number,
      default: 0
    },

    rules: {
      type: Array,
      default: undefined
    },

    saveOnBlur: {
      type: Boolean,
      default: false
    },

    hint: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      hasFocus: false,
      input: 0
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val: number) {
        this.input = val;
      }
    },

    input() {
      if (!this.saveOnBlur) {
        this.save();
      }
    }
  },

  methods: {
    onBlur() {
      this.save();
      this.hasFocus = false;
    },

    save() {
      if (this.input === this.value) return;

      if (this.positive) this.$emit("input", Math.abs(this.input));
      else this.$emit("input", this.input);
    }
  },

  computed: {
    formattedValue(): string {
      return this.value?.toLocaleString("nl-NL", {
        minimumFractionDigits: this.digits,
        maximumFractionDigits: this.digits
      });
    }
  }
});
