import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import SetCapacityYear from './steps/SetCapacityYear.vue';
import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';
import { ActionDialogAttributes, Installer, InstallerCapacity } from '@/types';

type FormData = {
  overwrite: boolean;
  selectedInstallers: Installer[];
  formValid: boolean;
  capacity: number;
  year: number;
  result: InstallerCapacity[];
};

export default WithSteps<FormData, ActionDialogAttributes<Installer>>({
  title: 'office.dialogs.installations.capacity.fill.title',

  width: 600,

  formData() {
    return {
      overwrite: false,
      selectedInstallers: this.$attrs.items.slice(),
      formValid: false,
      capacity: 0,
      year: new Date().getFullYear(),
      result: [],
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedInstallers.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['id', 'name'],
          settings: 'installers/settings',
          itemKey: 'id',
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedInstallers;
        },

        set(items: Installer[]) {
          this.$data.selectedInstallers = items;
        },
      },
    },

    {
      component: SetCapacityYear,

      sync: {
        year: 'year',
        capacity: 'capacity',
        overwrite: 'overwrite',
      },

      isValid() {
        return this.$data.formValid;
      },

      value: {
        get() {
          return this.$data.formValid;
        },

        set(valid: boolean) {
          this.$data.formValid = valid;
        },
      },

      async afterStep() {
        for (let installer of this.$data.selectedInstallers) {
          const result: InstallerCapacity[] = await this.$store.dispatch('planning/capacity/fill', {
            installerId: installer.id,
            capacity: this.$data.capacity,
            year: this.$data.year,
            overwrite: this.$data.overwrite,
          });

          this.$data.result = this.$data.result.concat(result);
        }
      },
    },

    {
      component: Confirm,

      value: {
        get() {
          return this.$t('office.dialogs.installations.capacity.fill.result', [
            `<span class="font-weight-bold">${this.$data.result.length}</span>`,
          ]);
        },
      },
    },
  ],
});
