import { RouteConfig, Route } from 'vue-router';

import Table from '@/apps/office/layouts/table.vue';

import { TableViewProps, AuthorizedRoute } from '@/types';
import router from './routes';

import filters from '@/apps/office/components/table/batches/filters';

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: '/batches',
      title: 'office.navigation.root.batches',
      icon: 'mdi-download-multiple',
      permission: 'CanViewProjects',
    },
    ...path,
  ];
}

const tableViewProps = (props: TableViewProps) => (route: Route): TableViewProps => ({
  relationId: route.params.id,
  resource: 'batches',
  settings: 'batches/settings',
  ...props,
  scope: { ...props.scope, query: route.query, filters },
});

export default [
  {
    path: '/batches',
    components: { default: Table },
    meta: {
      authorize: ['CanViewProjects'],
      breadcrumb: breadcrumb(),
      hasFilters: true,
      hasSideNav: false,
    },
    props: {
      default: tableViewProps({}),
    },
  },
] as RouteConfig[];
