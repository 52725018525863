import Vue, { PropType } from "vue";
import { Nullable } from "@/types";
import moment from "moment";

import { VIcon, VChip, VMenu, VSpacer } from "vuetify/lib";

type RouteFactory<T> = (
  item: T
) => { startDate: Nullable<string>; endDate: Nullable<string> };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      startDate(): string | null {
        const startDate = factory(this.item).startDate;
        return startDate
          ? moment(startDate).format("DD-MM-YYYY")
          : "office.labels.fields.noDate";
      },
      endDate(): string | null {
        const endDate = factory(this.item).endDate;
        return endDate
          ? moment(endDate!).format("DD-MM-YYYY")
          : "office.labels.fields.noDate";
      }
    },

    components: {
      VIcon,
      VChip,
      VMenu,
      VSpacer
    },

    template: `
    <div>
      <v-chip label outlined>
        <span>{{ $t(startDate) }}</span>
        <span class='mx-2'>/</span>
        <span>{{ $t(endDate) }}</span>
      </v-chip>
    </div>
    `
  });
