






import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      show: false,
    };
  },

  computed: {
    message() {
      return this.$store.state.ui.toastMessage;
    },
  },

  watch: {
    message: {
      immediate: true,
      handler(msg: string) {
        this.show = !!msg;
      },
    },
  },
});
