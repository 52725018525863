import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';
import { ActionDialogAttributes } from '@/types';

export default WithSteps<any, ActionDialogAttributes>({
  title: 'office.dialogs.export.title',

  formData() {
    return {};
  },

  steps: [
    {
      component: Confirm,

      value: {
        get() {
          return this.$t('office.dialogs.export.message', [
            `<span class="font-weight-bold">${this.$attrs.numberOfItems}</span>`,
            `<span class="font-weight-bold">${this.$store.state.profile.user.email}</span>`,
          ]);
        },
      },

      labels: {
        action: 'office.dialogs.export.action',
      },

      async afterStep() {
        const proposition = this.$store.state.route.name;
        await this.$store.dispatch(`projects/${proposition}/export:all`, {
          relationId: this.$attrs.relationId,
        });
      },
    },
  ],
});
