import { FunctionalComponentOptions } from 'vue/types';

export default (value: number | string[]): FunctionalComponentOptions => ({
  functional: true,
  render(createElement) {
    let text;

    if (typeof value === 'number') {
      text = Math.ceil(Math.random() * value) + '';
    } else {
      const index = Math.floor(Math.random() * value.length);
      text = value[index];
    }
    return createElement('span', text);
  },
});
