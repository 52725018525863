




























import Vue, { PropType } from 'vue';

export default Vue.extend({
  data() {
    return {
      value: false,
    };
  },

  computed: {
    availableScenarios(): string[] {
      return this.$store.getters['mock/availableScenarios'];
    },

    current: {
      get(): string {
        return this.$store.state.mock.current;
      },

      set(val: string) {
        this.$store.dispatch('mock/set:current', val);
      },
    },

    isProduction(): boolean {
      return this.$store.getters['mock/isProduction'];
    },
  },
});
