











import Vue, { PropType } from 'vue';
import { Nullable, House, Testimonial } from '@/types';

export default Vue.extend({
  props: {
    selectedHouses: Array as PropType<House[]>,
    testimonialCity: String,
    testimonialSentence: String,
    testimonialTenant: String,
    updateTestimonial: {
      type: Boolean,
      default: false,
    },
    updatePanelsAndInverters: {
      type: Boolean,
      default: false,
    },
    previewTitle: String,
    previewType: String,
    type: String,
  },
  data() {
    return {
      html: null as Nullable<string>,
    };
  },
  async created() {
    this.fetchPreview();
  },
  methods: {
    Testimonials(update: boolean): Testimonial | {} {
      return update
        ? {
            testimonialCity: this.testimonialCity,
            testimonialSentence: this.testimonialSentence,
            testimonialTenant: this.testimonialTenant,
          }
        : {};
    },
    async fetchPreview() {
      const htmlPreview = await this.$store.dispatch(
        `projects/regularHouses/preview:${this.type}`,
        {
          projectId: this.$router.currentRoute.params.id,
          houseId: this.selectedHouses[0].id,
          ...this.Testimonials(this.updateTestimonial),
        },
      );
      this.html = htmlPreview;
    },
    openNewTab() {
      const w = window.open();
      if (w) {
        w.document.body.innerHTML = this.html!;
      }
    },
  },
});
