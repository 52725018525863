import VueI18n from 'vue-i18n';
import Vue, { VueConstructor } from 'vue';
import SideNav from '@/components/side-nav';
import { RouteFactory } from '@/types';
import { Store } from 'vuex/types';
import { Route } from 'vue-router';

type Props = {
  routes: RouteFactory;
  init?: (route: Route, store: Store<any>) => any;
};

const withSideNav = ({ routes, init }: Props) =>
  Vue.component('withSideNav', {
    data() {
      return {
        isFetching: false,
      };
    },

    render(createElement) {
      return createElement(SideNav, {
        on: this.$listeners,
        props: {
          routes: routes instanceof Array ? routes : routes(this.$store.state.route, this.$store),
          ...this.$attrs,
          value: this.$store.state.ui.showNavigation,
        },
      });
    },

    computed: {
      route(): Route {
        return this.$store.state.route;
      },
    },

    watch: {
      route: {
        immediate: true,
        async handler() {
          // this.$store.commit('ui/set:showNavigation', true);
          try {
            await init?.(this.$store.state.route, this.$store);
          } finally {
            this.isFetching = false;
          }
        },
      },
    },
  });

export default withSideNav;
