





























































































































































import { Nullable, ParamSettings, TablePreset, TableSettings } from "@/types";
import Vue from "vue";

import compare from "./compare";
import ExportDialog from "./ExportDialog";
import ImportDialog from "./ImportDialog";

type EditMode = "add" | "clone" | "edit";

export default Vue.extend({
  components: { ExportDialog, ImportDialog },
  props: {
    settings: {
      type: String,
      default: null
    },

    params: {
      type: Object as () => ParamSettings
    }
  },

  data() {
    return {
      showEditDialog: false,
      showExportDialog: false,
      showImportDialog: false,
      editPreset: null as Nullable<TablePreset>,
      editMode: null as Nullable<EditMode>
    };
  },

  computed: {
    preset: {
      get(): TablePreset {
        return this.$store.getters[`${this.settings}/presets:current`];
      },

      set(preset: TablePreset) {
        this.$store.commit(`${this.settings}/presets:set`, preset);
        this.$store.dispatch(`${this.settings}/search`);
      }
    },

    presets(): TablePreset[] {
      return this.$store.getters[`${this.settings}/presets`];
    },

    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },

    lastUsed: {
      get(): TablePreset {
        return this.tableSettings.lastUsed;
      },

      set(preset: TablePreset) {
        this.$store.commit(`${this.settings}/set:lastUsed`, preset);
      }
    },

    hasChanged(): boolean {
      return !compare(this.preset, this.lastUsed);
    }
  },

  watch: {
    showEditDialog(val) {
      if (!val) {
        this.cancel();
      }
    }
  },

  methods: {
    download() {
      this.showExportDialog = true;
    },

    upload() {
      this.showImportDialog = true;
    },

    edit() {
      if (this.preset.readonly) {
        return;
      }

      this.editPreset = this.$clone(this.preset);
      this.editMode = "edit";
      this.showEditDialog = true;
    },

    clone() {
      this.editPreset = this.$clone(this.lastUsed)!;
      this.editMode = "clone";
      this.showEditDialog = true;
    },

    add() {
      this.editPreset = { columns: [], params: {} };
      this.editMode = "add";
      this.showEditDialog = true;
    },

    remove() {
      this.$store.dispatch(`${this.settings}/preset:remove`, this.preset);
    },

    save() {
      if (!this.editPreset && this.lastUsed!.readonly) {
        return;
      }

      switch (this.editMode) {
        case "edit":
          this.$store.dispatch(
            `${this.settings}/preset:update`,
            this.editPreset
          );
          break;
        case "clone":
        case "add":
          this.$store.dispatch(`${this.settings}/preset:add`, this.editPreset);
          break;
        default:
          this.$store.dispatch(`${this.settings}/preset:update`, this.lastUsed);
          break;
      }

      this.showEditDialog = false;
    },

    cancel() {
      this.editPreset = null;
      this.editMode = null;
      this.showEditDialog = false;
    },

    undo() {
      this.$store.dispatch(`${this.settings}/reset`, {
        ...this.params.query,
        ...this.params.values
      });
    }
  }
});
