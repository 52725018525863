





















import Vue, { PropType } from "vue";
import { Column, TablePreset, TableSettings } from "@/types";

export default Vue.extend({
  props: {
    header: Object as PropType<Column>,
    columns: Array,
    settings: {
      type: String
    }
  },

  computed: {
    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },
    lastUsed: {
      get(): TablePreset {
        return this.tableSettings.lastUsed;
      },

      set(preset: TablePreset) {
        this.$store.commit(`${this.settings}/set:lastUsed`, preset);
      }
    }
  },
  methods: {
    deleteColumn(header: Column) {
      const columns: string[] = this.columns as string[];
      this.lastUsed!.columns = columns.filter(col => col != header.value);
    },
    dragStart(event: any, header: Column) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowd = "move";
      event.dataTransfer.setData("headerValue", header.value);
    },
    onDrop(event: any, header: Column) {
      const columns: string[] = this.columns as string[];
      const temp = event.dataTransfer.getData("headerValue");

      this.swap(columns, columns.indexOf(header.value), columns.indexOf(temp));

      this.lastUsed!.columns = columns;
    },
    swap(input: string[], index_A: number, index_B: number) {
      let temp = input[index_A];

      input[index_A] = input[index_B];
      input[index_B] = temp;
    }
  }
});
