import { MockScenario } from '@/util/xhr-mock';

import defaults from './default';

export default {
  dependencies: [defaults],
  name: 'project/dashboard-states',
  mocks: [
    {
      url: /api\/projects\/projects\/[0-9]+\/progress$/,
      method: 'GET',
      response: {
        body: [
          { state: 'P220', count: 75 },
          { state: 'P221', count: 34 },
          { state: 'P230', count: 170 },
          { state: 'P240', count: 92 },
        ],
        status: 200,
      },
    },
    {
      url: /api\/projects\/projects\/[0-9]+\/progress\/installation$/,
      method: 'GET',
      response: {
        body: [
          { state: 'NOT_STARTED', count: 50 },
          { state: 'METER_READY', count: 30 },
          { state: 'PANEL_READY', count: 20 },
          { state: 'READY', count: 70 },
        ],
        status: 200,
      },
    },
    {
      url: /api\/projects\/projects\/[0-9]+\/progress\/contracts$/,
      method: 'GET',
      response: {
        body: [
          {
            state: 'TO_SEND',
            count: 22,
          },
          {
            state: 'SENT',
            count: 38,
          },
          {
            state: 'ACCEPTED',
            count: 10,
          },
          { state: 'DENIED', count: 5 },
        ],
        status: 200,
      },
    },
    {
      url: /api\/projects\/projects\/[0-9]+\/progress\/planning$/,
      method: 'GET',
      response: {
        body: [
          {
            state: 'NOT_CLEARED_FOR_INSTALLATION',
            count: 11,
          },
          {
            state: 'CLEARED_FOR_INSTALLATION',
            count: 23,
          },
        ],
        status: 200,
      },
    },
    {
      url: /api\/projects\/projects\/[0-9]+\/progress\/post-installation$/,
      method: 'GET',
      response: {
        body: [
          {
            state: 'NOT_APPROVED',
            count: 13,
          },
          {
            state: 'APPROVED',
            count: 19,
          },
        ],
        status: 200,
      },
    },
    {
      url: /api\/projects\/projects\/[0-9]+\/issues\/post-installation$/,
      method: 'GET',
      response: {
        body: [
          {
            state: 'BREAKING',
            count: 31,
          },
          {
            state: 'NON_BREAKING',
            count: 29,
          },
        ],
        status: 200,
      },
    },
  ],
} as MockScenario;
