import Vue, { PropType } from "vue";

import { VChip } from "vuetify/lib";
import { ListItem, Nullable } from "@/types";

type RouteFactory<T> = (item: T) => { value: Nullable<string[]> };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      zonmaatEmployees(): string[] {
        return factory(this.item).value || [];
      }
    },

    methods: {
      translateItem(t: string): string {
        const val: string | ListItem[] = this.$t(
          "office.dictionaries.zmRequiredExpertise"
        ) as any;

        if (Array.isArray(val)) {
          const item = val.find((item: ListItem) => item.value === t);
          return item ? item.text : t;
        }
        return t;
      }
    },

    components: {
      VChip
    },

    template: `
      <div class="d-flex">
        <v-chip outlined label v-for="(employee, i) in zonmaatEmployees" :key="i" class="mr-2">
          <span>{{ translateItem(employee) }}</span>
        </v-chip>
      </div>
    `
  });
