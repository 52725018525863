









import Vue, { PropType } from "vue";
import moment from "moment";
import _ from "underscore";
import Chart, { ChartData, ChartOptions } from "chart.js";

import BarChart from "@/components/Charts/Bar.vue";

import { createDiagonalPattern } from "@/util/canvas";
import { formatNumber } from "@/util/formatters";

import {
  Forecast,
  ForecastItem,
  ForecastOverview,
  ForecastTotal,
  ForecastTotals,
  Nullable,
  Project,
  WeekForecast
} from "@/types";

type ForecastOrderBy = keyof Forecast;

export default Vue.extend({
  props: {
    data: {
      type: Object as PropType<ForecastOverview>
    },
    start: {
      type: String,
      default: () => `${new Date().getFullYear()}-01-01`
    },
    showCapacity: {
      type: Boolean,
      default: true
    },
    showTargets: {
      type: Boolean,
      default: true
    },
    numberOfWeeks: {
      type: Number,
      default: 10
    },
    projects: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    projectDetails: {
      type: Array as PropType<Project[]>,
      default: () => []
    },
    installers: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    hocos: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    preview: {
      type: Boolean,
      default: false
    },
    showQuarters: {
      type: Boolean,
      default: false
    },
    showTotals: {
      type: Boolean,
      default: false
    },
    showStats: {
      type: Boolean,
      default: false
    }
  },

  components: {
    BarChart
  },

  data() {
    return {
      chart: null as Nullable<any>,
      activeDatasets: [0, 1, 2, 3, 4, 5, 6, 7] as number[]
    };
  },

  methods: {
    handleLegendClick(event: MouseEvent, item: Chart.ChartLegendLabelItem) {
      if (this.activeDatasets.includes(item.datasetIndex!)) {
        this.activeDatasets.splice(
          this.activeDatasets.indexOf(item.datasetIndex!),
          1
        );
      } else {
        this.activeDatasets.push(item.datasetIndex!);
      }
    },

    getAggregatedData(data: WeekForecast[]): number[] {
      return this.weeks.map(week => {
        const weekData = data.find(
          d => d.week === week.week && d.year === week.year
        );

        return (
          weekData?.data.reduce((total, current) => {
            if (
              this.projects.includes(current.projectId) ||
              (this.preview && this.projects.length === 0)
            )
              return total + current.installationsThisWeek;

            return total;
          }, 0) ?? 0
        );
      });
    },

    getTotals(source: number, data: number[] = []): ForecastTotal {
      const enabled = this.activeDatasets.includes(source);

      return {
        enabled,
        value: data.reduce((sum, val) => sum + val, 0)
      };
    },

    getSortedStats(dataset: number, index: number, groupBy: keyof Forecast) {
      const week: ForecastItem = this.weeks[index];
      const forecast = [
        this.data.finished,
        this.data.workQueue,
        this.data.distributedWorkQueue,
        this.data.expectedWorkQueue,
        this.data.planned
      ];

      let items: Forecast[] =
        forecast[dataset].find(
          item => item.week === week.week && item.year === week.year
        )?.data ?? [];

      items = items.filter(item => this.projects.includes(item.projectId));
      return _.sortBy(items, "installationsThisWeek").reverse();
    },

    getStats(item: Chart.ChartTooltipItem[], data: ChartData): string[] {
      if (!this.showStats || !item.length || item[0].datasetIndex! > 4)
        return [];
      const stats = this.getSortedStats(
        item[0].datasetIndex!,
        item[0].index!,
        "projectId"
      );

      const items = stats
        .filter(item => item.installationsThisWeek > 0)
        .slice(0, 10)
        .map(item => {
          const project = this.projectDetails.find(
            p => p.id === item.projectId
          );

          return `${formatNumber(item.installationsThisWeek, 0)} → [${
            project?.code
          }] ${project?.region ?? item.projectId}`;
        });

      return [`Top ${items.length} Projecten`, " ", ...items];
    }
  },

  computed: {
    totals(): ForecastTotals {
      const data = this.dataSource;

      return {
        finished: this.getTotals(0, data[0]),
        workQueue: this.getTotals(1, data[1]),
        distributedWorkQueue: this.getTotals(2, data[2]),
        expectedWorkQueue: this.getTotals(3, data[3]),
        planned: this.getTotals(4, data[4]),
        capacity: this.getTotals(5, this.capacity),
        targets: this.targets.slice(-1).pop(),
        year: this.weeks.slice(-1).pop()?.year ?? 0
      };
    },

    options(): ChartOptions {
      const data = this.dataSource.filter((ds, i) =>
        this.activeDatasets.includes(i)
      );
      const weeks = this.weeks;

      return {
        maintainAspectRatio: false,
        legend: {
          onClick: this.handleLegendClick
          // position: 'bottom',
        },
        bezierCurve: false,
        onClick(event, elements) {},
        scales: {
          xAxes: [
            {
              id: "xWeeks",
              stacked: true,
              type: "category",
              gridLines: {
                tickMarkLength: 0,
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              ticks: {
                padding: 8,
                autoSkip: false,
                autoSkipPadding: 0,
                callback(label, index, values) {
                  const [year, week] = (label as string).split(":");

                  if ((+week - 1) % 2 === 0) {
                    return `wk${week}`;
                  }

                  return null;
                }
              }
            },
            {
              id: "xQuarterLines",
              type: "category",
              position: "top",
              display: this.showQuarters,
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0
                // drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
              ticks: {
                autoSkipPadding: 0,
                maxRotation: 0,
                autoSkip: false,
                padding: 5,
                align: "left",
                fontSize: 15,
                lineHeight: 0,
                callback: function(label: string, index, values) {
                  const [year, week] = label.split(":");
                  if (+week <= 43 && (+week - 1) % 13 === 0) {
                    return "";
                  }

                  return null;
                }
              }
            },
            {
              id: "xQuarters",
              type: "category",
              position: "top",
              display: this.showQuarters,
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              ticks: {
                autoSkipPadding: 0,
                maxRotation: 0,
                autoSkip: false,
                padding: 5,
                align: "center",
                fontSize: 15,
                callback: function(label: string, index, values) {
                  const [year, week] = label.split(":");
                  if (
                    (index === 0 && +week % 13 > 7) ||
                    (index + 1 === weeks.length &&
                      +week % 13 > 0 &&
                      +week % 13 < 7 &&
                      +week < 52) ||
                    (+week <= 50 && +week % 13 === 7)
                  ) {
                    return [`Q${Math.ceil(+week / 13)}'${+year}`];
                  }

                  return null;
                }
              }
            },
            {
              id: "xTotals",
              type: "category",
              position: "top",
              display: this.showQuarters,
              gridLines: {
                drawBorder: false,
                tickMarkLength: 0,
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              ticks: {
                maxRotation: 0,
                autoSkip: false,
                autoSkipPadding: 0,
                align: "center",
                fontSize: 16,
                fontStyle: "bold",
                callback: function(label: string, index) {
                  const [year, week] = label.split(":");
                  if (
                    (index === 0 && +week % 13 > 7) ||
                    (index + 1 === weeks.length &&
                      +week % 13 > 0 &&
                      +week % 13 < 7 &&
                      +week < 52) ||
                    (+week <= 50 && +week % 13 === 7)
                  ) {
                    const quarter = Math.min(4, Math.ceil(+week / 13));
                    const quarterValues = weeks.flatMap((week, i) => {
                      if (
                        week.year === +year &&
                        week.week > (quarter - 1) * 13 &&
                        (quarter === 4 || week.week <= quarter * 13)
                      )
                        return data.map(source => source[i]);

                      return [0];
                    });

                    const total = formatNumber(
                      quarterValues.reduce((tot, val) => tot + val, 0),
                      0
                    );

                    return [`${total}`];
                  }

                  return null;
                }
              }
            }
          ],
          yAxes: [
            {
              id: "weeks",
              ticks: {},
              stacked: true,
              position: "left"
            },
            {
              id: "total",
              display: this.showTotals && this.activeDatasets.includes(5),
              gridLines: false,
              position: "right"
            }
          ]
        },

        tooltips: {
          xPadding: 10,
          yPadding: 10,
          footerMarginTop: 20,
          footerFontStyle: "regular",
          callbacks: {
            label: function(tooltipItem, data) {
              var label =
                data.datasets?.[tooltipItem.datasetIndex!]?.label || "";

              if (label) {
                label += ": ";
              }
              label += formatNumber(+tooltipItem.yLabel!, 1);
              return label;
            },

            title: function(items, data) {
              return items.flatMap(item => {
                const [year, week] = item.label!.split(":");

                return [`Week ${week} (${year})`];
              });
            },

            footer: this.getStats
          }
        }
      } as ChartOptions;
    },

    weeks(): ForecastItem[] {
      return new Array(this.numberOfWeeks).fill(null).map((d, i) => {
        const date = moment(this.start)
          .set("weekday", 0)
          .add(i, "weeks");

        return {
          year: date.get("year"),
          week: date.get("week"),
          count: 0
        };
      });
    },

    capacity(): number[] {
      const data = this.data.capacity;

      if (!this.showCapacity) return [];

      return this.weeks.map(week => {
        const weekData = data.filter(
          d =>
            d.week === week.week &&
            d.year === week.year &&
            (this.installers.includes(d.installerId!) ||
              (this.preview && this.installers.length === 0))
        );

        const capacity =
          weekData.reduce((total, current) => total + current.capacity, 0) ?? 0;

        return capacity;
      });
    },

    targets(): number[] {
      const data = this.data.targets;

      if (!this.showTargets) return [];

      return this.weeks.map(week => {
        const weekData = data.filter(
          d =>
            d.year === week.year && this.hocos.includes(d.housingCooperativeId!)
        );

        const targets =
          weekData.reduce((total, current) => total + current.target, 0) ?? 0;

        return targets;
      });
    },

    dataSource(): number[][] {
      return [
        this.getAggregatedData(this.data.finished),
        this.getAggregatedData(this.data.workQueue),
        this.getAggregatedData(this.data.distributedWorkQueue),
        this.getAggregatedData(this.data.expectedWorkQueue),
        this.getAggregatedData(this.data.planned)
      ];
    },

    totalOfTotals(): number[] {
      const dataSource = this.dataSource.filter((ds, i) =>
        this.activeDatasets.includes(i)
      );
      let sum = 0;

      return this.weeks.map((wk, i) => {
        const val = dataSource.reduce((tot, ds) => tot + (ds[i] ?? 0), 0);

        sum += val;

        return sum;
      });
    },

    chartData(): ChartData {
      const activeDatasets = this.activeDatasets;

      return {
        labels: this.weeks.map(item => {
          return `${item.year}:${item.week}`;
        }),
        datasets: [
          {
            // barThickness: 10,
            label: "Goedgekeurd (P250)",
            backgroundColor: "#66BB6A",
            data: this.dataSource[0],
            hidden: !activeDatasets.includes(0),
            xAxisID: "xWeeks"
          },
          {
            label: "Werkvoorraad (installatiedatum)",
            backgroundColor: "#3949AB",
            data: this.dataSource[1],
            hidden: !activeDatasets.includes(1),
            xAxisID: "xWeeks"
          },
          {
            label: "Werkvoorraad (installatieperiode)",
            backgroundColor: "#42C5FE",
            data: this.dataSource[2],
            hidden: !activeDatasets.includes(2),
            xAxisID: "xWeeks"
          },
          {
            label: "Verwachte werkvoorraad",
            backgroundColor: createDiagonalPattern("#42C5FE"),
            borderColor: "#42C5FE",
            hidden: !activeDatasets.includes(3),
            data: this.dataSource[3],
            xAxisID: "xWeeks"
          },
          {
            label: "Geplande projecten",
            borderColor: "rgba(255, 179, 0, 1)",
            borderWidth: 2,
            hidden: !activeDatasets.includes(4),
            backgroundColor: "rgba(255, 179, 0, 0.2)",
            data: this.dataSource[4],
            xAxisID: "xWeeks"
          },
          {
            label: "Totaal",
            type: "line",
            hidden: !activeDatasets.includes(5),
            borderColor: "purple",
            // borderWidth: 3,
            backgroundColor: "rgba(0,0,0,0)",
            data: this.totalOfTotals,
            // pointRadius: 0,
            xAxisID: "xWeeks",
            yAxisID: "total",
            pointRadius: 3,
            hideInLegendAndTooltip: !this.showTotals
          },
          {
            label: "Capaciteit",
            // backgroundColor: 'rgba(0,0,0,0)',
            borderColor: "",
            data: this.capacity,
            type: "line",
            id: "capaciteit",
            hidden: !this.showCapacity || !activeDatasets.includes(6),
            hideInLegendAndTooltip: !this.showCapacity,
            // pointRadius: 0,
            xAxisID: "xWeeks"
          },
          {
            label: "Woco target",
            type: "line",
            hidden: !this.showTargets || !activeDatasets.includes(7),
            borderColor: "orange",
            backgroundColor: "rgba(0,0,0,0)",
            data: this.targets,
            // pointRadius: 0,
            xAxisID: "xWeeks",
            yAxisID: "total",
            pointRadius: 1,
            hideInLegendAndTooltip: !this.showTotals,
            tension: 0
          }
        ].filter(d => !d.hideInLegendAndTooltip)
      } as ChartData;
    }
  },

  watch: {
    totals: {
      immediate: true,
      handler() {
        this.$emit("update:totals", this.totals);
      }
    }
  }
});
