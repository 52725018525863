import { render, staticRenderFns } from "./ProjectDescription.vue?vue&type=template&id=1b373d48&scoped=true&"
import script from "./ProjectDescription.vue?vue&type=script&lang=ts&"
export * from "./ProjectDescription.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b373d48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VRow,VTextarea})
