












import { DynamicDocumentType } from '@/types';
import Vue from 'vue';
import DeliveryDocument from './DeliveryDocument.vue';

import documents from '@/apps/office/static/data/documents';
import { DynamicDocumentGroup, DynamicDocumentItemData } from '@/types.dynamic-document';

type Result = {
  pchn: string;
  data: DynamicDocumentItemData[];
};

export default Vue.extend({
  components: {
    DeliveryDocument,
  },

  data() {
    return {
      types: [
        { value: 'PANELS-REGULAR', text: 'Buiten (regulier)' },
        { value: 'METER-REGULAR', text: 'Binnen (regulier)' },
        { value: 'METER-REGULAR_MAAS', text: 'Binnen (monitoring-only)' },
        { value: 'TENANT', text: 'Bewoner' },
      ],
      type: 'PANELS-REGULAR' as DynamicDocumentType,
      documents: [] as Result[],
    };
  },

  computed: {
    groups(): DynamicDocumentGroup[] {
      return documents[this.type];
    },

    data(): DynamicDocumentItemData[] {
      return this.documents.flatMap(item => item.data);
    },
  },

  mounted() {
    this.fetch();
  },

  watch: {
    type() {
      this.fetch();
    },
  },

  methods: {
    async fetch() {
      this.documents = await this.$store.dispatch('deliveryDocuments/fetch', this.type);
    },
  },
});
