import Vue, { PropType } from 'vue';

import { VIcon } from 'vuetify/lib';

type RouteFactory<T> = (
  item: T,
) => { url?: string; title?: string; icon?: string; iconNoUrl?: string };

export default <T = any>(factory: RouteFactory<T>, icon?: string, iconNoUrl?: string) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    components: {
      VIcon,
    },

    data() {
      const props = factory(this.item);

      return {
        icon: props.icon ?? icon,
        iconNoUrl: props.iconNoUrl ?? iconNoUrl,
        title: props.title,
        url: props.url,
        active: 'none',
      };
    },

    template: `
    <a :href="url" target="_empty" v-bind:style="[!url ? { pointerEvents: active } : {}]">
      <v-icon v-if="url" color="success" left v-text="icon" size="20" />
      <v-icon v-else disabled color="grey lighten-2" left v-text="iconNoUrl" size="20" />
      <span v-if="title" v-text="title" />
    </a>
    `,
  });
