































































import Vue from 'vue';

import RangePicker from '@/components/range-picker/RangePicker.vue';

export default Vue.extend({
  components: { RangePicker },
  props: {
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
    },
    params: {
      type: Object as () => Record<string, any>,
    },
    setParam: {
      type: Function,
    },

    clearParam: {
      type: Function,
    },
  },
  data() {
    return {
      localDateType: '',
    };
  },

  watch: {
    params: {
      immediate: true,
      handler(val) {
        if (this.includesBeforeOrAfter) {
          this.localDateType = 'customSelection';
        } else if (this.includesNextDays) {
          this.localDateType = 'nextDays';
        } else if (this.includesPreviousDays) {
          this.localDateType = 'previousDays';
        } else if (this.includesNoDate) {
          this.localDateType = 'noDate';
        } else if (this.includesDateRange) {
          this.localDateType = val[`${this.type}DateRange`];
        } else {
          this.localDateType = '';
        }
      },
    },
  },
  methods: {
    paramClearer(params: string[]): void {
      params.forEach((param: string) => {
        this.clearParam(`${this.type}${param}`);
      });
    },
    keyHandler(e: any) {
      if (e.key === '-' || e.key === '+') {
        e.preventDefault();
      }
    },
    clear() {
      this.paramClearer(['DateRange', 'Before', 'After', 'NextDays', 'PreviousDays', 'NoDate']);
    },
  },
  computed: {
    includesNoDate() {
      return Object.keys(this.params).includes(`${this.type}NoDate`);
    },
    includesBeforeOrAfter() {
      return Object.keys(this.params).includes(`${this.type}Before` || `${this.type}After`);
    },
    includesNextDays(): boolean {
      return Object.keys(this.params).includes(`${this.type}NextDays`);
    },
    includesPreviousDays(): boolean {
      return Object.keys(this.params).includes(`${this.type}PreviousDays`);
    },
    includesDateRange(): boolean {
      return Object.keys(this.params).includes(`${this.type}DateRange`);
    },
    dateType: {
      get(): string {
        return this.localDateType;
      },
      set(val) {
        switch (val) {
          case 'customSelection':
            this.setParam(`${this.type}Before`, null);
            this.setParam(`${this.type}After`, null);
            this.paramClearer(['DateRange', 'NextDays', 'PreviousDays', 'NoDate']);
            break;
          case 'previousDays':
            this.setParam(`${this.type}PreviousDays`, 0);
            this.paramClearer(['NextDays', 'DateRange', 'Before', 'After', 'NoDate']);
            break;
          case 'nextDays':
            this.setParam(`${this.type}NextDays`, 0);
            this.paramClearer(['PreviousDays', 'DateRange', 'Before', 'After', 'NoDate']);
            break;
          case 'noDate':
            this.setParam(`${this.type}NoDate`, true);
            this.paramClearer(['PreviousDays', 'NextDays', 'DateRange', 'Before', 'After']);
            break;
          case 'today':
          case 'week':
          case 'month':
          case 'year':
            this.setParam(`${this.type}DateRange`, val);
            this.paramClearer(['Before', 'After', 'NextDays', 'PreviousDays', 'NoDate']);
            break;
        }
      },
    },
    previousPicker: {
      get(): number | null {
        return this.params[`${this.type}PreviousDays`];
      },
      set(val: number | string) {
        this.setParam(`${this.type}PreviousDays`, val);
      },
    },
    nextPicker: {
      get(): number | null {
        return this.params[`${this.type}NextDays`];
      },
      set(val: number | string) {
        this.setParam(`${this.type}NextDays`, val);
      },
    },
  },
});
