



























































import Vue, { PropType } from 'vue';
import moment from 'moment';
import _ from 'underscore';

import rules, { ValidationRules } from '@/util/forms';

import { InstallerCapacity, Installer, Pagination } from '@/types';

export default Vue.extend({
  props: {
    item: {
      type: Object as PropType<InstallerCapacity>,
    },

    value: Boolean,

    installerEditable: Boolean,
  },

  computed: {
    year: {
      get(): number {
        return this.item.year;
      },

      set({ value }: { value: number }) {
        this.item.year = value;
      },
    },

    week: {
      get(): number {
        return this.item.week;
      },

      set({ value }: { value: number }) {
        this.item.week = value;
      },
    },

    years(): any[] {
      return new Array(100).fill(null).map((d, i) => {
        const year = 2000 + i;
        const weeks = moment(`${year}-01-01`).isoWeeksInYear();

        return {
          value: year,
          text: year,
          disabled: this.monthlyCapacity.filter(item => item.year === year).length === weeks,
        };
      });
    },

    weeks(): any[] {
      return new Array(this.numberOfWeeks).fill(null).map((d, i) => ({
        value: i + 1,
        text: i + 1,
        disabled: this.monthlyCapacity.some(
          item => item.year === this.item.year && item.week === i + 1,
        ),
      }));
    },

    weekText(): string {
      const start = moment()
        .set('year', this.item.year)
        .set('week', this.item.week)
        .set('weekday', 0);

      const end = moment(start).set('weekday', 6);

      return `${start.format('dddd DD/MM/YYYY')} - ${end.format('dddd DD/MM/YYYY')}`;
    },

    numberOfWeeks(): number {
      return moment(`${this.item.year}-01-01`).isoWeeksInYear();
    },

    formValid: {
      get(): boolean {
        return this.value;
      },

      set(value: boolean) {
        this.$emit('input', value);
      },
    },

    installerId(): number {
      return this.item.installerId;
    },

    installers(): Installer[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, 'name');
    },

    monthlyCapacity(): InstallerCapacity[] {
      const page: Pagination<InstallerCapacity> = this.$store.getters['planning/capacity/page'](
        1,
        this.installerId,
      );

      return page?.data ?? [];
    },

    date() {
      return `${this.item.year}-${this.item.week}`;
    },

    rules(): ValidationRules {
      return {
        ...rules,
        duplicate: () => {
          return (
            !this.monthlyCapacity.find(
              item =>
                item.id !== this.item.id &&
                item.year === this.item.year &&
                item.week === this.item.week,
            ) || this.$t('office.validation.capacityDuplicate', [this.monthYear])
          );
        },
      };
    },

    monthYear(): string {
      return moment(this.date).format('MMMM YYYY');
    },
  },

  watch: {
    installerId: {
      immediate: true,
      handler() {
        this.setCapacity();
      },
    },
  },

  methods: {
    async setCapacity() {
      if (!this.installerId || this.item.id) return;

      try {
        await this.$store.dispatch('planning/targets/fetch:all:page', {
          relationId: this.installerId,
          perPage: 1000,
        });
      } finally {
        this.setCapacityForYear(new Date().getFullYear());
      }
    },

    setCapacityForYear(year: number) {
      const capacity: InstallerCapacity[] = _.sortBy(
        this.monthlyCapacity.filter(item => item.year === year),
        'week',
      ).reverse();

      let week = 1;

      if (capacity.length) {
        week = capacity[0].week;

        if (week < this.numberOfWeeks) {
          week += 1;
        } else {
          this.setCapacityForYear(year + 1);
          return;
        }
      }

      this.item.year = year;
      this.item.week = week;
    },
  },
});
