import { Module } from 'vuex/types';
import { Language } from '@/types';
import { $t } from '@/plugins/i18n';

const languages: Language[] = [
  {
    code: 'nl',
    locale: 'nl-NL',
    flag: 'nl',
  },
  {
    code: 'en',
    locale: 'en-GB',
    flag: 'gb',
  },
];

export interface UIState {
  darkMode: boolean;
  language: Language;
  toastMessage: string;
  timeout?: number;
  showNavigation: boolean;
  showFilters: boolean;
  activeFilters: number;
  mainHeight: number;
}

export default {
  namespaced: true,

  state: {
    darkMode: false,
    language: languages[0],
    toastMessage: '',
    showNavigation: false,
    showFilters: false,
    activeFilters: 0,
    mainHeight: 0,
  },

  getters: {
    languages() {
      return languages;
    },
  },

  mutations: {
    ['set:darkMode'](state, darkMode: boolean) {
      state.darkMode = darkMode;
    },

    ['set:language'](state, language: Language) {
      state.language = language;
    },

    ['set:toastMessage'](state, message: string) {
      state.toastMessage = message;
    },

    ['set:timeout'](state, timeout: number) {
      state.timeout = timeout;
    },

    ['set:showNavigation'](state, show: boolean) {
      state.showNavigation = show;
    },

    ['set:showFilters'](state, show: boolean) {
      state.showFilters = show;
    },

    ['set:activeFilters'](state, activeFilters: number) {
      state.activeFilters = activeFilters;
    },

    ['set:mainHeight'](state, height: number) {
      state.mainHeight = height;
    },
  },

  actions: {
    ['set:toastMessage']({ commit, state }, message: string) {
      clearTimeout(state.timeout);

      const timeout = setTimeout(() => commit('set:toastMessage', ''), 5000);

      commit('set:toastMessage', message);
      commit('set:timeout', timeout);
    },

    ['set:clipboard'](
      { dispatch },
      { message, color = 'white' }: { message: string; color: string },
    ) {
      dispatch(
        'set:toastMessage',
        $t('messages.clipboard', [`<span class="${color}--text">${message}</span>`]),
      );
    },
  },
} as Module<UIState, any>;
