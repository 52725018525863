




















































import { CellRenderers, Column } from "@/types";
import Vue, { FunctionalComponentOptions, PropType, VueConstructor } from "vue";

type ColumnRenderer = Column & {
  valueToRender?: any;
  renderer?: VueConstructor | FunctionalComponentOptions;
};

export default Vue.extend({
  props: {
    item: Object,
    values: Array as PropType<string[]>,
    renderers: Object as PropType<CellRenderers>,
    columns: Array as PropType<Column[]>,
    editable: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    hideValues: Boolean,
    split: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    rows(): ColumnRenderer[] {
      return this.values
        .map(value => this.columns.find(col => col.value === value)!)
        .map(col => ({
          ...col,
          text: this.$t(col?.text!).toString(),
          renderer: this.renderers[`item.${col?.value}`],
          header: this.renderers[`header.${col?.value}`]
        }));
    }
  }
});
