





































































import Vue, { PropType } from 'vue';
import { Testimonial, HousingCooperative, Project } from '@/types';

export default Vue.extend({
  props: {
    testimonialCity: String,
    testimonialSentence: String,
    testimonialTenant: String,
    updateTestimonial: Boolean,
    updateTestimonialValid: Boolean,
    testimonialType: {
      type: String,
      default: 'generate-contracts',
    },
  },
  data() {
    return {
      testimonialTenantRule: [
        (v: string) => !!v || this.$t('office.dialogs.houses.editTestimonial.rules[0].min'),
        (v: string) =>
          v?.length <= 25 || this.$t('office.dialogs.houses.editTestimonial.rules[0].max'),
      ],
      testimonialCityRule: [
        (v: string) => !!v || this.$t('office.dialogs.houses.editTestimonial.rules[1].min'),
        (v: string) =>
          v?.length <= 25 || this.$t('office.dialogs.houses.editTestimonial.rules[1].max'),
      ],
      testimonialSentenceRule: [
        (v: string) => !!v || this.$t('office.dialogs.houses.editTestimonial.rules[2].min'),
        (v: string) =>
          v?.length <= 110 || this.$t('office.dialogs.houses.editTestimonial.rules[2].max'),
      ],
    };
  },
  mounted() {
    this.$emit('update:testimonialCity', this.testimonial.city);
    this.$emit('update:testimonialSentence', this.testimonial.sentence);
    this.$emit('update:testimonialTenant', this.testimonial.tenant);
  },
  computed: {
    valid: {
      get(): boolean {
        return this.updateTestimonialValid;
      },
      set(val: boolean) {
        this.$emit('update:updateTestimonialValid', val);
      },
    },
    project(): Project {
      return this.$store.getters['projects/details'](this.$router.currentRoute.params.id);
    },
    testimonial(): Testimonial {
      const item = this.$store.state.dictionaries.housingCooperatives.items.find(
        (hoco: HousingCooperative) => hoco.name === this.project?.housingCooperativeName,
      );
      if (this.testimonialType === 'generate-projectlaunch') {
        return item?.projectLaunchTestimonial;
      } else {
        return item?.contractTestimonial;
      }
    },
  },
});
