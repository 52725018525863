import Vue from 'vue';
import store from '@/apps/office/store';
import authRoles from '@/directives/roles';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('roles', authRoles(store));
  },
};

Vue.use(GlobalDirectives);

export default GlobalDirectives;
