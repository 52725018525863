








import Vue from 'vue';

import BlockEditor from '@/apps/office/containers/block-editor';

export default Vue.extend({
  components: {
    BlockEditor,
  },

  props: {
    id: String,
  },
});
