











































import Vue, { PropType } from "vue";
import { Project, Nullable } from "@/types";
import date from "@/util/date";
import moment from "moment";

import DatePicker from "@/components/date-picker";

export default Vue.extend({
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    title: {
      type: String,
      default: ""
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    },
    durationInWeeks: {
      type: Number,
      default: 4
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    date() {
      return date;
    },

    startAt: {
      get(): Nullable<string> {
        return this.value[`${this.type}StartAt` as keyof Nullable<String>];
      },

      set(val: Nullable<string>) {
        this.$set(this.value, `${this.type}StartAt`, val);
        this.setEndsAt(this.type, this.durationInWeeks);
      }
    },

    duration: {
      get(): Nullable<number> {
        return this.value[`${this.type}Duration` as keyof Nullable<String>];
      },

      set(val: Nullable<number>) {
        this.$set(this.value, `${this.type}Duration`, val);
        this.setEndsAt(this.type, val);
      }
    },

    disabled() {
      if (this.value.status === 2) {
        return true;
      }

      if (!this.value.installationStartAt && this.type !== "installation") {
        return true;
      }

      return false;
    }
  },
  methods: {
    endsAtFormatted(type: string): string {
      const startAtDate: string = this.value[
        `${type}EndAt` as keyof Nullable<String>
      ];

      if (startAtDate) {
        return moment(startAtDate).format("DD-MM-YYYY");
      }

      return "";
    },
    setEndsAt(type: string, weeks: Nullable<number>): void {
      if (weeks != 0 && !weeks) {
        this.$set(this.value, `${type}EndAt`, null);
        return;
      }

      if (!this.value[`${type}StartAt` as keyof Nullable<String>]) {
        this.$set(this.value, `${type}Duration`, null);
        this.$set(this.value, `${type}EndAt`, null);
        return;
      }

      if (
        this.value[`${type}StartAt` as keyof Nullable<String>] &&
        !this.value[`${type}Duration` as keyof Nullable<String>]
      ) {
        this.$set(this.value, `${type}Duration`, weeks);
      }

      if (this.value[`${type}StartAt` as keyof Nullable<String>]) {
        const startAtDate: string = this.value[
          `${type}StartAt` as keyof Nullable<String>
        ];
        const duration: string = this.value[
          `${type}Duration` as keyof Nullable<String>
        ];
        this.$set(
          this.value,
          `${type}EndAt`,
          moment(startAtDate)
            .add(duration || 0, "weeks")
            .subtract(1, "day")
            .format("YYYY-MM-DD")
        );
      }
    }
  }
});
