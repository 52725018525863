import Vue, { PropType } from 'vue';

import { VIcon, VChip, VMenu } from 'vuetify/lib';

type RouteFactory<T> = (item: T) => { value?: string[] };

export default <T = any>(factory: RouteFactory<T>, options?: Record<any, any>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    data() {
      const props = factory(this.item);

      return {
        options,
        value: props.value,
      };
    },

    components: {
      VIcon,
      VChip,
      VMenu,
    },

    template: `
    <span>
    <v-chip :color="options.color" :label="options.label" v-if="value.length === 1" small>
      <span v-text="value[0].name"> </span>
    </v-chip>
    <v-menu max-width="300" v-else-if="value.length > 0" open-on-hover right open-on-click>
      <template v-slot:activator="{ on }">
            <v-chip :color="options.color" :label="options.label" class="ml-1" small v-on="on">
              {{ value[0].name }}
              <v-chip class="ml-1 px-2 font-weight-bold" x-small outlined>
                +{{ value.length - 1 }}
              </v-chip>
            </v-chip>
      </template > 
      <div class="pa-3" style="background: white;">
          <template v-for="entry in value">
            <v-chip small :color="options.color" :label="options.label" class="ma-1" :key="entry.id">{{ entry.name }}</v-chip>
          </template>
        </div>
      </v-menu>
    </span>
    `,
  });
