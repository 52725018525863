import Router from 'vue-router';
import { Store } from 'vuex/types';

export default (router: Router, store: Store<any>) =>
  router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page

    if (to.path === '/uitloggen') {
      await store.dispatch('account/signOut');
      return next({ path: '/inloggen' });
    }

    // const { authorize }: { authorize?: boolean | string[] } = to.meta;
    const authorize: boolean | string[] | undefined = to.meta?.authorize;
    const isAuthenticated: boolean = store.getters['account/isAuthenticated'];

    if (!authorize) {
      return next();
    }

    if (!isAuthenticated) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/inloggen', query: { returnUrl: to.path } });
    }

    if (authorize instanceof Array) {
      const roles: string[] = store.getters['profile/permissions'];

      // check if route is restricted by role
      if (authorize.length && !authorize.some(role => roles.includes(role))) {
        // role not authorised so redirect to home page
        return next({ path: '/' });
      }
    }

    next();
  });
