




























































































import Vue, { PropType } from "vue";
import { Project } from "@/types";

import _ from "underscore";

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: Object as PropType<Project>
    },
    rules: {
      type: Object,
      default: () => []
    },
    autofocus: {
      type: String,
      default: ""
    }
  }
});
