









































import Vue, { PropType } from 'vue';
import { ExportBatch, Nullable, Progress } from '@/types';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  props: {
    value: Object as PropType<ExportBatch>,
    doesSendZip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      progress: null as Nullable<Progress>,
      timeout: null as Nullable<number>,
    };
  },

  mounted() {
    this.progress = {
      processedJobs: this.value.processedJobs,
      failedJobs: this.value.failedJobs,
      progress: this.value.progress,
      pendingJobs: this.value.pendingJobs,
      totalJobs: this.value.totalJobs,
      finishedAt: this.value.finishedAt,
    };

    this.checkProgress();
  },

  destroyed() {
    this.clear();
  },

  computed: {
    zipFileMessage(): TranslateResult {
      return this.$t('office.dialogs.batchProgress.zipFileMessage');
    },
    progressText(): string {
      return this.$t('office.dialogs.houses.updateExpiredContracts.steps[3].progress', [
        this.remainingJobs,
        this.progress?.totalJobs || 0,
      ]).toString();
    },
    succesMessage(): TranslateResult {
      return this.$t('office.dialogs.batchProgress.succes', [
        `<span class="font-weight-bold">${this.progress?.processedJobs || 0}</span>`,
      ]);
    },
    failedMessage(): TranslateResult {
      return this.$t('office.dialogs.batchProgress.failed', [
        `<span class="font-weight-bold">${this.progress?.failedJobs || 0}</span>`,
      ]);
    },
    remainingMessage(): TranslateResult {
      return this.$t('office.dialogs.batchProgress.pending', [
        `<span class="font-weight-bold">${this.progress?.totalJobs! -
          this.succesfullJobs -
          this.failedJobs || 0}</span>`,
      ]);
    },
    loading(): boolean {
      return !this.progress?.finishedAt;
    },
    succesfullJobs(): number {
      return this.progress?.processedJobs || 0;
    },
    failedJobs(): number {
      return this.progress?.failedJobs || 0;
    },
    remainingJobs(): number | null {
      return this.succesfullJobs + this.failedJobs || 0;
    },
    processedJobsPercentage(): number | null {
      return (this.progress?.processedJobs! * 100) / this.progress?.totalJobs! || null;
    },
    failedJobsPercentage(): number | null {
      return (this.progress?.failedJobs! * 100) / this.progress?.totalJobs! || null;
    },
    progressRemainingPercentage(): number {
      return this.progress?.finishedAt
        ? 0
        : 100 - (this.failedJobsPercentage! + this.progress?.progress! || 0);
    },
    progressFinishedPercentage(): number {
      if (this.progress?.finishedAt) {
        return 100;
      } else if (this.progress?.failedJobs! > 0) {
        return Math.round(this.failedJobsPercentage! + this.progress?.progress!) || 0;
      } else {
        return this.progress?.progress || 0;
      }
    },
  },
  methods: {
    async checkProgress() {
      this.clear();
      const batch: ExportBatch = await this.$store.dispatch('batches/fetch', this.value.id);

      this.progress = {
        progress: batch.progress,
        failedJobs: batch.failedJobs,
        processedJobs: batch.processedJobs,
        pendingJobs: batch.pendingJobs,
        totalJobs: batch.totalJobs,
        finishedAt: batch.finishedAt,
      };

      this.$emit('input', batch);

      if (!batch.finishedAt) {
        this.timeout = window.setTimeout(() => this.checkProgress(), 2000);
      }
    },

    clear() {
      if (!this.timeout) return;

      window.clearTimeout(this.timeout);
      this.timeout = null;
    },
  },
});
