import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import ResourceTable from '@/apps/office/containers/resource-table';
import DataTable from '@/components/table';
import { House, Project, ActionDialogAttributes } from '@/types';

type FormData = {
  selectedHouses: House[];
  selectedProjects: Project[];
  project: Project;
};

export default WithSteps<FormData, ActionDialogAttributes<House>>({
  title: 'office.dialogs.houses.transferHouses.title',

  formData() {
    const project: Project = this.$store.getters['projects/details'](
      this.$store.state.route.params.id,
    );

    return {
      selectedHouses: this.$attrs.items.slice(),
      selectedProjects: [],
      project,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedHouses.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['pchn', 'address', 'city', 'tenantName', 'contractState', 'panel', 'inverter'],
          settings: 'houses/settings',
          itemKey: 'pchn',
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },
    },
    {
      component: ResourceTable,

      labels: {
        subtitle: 'office.dialogs.houses.transferHouses.steps[1].subtitle',
      },

      props() {
        return {
          columns: [
            'region',
            'code',
            'type',
            'status',
            'installationType',
            'buildingType',
            'projectManagerName',
            'year',
            'installerName',
          ],

          isSelectable: (item: Project) => item.id !== this.$data.project.id,

          itemClass: (item: Project) =>
            item.id === this.$data.project.id ? 'v-data-row--unavailable' : '',

          resource: 'projects/regular',
          settings: 'projects/regular/settings',
          showSelect: true,
          singleSelect: true,
          params: {
            status: [0, 1],
            housingCooperativeId: this.$data.project?.housingCooperativeId!,
          },
        };
      },

      isValid() {
        return this.$data.selectedProjects.length === 1;
      },

      value: {
        get() {
          return this.$data.selectedProjects;
        },

        set(items: Project[]) {
          this.$data.selectedProjects = items;
        },
      },
    },
    {
      component: DataTable,

      props() {
        const project: Project = this.$data.selectedProjects[0];

        return {
          items: this.$data.selectedHouses.map(house => ({
            pchn: house.pchn,
            oldCode: this.$data.project?.code,
            newCode: project?.code,
            region: project?.region,
            status: project?.status,
            projectManagerName: project?.projectManagerName,
            installerName: project?.installerName,
            year: project?.year,
          })),

          itemKey: 'pchn',

          headers: [
            ...this.$store.getters['houses/settings/columns:selection'](['pchn']),
            ...this.$store.getters['projects/regularHouses/settings/columns:selection']([
              'region',
              'year',
              'status',
              'projectManagerName',
              'installerName',
            ]),
            { value: 'oldCode', text: this.$t(`office.tables.headers.projectCodeOld`) },
            { value: 'newCode', text: this.$t(`office.tables.headers.projectCodeNew`) },
          ],

          renderers: this.$store.getters['projects/regularHouses/settings/renderers'],
        };
      },

      labels: {
        action() {
          return this.$t('office.dialogs.houses.transferHouses.action', [
            this.$data.selectedHouses.length,
          ]).toString();
        },

        subtitle: 'office.dialogs.houses.transferHouses.steps[2].subtitle',
      },

      async afterStep() {
        await this.$store.dispatch('projects/regularHouses/bulk:transferHouses', {
          houses: this.$data.selectedHouses,
          fromProject: this.$data.project,
          toProject: this.$data.selectedProjects[0],
        });

        const proposition = this.$store.state.route.name;

        this.$store.dispatch(`projects/${proposition}/settings/search`);
      },
    },
  ],
});
