





































import Vue, { PropType } from 'vue';
import moment from 'moment';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
    allowedDates: Function as PropType<() => boolean>,
    rules: {
      type: Array,
      default: () => [],
    },
    showWeek: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Boolean],
      default: true,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    prependIcon(): string | undefined {
      if (this.icon === true) return 'mdi-calendar';
      if (this.icon === false) return undefined;

      return this.icon;
    },

    dateFormatted(): string {
      return this.value ? moment(this.value).format('DD-MM-YYYY') : '';
    },

    dateHint(): string {
      return this.value ? moment(this.value).format('dddd, DD MMMM YYYY') : '';
    },
  },
});
