import { ProjectConversion } from '@/types';
import { FunctionalComponentOptions } from 'vue/types';

type Props = {
  item: { conversionValues: ProjectConversion[] };
};

export default (status: number): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    const item = props.item.conversionValues.find(conversion => conversion.forStatus === status);

    return createElement('span', item?.duration?.toString() ?? '');
  },
});
