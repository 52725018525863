
































import moment from 'moment';
import Vue from 'vue';
import _ from 'underscore';

export default Vue.extend({
  props: {
    filters: {
      type: Object as () => Record<string, any>,
    },

    params: {
      type: Object as () => Record<string, any>,
    },

    readonly: {
      type: Object as () => Record<string, true>,
    },

    limit: {
      type: Object as () => Record<string, true>,
    },

    setParam: {
      type: Function,
    },

    clearParam: {
      type: Function,
    },
  },

  computed: {
    years(): any[] {
      return new Array(100).fill(null).map((d, i) => 2000 + i);
    },

    installers(): unknown[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, 'name');
    },
  },
});
