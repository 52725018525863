import Vue, { PropType } from 'vue';
import { VNode } from 'vue/types/umd';

type RouteFactory = (item: any) => { url: string | undefined; title: string | undefined };

export default (factory: RouteFactory) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    computed: {
      url(): string | undefined {
        return factory(this.item)?.url;
      },
      title(): string | undefined {
        return factory(this.item)?.title;
      },
    },

    render(h): VNode {
      const link = h(
        'a',
        {
          attrs: { href: this.url, target: '_blank' },
        },
        this.title,
      );
      return link;
    },
  });
