import { RouteConfig, Route } from 'vue-router';

import Table from '@/apps/office/layouts/table.vue';

import DialogExportAll from '@/apps/office/containers/table/projects/dialogs/houses/ExportAll';
import DialogExportSelection from '@/apps/office/containers/table/projects/dialogs/houses/ExportSelection';
import DialogAddHouse from '@/apps/office/containers/table/projects/dialogs/houses/Add';
import DialogRemoveHouse from '@/apps/office/containers/table/projects/dialogs/houses/Remove';
import DialogClearForInstallation from '@/apps/office/containers/table/houses/dialogs/ClearForInstallation';
import DialogCloseContractPhase from '@/apps/office/containers/table/houses/dialogs/CloseContractPhase';
import DialogUpdateExpiredContracts from '@/apps/office/containers/table/houses/dialogs/UpdateExpiredContracts';
import SendDocumentFiles from '@/apps/office/containers/table/houses/dialogs/SendDocumentFiles';
import DialogGenerateAndSendContracts from '@/apps/office/containers/table/houses/dialogs/GenerateAndSendContracts';
import DialogTransferHouses from '@/apps/office/containers/table/projects/dialogs/houses/TransferToOtherProject';
import DialogGenerateProjectLaunch from '@/apps/office/containers/table/houses/dialogs/GenerateProjectLaunch';
import DialogDeleteContracts from '@/apps/office/containers/table/houses/dialogs/deleteContracts';

import Filters from '@/apps/office/containers/table/houses/Filters.vue';
import Navigation from '@/apps/office/pages/projects/_id/containers/Navigation';
import propositions from '@/apps/office/static/project-propositions.json';

import {
  TableViewProps,
  AuthorizedRoute,
  Breadcrumb,
  Project,
  TableAction,
  Proposition,
} from '@/types';

import filters from '@/apps/office/components/table/shared/items/filters';
import { Prop } from 'vue/types/options';
import { filter } from 'vue/types/umd';

const actions: TableAction[] = [
  {
    name: 'exportAll',
    component: DialogExportAll,
    icon: 'mdi-file-export-outline',
    label: 'office.dialogs.toolbar.export',
  },
  {
    name: 'exportSelection',
    component: DialogExportSelection,
    requiresSelection: true,
    icon: 'mdi-table-arrow-right',
    label: 'office.dialogs.toolbar.exportSelection',
  },
];

const tableViewProps = (props: TableViewProps) => (route: Route): TableViewProps => ({
  relationId: route.params.id,
  dictionaries: `houses/other/dictionaries`,
  filters: Filters,
  ...props,
  scope: { ...props.scope, query: route.query, filters },
});

const tableMeta = (path: AuthorizedRoute[] = []): any => ({
  authorize: ['CanViewProjects'],
  hasFilters: true,
  hasSideNav: true,
  breadcrumb: breadcrumb(path),
});

function breadcrumb(path: AuthorizedRoute[] = []): Breadcrumb {
  return (route, store) => {
    const project: Project = store.getters['projects/details'](route.params.id);
    return [
      {
        to: '/projecten',
        title: 'office.navigation.root.projects',
        icon: 'fas fa-suitcase',
        permission: 'CanViewInstallations',
      },
      {
        to: project
          ? `/projecten/${propositions[project.installationType].project_name}`
          : 'office.navigation.projects.allprojects',
        title: project
          ? `office.navigation.projects.${propositions[project.installationType].project_name}`
          : 'office.navigation.projects.allprojects',
      },
      {
        to: `/projecten/${route.params.id}`,
        custom: true,
        title: project
          ? `${project?.region} (${project?.housingCooperativeName})`
          : route.params.id,
        clipboard: project?.code,
      },
      ...path.map(p => ({ ...p, clipboard: p.clipboard ?? project?.code })),
    ];
  };
}

export default [
  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/huidig`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.houses' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        actions,
        scope: {
          exclude: [filters.PREVIOUS_DAYS_CONTRACT_SENT],
        },
      }),
    },
  })),
  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/verwijderen`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.removeHouses' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.PREVIOUS_DAYS_CONTRACT_SENT],
        },
        actions: [
          {
            name: 'remove',
            component: DialogRemoveHouse,
            permission: 'CanEditHouses',
            requiresSelection: true,
            icon: 'mdi-minus-box-multiple',
            label: 'office.dialogs.toolbar.remove',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/toevoegen`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.addHouses' }]),
    props: {
      default: tableViewProps({
        resource: 'houses',
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.PREVIOUS_DAYS_CONTRACT_SENT],
        },
        actions: [
          {
            name: 'addMany',
            component: DialogAddHouse,
            permission: 'CanEditHouses',
            icon: 'mdi-plus-box-multiple',
            requiresSelection: true,
            label: 'office.dialogs.toolbar.addMany',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/GenereerProjectlancering`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.generateProjectLaunch' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [
            filters.CLEARED_FOR_INSTALLATION,
            filters.CONTRACT_STATES,
            filters.PREVIOUS_DAYS_CONTRACT_SENT,
          ],
          limit: {
            [filters.PROJECT_STATES]: ['P200', 'P210', 'P220'],
          },
          values: {
            [filters.PROJECT_STATES]: ['P200', 'P210', 'P220'],
          },
        },
        actions: [
          {
            name: 'generateProjectLaunch',
            icon: 'mdi-rocket-launch-outline',
            label: 'office.dialogs.houses.generateProjectLaunch.title',
            requiresSelection: true,
            component: DialogGenerateProjectLaunch,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/genereerEnVerstuurContracten`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.generateAndSendContracts' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.CLEARED_FOR_INSTALLATION, filters.PREVIOUS_DAYS_CONTRACT_SENT],
          readonly: {
            [filters.PROJECT_STATES]: ['P220'],
            [filters.CONTRACT_STATES]: ['P3010'],
          },
        },
        actions: [
          {
            name: 'generateNewContracts',
            icon: 'mdi-text-box-plus-outline',
            label: 'office.dialogs.houses.generateAndSendContracts.title',
            requiresSelection: true,
            component: DialogGenerateAndSendContracts,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/vrijgevenVoorInstallatie`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.clearForInstallation' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.CLEARED_FOR_INSTALLATION, filters.PREVIOUS_DAYS_CONTRACT_SENT],
          limit: {
            [filters.PROJECT_STATES]:
              item.project_name != 'monitoringonly' ? ['P221', 'P222'] : ['M200'],
          },
          values: {
            [filters.PROJECT_STATES]:
              item.project_name != 'monitoringonly' ? ['P221', 'P222'] : ['M200'],
          },
          readonly: {
            [filters.CLEARED_FOR_INSTALLATION]: '0',
          },
        },
        actions: [
          {
            name: 'clearForInstallation',
            icon: 'mdi-home-assistant',
            label: 'office.dialogs.houses.clearForInstallation.title',
            requiresSelection: true,
            component: DialogClearForInstallation,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/afsluitenContractFase`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.closeContractPhase' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [
            filters.CLEARED_FOR_INSTALLATION,
            filters.PROJECT_STATES,
            filters.PREVIOUS_DAYS_CONTRACT_SENT,
          ],
          limit: {
            [filters.CONTRACT_STATES]: ['P3012', 'P3020', 'P3021', 'P3022', 'P3023'],
          },
          values: {
            [filters.CONTRACT_STATES]: ['P3012', 'P3020', 'P3021', 'P3022', 'P3023'],
          },
          readonly: {
            [filters.PROJECT_STATES]: ['P220'],
          },
        },
        actions: [
          {
            name: 'closeContractPhase',
            icon: 'mdi-text-box-check-outline',
            label: 'office.dialogs.houses.closeContractPhase.title',
            requiresSelection: true,
            component: DialogCloseContractPhase,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/genereerNieuweContracten`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.updateExpiredContracts' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [
            filters.CLEARED_FOR_INSTALLATION,
            filters.CONTRACT_STATES,
            filters.PREVIOUS_DAYS_CONTRACT_SENT,
          ],
          limit: {
            [filters.PROJECT_STATES]: ['P297', 'P298'],
          },
          values: {
            [filters.PROJECT_STATES]: ['P297', 'P298'],
          },
        },
        actions: [
          {
            name: 'generateNewContracts',
            icon: 'mdi-text-box-multiple-outline',
            label: 'office.dialogs.houses.updateExpiredContracts.title',
            requiresSelection: true,
            component: DialogUpdateExpiredContracts,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/woningenOverzetten`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.transferHouses' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.PREVIOUS_DAYS_CONTRACT_SENT],
          limit: {
            [filters.PROJECT_STATES]: ['P221', 'P222', 'P230', 'P240'],
          },
          values: {
            [filters.PROJECT_STATES]: ['P221', 'P222', 'P230', 'P240'],
          },
        },
        actions: [
          {
            name: 'transferHouses',
            icon: 'mdi-home-export-outline',
            label: 'office.dialogs.houses.transferHouses.title',
            requiresSelection: true,
            component: DialogTransferHouses,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/deleteContracts`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.deleteContracts' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.CLEARED_FOR_INSTALLATION],
          limit: {
            [filters.CONTRACT_STATES]: ['P3012', 'P3020', 'P3021', 'P3022', 'P3023'],
          },
          values: {
            [filters.CONTRACT_STATES]: ['P3012', 'P3020', 'P3021', 'P3022', 'P3023'],
            [filters.PREVIOUS_DAYS_CONTRACT_SENT]: 7,
          },
          readonly: {
            [filters.PROJECT_STATES]: ['P220'],
          },
        },
        actions: [
          {
            name: 'deleteContracts',
            icon: 'mdi-text-box-remove-outline',
            label: 'office.dialogs.houses.deleteContracts.title',
            requiresSelection: true,
            component: DialogDeleteContracts,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),

  ...propositions.map((item: Proposition) => ({
    name: item.project_house_name,
    path: `projecten/:id/${item.project_name}/woningen/verstuurDocumentType`,
    components: { default: Table, navigation: Navigation },
    meta: tableMeta([{ title: 'office.navigation.projects.houses.sendDocumentFiles' }]),
    props: {
      default: tableViewProps({
        resource: `projects/${item.project_house_name}`,
        settings: `projects/${item.project_house_name}/settings`,
        scope: {
          exclude: [filters.PREVIOUS_DAYS_CONTRACT_SENT],
        },
        actions: [
          {
            name: 'sendDocumentFiles',
            icon: 'mdi-file-download-outline',
            label: 'office.dialogs.houses.sendDocumentFiles.title',
            requiresSelection: true,
            component: SendDocumentFiles,
            permission: 'CanEditHouses',
          },
          ...actions,
        ],
      }),
    },
  })),
] as RouteConfig[];
