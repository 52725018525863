import { AsyncModule } from "vuex-async-mutations";
import { UserProfile } from "@/types";

interface ProfileState {
  user: Partial<UserProfile>;
}

const stateFactory = () => ({
  user: {
    email: null,
    name: null,
    roles: []
  }
});

const permissions: Record<string, string[]> = {
  ["administrator"]: ["CanViewProjects", "CanViewInstallations"],
  ["superuser"]: [
    "CanViewProjects",
    "CanEditProjects",
    "CanEditHouses",
    "CanViewInstallations",
    "CanEditInstallations"
  ],
  ["project_manager"]: [
    "CanViewProjects",
    "CanEditProjects",
    "CanEditHouses",
    "CanViewInstallations"
  ],
  ["zonmaat_office"]: [
    "CanViewProjects",
    "CanEditProjects",
    "CanViewInstallations"
  ]
};

export const module: AsyncModule<ProfileState, any> = {
  namespaced: true,

  async: true,

  state: stateFactory,

  getters: {
    ["roles"](state) {
      return state.user.roles?.map(role => role.name) ?? [];
    },

    ["permissions"](state, { roles }: { roles: string[] }) {
      return roles.flatMap(role => permissions[role] ?? []);
    }
  },

  /* eslint-disable no-param-reassign */
  mutations: {
    ["reset"]: state => {
      Object.assign(state, stateFactory());
    }
  },
  /* eslint-disable no-param-reassign */

  actionsAsync: {
    ["fetch:profile"]: {
      handler({ commitAsync }) {
        return commitAsync(this.$axios.get("/users/profile"));
      },

      resolved(state, { user }: { user: UserProfile }) {
        state.user = user;
      }
    }
  },

  actions: {
    // "events"

    ["reset"]: {
      root: true,
      handler: ({ commit }) => commit("reset")
    },

    ["authenticated"]: {
      root: true,
      handler: ({ dispatch }) => dispatch("fetch:profile")
    }
  }
};

export default module;
