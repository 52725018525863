import { AuthorizedRoute, Proposition } from '@/types';
import { Route, RouteConfig } from 'vue-router';

import Tabs from '@/apps/office/layouts/tabs.vue';

import deliveryDocuments from './routes.installations.delivery-documents';

import Filters from '@/apps/office/containers/table/installations/Filters.vue';

import DialogExportAll from '@/apps/office/containers/table/installations/dialogs/ExportAll';
import DialogExportSelection from '@/apps/office/containers/table/installations/dialogs/ExportSelection';

import InstallersNavigation from '@/apps/office/pages/installations/containers/Navigation';

import filters from '@/apps/office/components/table/shared/items/filters';

import propositions from '@/apps/office/static/project-propositions.json';

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: '/installaties',
      title: 'office.navigation.root.installations',
      icon: 'mdi-home',
      permission: 'CanViewInstallations',
    },
    ...path,
  ];
}

const sharedActions = [
  {
    name: 'exportAll',
    component: DialogExportAll,
    requiresSelection: false,
    icon: 'mdi-file-export-outline',
    label: 'office.dialogs.toolbar.export',
  },
  {
    name: 'exportSelection',
    component: DialogExportSelection,
    requiresSelection: true,
    icon: 'mdi-table-arrow-right',
    label: 'office.dialogs.toolbar.exportSelection',
  },
];

export default [
  {
    path: '/installaties',
    redirect: '/installaties/regular',
  },
  {
    name: 'withoutprojects',
    path: '/installaties/withoutprojects',
    components: { default: Tabs, navigation: InstallersNavigation },
    meta: {
      authorize: ['CanViewInstallations'],
      hasFilters: true,
      hasSideNav: true,
      breadcrumb: breadcrumb([{ title: 'office.navigation.installations.withoutprojects' }]),
    },
    props: {
      default: (route: Route) => ({
        resource: 'installations/withoutprojects',
        settings: 'installations/withoutprojects/settings',
        dictionaries: 'installations/withoutprojects/dictionaries',
        filters: Filters,
        actions: sharedActions,
        scope: {
          query: route.query,
          filters,
        },
      }),
    },
  },
  ...propositions.map((item: Proposition, i: number) => ({
    name: item.installation_name,
    path: `/installaties/${item.project_name}`,
    components: { default: Tabs, navigation: InstallersNavigation },
    meta: {
      authorize: ['CanViewInstallations'],
      hasFilters: true,
      hasSideNav: true,
      breadcrumb: breadcrumb([{ title: `office.navigation.installations.${item.project_name}` }]),
    },
    props: {
      default: (route: Route) => ({
        resource: `installations/${item.installation_name}`,
        settings: `installations/${item.installation_name}/settings`,
        dictionaries: `installations/${item.installation_name}/dictionaries`,
        filters: Filters,
        actions: sharedActions,
        scope: {
          query: route.query,
          filters,
          limit: {
            [filters.PROJECT_STATES]:
              item.project_name != 'monitoringonly'
                ? [
                    'P200',
                    'P210',
                    'P220',
                    'P221',
                    'P222',
                    'P230',
                    'P240',
                    'P250',
                    'P251',
                    'P297',
                    'P298',
                    'P299',
                  ]
                : ['M200', 'M221', 'M230', 'M250', 'M299'],
            [filters.INSTALLATION_TYPE]: [i],
          },
          readonly: {
            [filters.INSTALLATION_TYPE]: i,
          },
          values: {
            [filters.INSTALLATION_TYPE]: i,
          },
        },
      }),
    },
  })),
  ...deliveryDocuments,
] as RouteConfig[];
