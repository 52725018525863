import Vue, { PropType } from "vue";

import { Nullable, Project } from "@/types";
import {
  getTotalHoursEmployee,
  getDefaultAverageHoursEmployee,
  EmployeeTypes
} from "@/components/average-hours-input/helpers";

type RouteFactory<T> = (
  item: T
) => {
  project: Project;
  norm: Nullable<number> | undefined;
  employee: EmployeeTypes;
};

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      project(): Project {
        return factory(this.item).project;
      },
      employee(): EmployeeTypes {
        return factory(this.item).employee;
      },
      norm(): number | null | undefined {
        return factory(this.item).norm;
      },
      averageHours(): number {
        if (this.norm != null || this.norm != undefined) {
          return this.norm;
        } else {
          return getDefaultAverageHoursEmployee(this.project, this.employee);
        }
      },
      totalHours(): number {
        return getTotalHoursEmployee(
          this.project,
          this.employee,
          this.averageHours
        );
      }
    },

    template: `
          <span>{{ totalHours }} {{!norm ?  this.$t("office.labels.fields.defaultValue") : ''}}</span>
        `
  });
