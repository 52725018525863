
















































































































































































import { BlockHouse, House, HouseSize, MeterStatus } from '@/types';
import Vue, { PropType } from 'vue';

import statusChips from './dialogs/StatusChips.vue';

export default Vue.extend({
  components: {
    statusChips,
  },
  props: {
    house: Object as PropType<BlockHouse>,
    powershareNames: Array as PropType<string[]>,
    meterStatuses: Array as PropType<MeterStatus[]>,
    allHouseDetails: Array as PropType<House[]>,
    loading: Boolean,
    editMode: Boolean,
    size: String as PropType<HouseSize>,
  },

  data() {
    return {
      blockHouseOverviewDialog: false,
      colors: [
        '#66BB6A',
        '#29B6F6',
        '#D4E157',
        '#FF7043',
        '#FFCA28',
        '#EC407A',
        '#1B5E20',
        '#01579B',
        '#827717',
        '#BF360C',
        '#FF6F00',
        '#880E4F',
        '#00C853',
        '#0091EA',
        '#AEEA00',
        '#DD2C00',
        '#FFAB00',
        '#C51162',
        '#C8E6C9',
        '#B3E5FC',
        '#F0F4C3',
        '#FFCCBC',
        '#FFECB3',
        '#F8BBD0',
        '#AED581',
        '#DCE775',
        '#4DB6AC',
        'transparent',
      ],
    };
  },

  methods: {
    toggleDialog() {
      this.blockHouseOverviewDialog = !this.blockHouseOverviewDialog;
    },
  },

  computed: {
    width(): number {
      switch (this.size) {
        case 'S':
          return 125;
        case 'M':
          return 150;
        case 'L':
          return 200;
        default:
          return 150;
      }
    },

    contractState(): string | null {
      return this.houseDetails?.contractState;
    },

    projectState(): string | null {
      return this.houseDetails?.projectState;
    },

    meterStatus(): string {
      return this.houseStatus?.[0]?.meterStatus ?? 'Geen data';
    },

    breakerStatus(): string {
      return this.houseStatus?.[0]?.breakerStatus ?? 'Geen data';
    },

    houseStatus(): MeterStatus[] | undefined {
      return this.meterStatuses?.filter(house => house.pchn === this.house.pchn);
    },

    meterStatusDetails(): MeterStatus[] {
      const housesArray = this.powershareDetails.flatMap(house => house.pchn);
      return this.meterStatuses?.filter(house => housesArray.includes(house.pchn));
    },

    houseDetails(): House {
      return this.$store.getters['projects/regularHouses/details'](this.house.pchn);
    },

    powershareNameIndex(): number {
      return this.powershareNames.indexOf(this.houseDetails?.powershareName);
    },

    powershareDetails(): House[] {
      return this.allHouseDetails.filter(
        house => house.powershareName === this.powershareNames[this.powershareNameIndex],
      );
    },

    powerShareStringName(): string {
      return this.powershareDetails[0].powershareName;
    },

    powershareColor(): string {
      return this.colors[this.powershareNameIndex];
    },

    powershareColorClass(): string {
      return `border-color-${this.powershareNameIndex}`;
    },

    meterStatusColor(): boolean {
      return this.meterStatus === 'Registered';
    },

    breakerStatusColor(): boolean {
      return this.breakerStatus === 'On';
    },
  },
});
