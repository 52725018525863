

















































import Vue, { PropType } from "vue";
import { Project } from "@/types";
import InputNumber from "@/components/inputs/InputNumber.vue";
import {
  EmployeeTypes,
  getAmountInstallations,
  getDefaultAverageHoursEmployee,
  getTotalHoursEmployee
} from "./helpers";

export default Vue.extend({
  components: {
    InputNumber
  },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    employee: {
      type: String as PropType<EmployeeTypes>,
      required: true
    }
  },

  data() {
    return {
      expectedInstallations: 0
    };
  },

  created() {
    this.expectedInstallations = this.value?.expectedInstallations || 0;
  },

  watch: {
    value: {
      handler(val: Project) {
        if (this.value?.expectedInstallations) {
          return;
        } else {
          this.expectedInstallations = Math.round(
            ((val?.expectedHouses || 0) / 100) * (this.value?.conversion || 100)
          );
        }
      },
      deep: true
    }
  },

  computed: {
    averageHours: {
      get(): number {
        let val;

        if (this.employee == EmployeeTypes.PvMechanic) {
          val = this.value.zmAverageHoursPvMechanic;
        }

        if (this.employee == EmployeeTypes.Electrician) {
          val = this.value.zmAverageHoursElectrician;
        }

        if (val !== null && val !== undefined) {
          return val;
        }

        return getDefaultAverageHoursEmployee(this.value, this.employee) || 0;
      },

      set(val: number) {
        if (isNaN(val) || val < 0) val = 0;

        const numVal = parseFloat(val.toFixed(2));

        if (this.employee == EmployeeTypes.PvMechanic) {
          this.$set(this.value, `zmAverageHoursPvMechanic`, numVal);
        }

        if (this.employee == EmployeeTypes.Electrician) {
          this.$set(this.value, `zmAverageHoursElectrician`, numVal);
        }
      }
    },
    isDefaultValue(): boolean {
      if (
        this.employee == EmployeeTypes.PvMechanic &&
        (this.value.zmAverageHoursPvMechanic != null ||
          this.value.zmAverageHoursPvMechanic != undefined)
      ) {
        return false;
      }

      if (
        this.employee == EmployeeTypes.Electrician &&
        (this.value.zmAverageHoursElectrician != null ||
          this.value.zmAverageHoursElectrician != undefined)
      ) {
        return false;
      }
      return true;
    },
    installations() {
      return getAmountInstallations(this.value);
    },
    totalHours(): number {
      return getTotalHoursEmployee(
        this.value,
        this.employee,
        this.averageHours
      );
    }
  },

  methods: {
    preventMinusKey(event: KeyboardEvent) {
      if (event.key === "-" || event.code === "Minus") {
        event.preventDefault();
      }
    }
  }
});
