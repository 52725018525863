import Vue from 'vue';
import { InverterConfiguration, Panel } from '@/types';
export default (type: 'panelTranslation' | 'inverterConfigurationTranslation') =>
  Vue.extend({
    props: {
      value: Number,
    },
    render(createElement) {
      let text = '';

      if (type === 'panelTranslation') {
        const panels: Panel[] = this.$store.state.dictionaries.panels.items;
        text =
          panels?.filter((item: Panel) => item.id === this.value)[0]?.model ??
          panels?.filter((item: Panel) => item.default)[0]?.model;
      }

      if (type === 'inverterConfigurationTranslation') {
        const inverterConfigurations: InverterConfiguration[] = this.$store.state.dictionaries
          .inverterConfigurations.items;
        text =
          inverterConfigurations?.filter((item: InverterConfiguration) => item.id === this.value)[0]
            ?.name ??
          inverterConfigurations?.filter((item: InverterConfiguration) => item.default)[0]?.name;
      }

      return createElement('span', text);
    },
  });
