


























import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
    },

    positive: {
      type: Boolean,
      default: true,
    },

    digits: {
      type: Number,
      default: 0,
    },

    rules: {
      type: Array,
      default: undefined,
    },

    saveOnBlur: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasFocus: false,
      input: 0,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val: number) {
        this.input = val;
      },
    },

    input() {
      if (!this.saveOnBlur) {
        this.save();
      }
    },
  },

  methods: {
    onBlur() {
      this.save();
      this.hasFocus = false;
    },

    save() {
      if (this.input === this.value) return;

      if (this.positive) this.$emit('input', Math.abs(this.input));
      else this.$emit('input', this.input);
    },
  },

  computed: {
    formattedValue(): string {
      return (this.value / 100).toLocaleString('nl-NL', {
        style: 'percent',
        minimumFractionDigits: this.digits,
        maximumFractionDigits: this.digits,
      });
    },
  },
});
