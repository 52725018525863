














































import Vue, { PropType } from 'vue';
import moment from 'moment';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
    allowedDates: Function as PropType<() => boolean>,
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      show: false,
      view: 'date' as 'date' | 'time',
    };
  },

  computed: {
    dateFormatted(): string {
      return this.value ? moment(this.value).format('DD/MM/YYYY @ H:mm') : '';
    },

    dateHint(): string {
      return this.value ? moment(this.value).format('dddd, DD MMMM YYYY @ H:mm') : '';
    },

    date: {
      get(): string {
        return this.value?.split(' ')[0] ?? '';
      },

      set(val: string) {
        this.$emit('input', `${val} ${this.time}`);
      },
    },

    time: {
      get(): string {
        return this.value?.split(' ')[1] ?? '0:00';
      },

      set(val: string) {
        this.$emit('input', `${this.date} ${val}`);
      },
    },
  },

  methods: {
    commit() {
      if (this.view === 'date') {
        this.view = 'time';
      } else {
        this.show = false;
      }
    },
  },
});
