import { Project } from "@/types";

enum EmployeeTypes {
  PvMechanic = "pv-mechanic",
  Electrician = "electrician"
}

function getDefaultAverageHoursEmployee(
  project: Project,
  employee: EmployeeTypes
): number {
  if (project?.installationType === 2) {
    if (employee === EmployeeTypes.PvMechanic) {
      return 0;
    }
    if (employee === EmployeeTypes.Electrician) {
      return 1;
    }
  } else if (project?.buildingType === 0 || project?.buildingType === 2) {
    const panels = project.averageAmountPanels;

    if (employee === EmployeeTypes.PvMechanic) {
      if (panels >= 0 && panels <= 3) return 4;
      if (panels === 4) return 5;
      if (panels >= 5 && panels <= 6) return 6;
      if (panels >= 7 && panels <= 8) return 7;
      if (panels >= 9 && panels <= 10) return 8;
      if (panels >= 11 && panels <= 12) return 9;
      if (panels >= 13 && panels <= 14) return 10;
      if (panels >= 15 && panels <= 16) return 11;
      if (panels > 16) return 0;
    } else if (employee === EmployeeTypes.Electrician) {
      return 3.5;
    } else {
      return 0;
    }
  } else {
    if (employee === EmployeeTypes.PvMechanic) {
      return project?.averageAmountPanels || 0;
    }
    if (employee === EmployeeTypes.Electrician) {
      return 0;
    }
    return 0;
  }
  return 0;
}

function getTotalHoursEmployee(
  project: Project,
  employee: EmployeeTypes,
  averageHours: number
): number {
  return getAmountInstallations(project) * averageHours;
}

function getAmountInstallations(project: Project): number {
  return project?.status === 0
    ? getExpectedInstallations(project) || 0
    : project?.expectedWorkQueue + getWorkQueue(project);
}

function getExpectedInstallations(project: Project): number {
  return (
    project?.expectedInstallations ||
    Math.round(
      ((project?.expectedHouses || 0) / 100) * (project?.conversion || 100)
    )
  );
}

function getWorkQueue(project: Project): number {
  return workQueStates(project)?.reduce((total, currentState) => {
    const workToBeDone = project?.progress.find(
      item => item.state === currentState
    );
    return total + (workToBeDone?.count || 0);
  }, 0);
}

function workQueStates(project: Project): string[] {
  if (project?.installationType === 2) {
    return ["M221", "M230"];
  } else {
    return ["P221", "P222", "P230", "P240"];
  }
}

export {
  getDefaultAverageHoursEmployee,
  getTotalHoursEmployee,
  getAmountInstallations,
  EmployeeTypes
};
