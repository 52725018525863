









































import { BlockHouse, House, Nullable, Pagination } from '@/types';
import draggable from 'vuedraggable';
import Vue from 'vue';
import _ from 'underscore';

type DragMoveEvent = {
  draggedContext: {
    element: BlockHouse;
    futureIndex: number;
    index: number;
  };

  relatedContext: {
    element: BlockHouse;
    index: number;
    list: BlockHouse[];
  };
};

export default Vue.extend({
  components: {
    draggable,
  },

  props: {
    id: String,
  },

  data() {
    return {
      dragEvent: null as Nullable<DragMoveEvent>,
      pending: false,
      searchTerm: '',
      previous: '',
      houses: [] as BlockHouse[],
    };
  },

  computed: {
    isVisible: {
      get(): boolean {
        return !!this.$store.state.ui.showFilters;
      },

      set(visible: boolean) {
        this.$store.commit(`ui/set:showFilters`, !!visible);
      },
    },

    currentHouses(): BlockHouse[] {
      return this.$store.getters['projects/highrise/houses'](this.id);
    },

    findHouse() {
      return (id: number): BlockHouse | undefined =>
        this.currentHouses.find(house => house.id === id);
    },
  },

  mounted() {
    this.search();
  },

  methods: {
    onChange() {
      if (!this.dragEvent) return;

      const [source, target] = this.dragEvent!.relatedContext.list;

      if (!target || !source) return;

      const clone = this.$clone(target);

      target.column = source.column;
      target.row = source.row;
      this.dragEvent!.relatedContext.list.reverse();
      this.houses.push(target);
    },

    onInput(val: string) {
      if (val !== this.previous) {
        this.previous = val;
        this.search();
      }
    },

    onMoveCallback(evt: DragMoveEvent) {
      this.dragEvent = evt;
      if (!evt.relatedContext.list[0]?.id) return 1;

      return false;
    },

    async search() {
      this.pending = true;

      const page: Pagination<House> = await this.$store.dispatch(
        'projects/regularHouses/fetch:all:page',
        {
          relationId: this.id,
          perPage: 1000,
          query: this.searchTerm,
        },
      );

      this.pending = false;

      let houses = page.data.map(
        house =>
          this.findHouse(house.id) ?? {
            column: -1,
            row: -1,
            pchn: house.pchn,
            id: house.id,
            house,
          },
      );

      houses = _.sortBy(houses, 'pchn');

      const available = houses.filter(house => house.row < 0);
      const unavailable = houses.filter(house => house.row >= 0);

      this.houses = [...available, ...unavailable];
    },
  },
});
