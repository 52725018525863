






























































































import Vue, { PropType } from "vue";
import { AuthorizedRoute, Breadcrumb } from "@/types";

export default Vue.extend({
  props: {
    routes: {
      type: Array as PropType<AuthorizedRoute[]>,
      default: () => []
    }
  },

  computed: {
    showNavigation: {
      get(): boolean {
        return this.$store.state.ui.showNavigation;
      },

      set(val: boolean): void {
        this.$store.commit("ui/set:showNavigation", val);
      }
    },

    showFilters: {
      get(): boolean {
        return this.$store.state.ui.showFilters;
      },

      set(val: boolean) {
        this.$store.commit("ui/set:showFilters", val);
      }
    },

    activeFilters(): number {
      return this.$store.state.ui.activeFilters;
    },

    filterIcon(): string {
      return this.$store.state.route.meta.filterIcon ?? "mdi-filter-outline";
    },

    hasFilters(): boolean {
      return this.$store.state.route.meta.hasFilters;
    },

    hasSideNav(): boolean {
      return this.$store.state.route.meta.hasSideNav;
    },

    availableRoutes(): AuthorizedRoute[] {
      return this.routes.filter(
        route => !route.permission || this.$permission(route.permission)
      );
    },

    breadcrumb(): AuthorizedRoute[] {
      const breadcrumb: Breadcrumb = this.$store.state.route.meta.breadcrumb;

      if (!breadcrumb) return [];

      if (breadcrumb instanceof Array) return breadcrumb;

      return breadcrumb.apply(undefined, [
        this.$store.state.route,
        this.$store
      ]);
    },

    root(): AuthorizedRoute {
      return this.breadcrumb[0];
    },

    current(): AuthorizedRoute {
      return this.breadcrumb[this.breadcrumb.length - 1];
    },

    clipboard(): string | undefined {
      return this.current?.clipboard;
    }
  },

  methods: {
    showClipboardMessage() {
      this.$store.dispatch("ui/set:clipboard", {
        message: this.current?.clipboard,
        color: "info"
      });
    }
  }
});
