import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.ID',
    title: 'office.tables.headers.id',
    align: 'right',
    sortable: true,
    value: 'id',
    width: 60,
  },

  {
    text: 'office.tables.headers.pchn',
    title: 'office.tables.headers.pchn',
    align: 'left',
    sortable: true,
    value: 'pchn',
  },

  {
    text: 'office.tables.headers.address',
    align: 'left',
    sortable: true,
    value: 'address',
  },

  {
    text: 'office.tables.headers.city',
    align: 'left',
    sortable: true,
    value: 'city',
  },

  {
    text: 'office.tables.headers.tenant',
    align: 'left',
    sortable: true,
    value: 'tenantName',
  },

  {
    text: 'office.tables.headers.tags',
    align: 'left',
    sortable: false,
    value: 'tags',
  },

  {
    text: 'office.tables.headers.CRM',
    title: 'office.tables.headers.linkToCRM',
    sortable: false,
    value: 'crmUrl',
    width: 60,
  },

  {
    text: 'office.tables.headers.panel',
    sortable: false,
    value: 'panel',
  },

  {
    text: 'office.tables.headers.inverters',
    align: 'left',
    sortable: false,
    value: 'inverters',
  },

  {
    text: 'office.tables.headers.projectState',
    sortable: true,
    value: 'projectState',
  },
] as Column[];
