






























































































































type EditMode = 'add' | 'clone' | 'edit';

import Vue from 'vue';
import { TablePreset, Column, TableSettings, Nullable, ParamSettings, Project } from '@/types';
import TablePresets from '../table-presets/TablePresets.vue';

type item = {
  name: string;
  intervalValue: null | number;
};

export default Vue.extend({
  components: { TablePresets },
  props: {
    params: {
      type: Object as () => ParamSettings,
    },

    resource: {
      type: String,
      default: null,
    },

    dictionaries: {
      type: [String, Array],
    },

    settings: {
      type: String,
      default: null,
    },

    showColumns: {
      type: Boolean,
      default: true,
    },

    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      show: false,
      intervalId: 0,
      items: [
        { name: '10m', intervalValue: 600000 },
        { name: '5m', intervalValue: 300000 },
        { name: '1m', intervalValue: 60000 },
        { name: 'Off', intervalValue: null },
      ] as item[],
      currentTimer: 'Off',
      showEditDialog: false,
      editPreset: null as Nullable<TablePreset>,
      editMode: null as Nullable<EditMode>,
    };
  },

  computed: {
    perPage: {
      get(): number {
        return this.tableSettings.perPage;
      },

      set(perPage: number) {
        this.$store.commit(`${this.settings}/set:perPage`, perPage);
      },
    },

    isVisible: {
      get(): boolean {
        return !!this.$store.state.ui.showFilters;
      },

      set(visible: boolean) {
        this.$store.commit(`ui/set:showFilters`, !!visible);
      },
    },

    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },

    lastUsed: {
      get(): TablePreset {
        return this.tableSettings.lastUsed;
      },

      set(preset: TablePreset) {
        this.$store.commit(`${this.settings}/set:lastUsed`, preset);
      },
    },

    columns(): Column[] {
      const columns: Column[] = this.$store.getters[`${this.settings}/columns`];

      return columns
        .filter(col => !col.hidden)
        .sort((a, b) => {
          const titleA = a.title || a.text || a.value;
          const titleB = b.title || b.text || b.value;

          if (titleA > titleB) {
            return 1;
          }

          if (titleB > titleA) {
            return -1;
          }

          return 0;
        });
    },

    readonly(): Record<string, boolean> {
      if (!this.params.readonly) return {};

      return Object.keys(this.params.readonly).reduce(
        (disabled, key) => ({ ...disabled, [key]: true }),
        {},
      );
    },

    selected: {
      get(): string[] {
        return this.lastUsed.columns;
      },

      set(columns: string[]) {
        this.lastUsed!.columns = columns;
        this.lastUsed = this.lastUsed;
      },
    },

    selectedCount(): number {
      return this.selected.length;
    },

    searchTerm: {
      get(): string | undefined {
        return this.lastUsed.searchTerm;
      },

      set(term: string) {
        this.lastUsed!.searchTerm = term;
        this.lastUsed = this.lastUsed;
      },
    },
  },

  watch: {
    currentTimer(val: string): void {
      const item: item = this.items.find((item: item) => item.name == val)!;
      if (item.intervalValue) {
        window.clearInterval(this.intervalId);
        this.intervalId = window.setInterval(() => {
          this.search();
        }, item.intervalValue);
      } else {
        window.clearInterval(this.intervalId);
      }
    },
    selectedCount: {
      immediate: true,
      handler(val: number) {
        if (val === 0) {
          this.selected = this.columns.map(col => col.value);
        }
      },
    },

    dictionaries: {
      immediate: true,
      handler(dictionaries: string | string[]) {
        if (!dictionaries) return;

        if (dictionaries instanceof Array) {
          dictionaries.forEach(dict => this.$store.dispatch(`${dict}/fetch`));
        } else {
          this.$store.dispatch(`${dictionaries}/fetch`);
        }
      },
    },

    params: {
      immediate: true,
      handler(params: ParamSettings) {
        const query: Record<string, any> = {
          ...this.lastUsed.params,
          ...params.query,
          ...params.values,
        };

        this.lastUsed.params = query;
        this.lastUsed = this.lastUsed;
      },
    },
  },

  mounted() {
    this.$nextTick(() => (this.isVisible = !this.$vuetify.breakpoint.lgAndDown));
  },

  methods: {
    listItem(item: string) {
      this.currentTimer = item;
      this.show = false;
    },
    showOptions() {
      this.show = !this.show;
    },
    setParam(key: string, val: any) {
      this.$set(this.lastUsed.params, key, val);
      this.lastUsed = this.lastUsed;
    },

    clearParam(key: string) {
      this.$delete(this.lastUsed.params, key);
    },

    search() {
      this.$store.dispatch(`${this.settings}/search`);
    },
  },
});
