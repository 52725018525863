










import Vue, { PropType } from 'vue';
import { Column } from '@/types';

export default Vue.extend({
  props: {
    header: Object as PropType<Column>,
  },
});
