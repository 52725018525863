import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.id',
    value: 'id',
    width: 60,
  },

  {
    text: 'office.tables.headers.identifier',
    value: 'identifier',
    width: 60,
  },

  {
    text: 'office.tables.headers.createdAt',
    value: 'created_at',
  },

  {
    text: 'office.tables.headers.finishedAt',
    value: 'finished_at',
  },

  {
    text: 'office.tables.headers.count',
    value: 'count',
  },

  {
    text: 'office.tables.headers.downloadUrl',
    value: 'downloadUrl',
  },
] as Column[];
