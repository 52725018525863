import Vue, { PropType } from "vue";
import { InverterConfiguration, Nullable } from "@/types";

type RouteFactory<T> = (
  item: T
) => { inverterConfigurationLabel: Nullable<string> };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      inverterConfigName(): string {
        const inverterConfig = factory(this.item)?.inverterConfigurationLabel;
        if (inverterConfig) {
          return inverterConfig;
        } else {
          return this.getDefaultInverterConfiguration();
        }
      }
    },

    methods: {
      getDefaultInverterConfiguration(): string {
        const inverterConfigurations: InverterConfiguration[] = this.$store
          .state.dictionaries.inverterConfigurations.items;

        const defaultInverterConfiguration = inverterConfigurations
          ?.filter(item => !!item.default)
          ?.map((item: InverterConfiguration) => ({
            ...item,
            id: null,
            name: `Standaard (${item.name?.split(" ")?.[0]})`,
            disabled: false
          }));

        return defaultInverterConfiguration[0]?.name || "";
      }
    },

    template: `
      <div>
          <span>{{inverterConfigName}}</span>
      </div>
    `
  });
