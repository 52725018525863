import { FunctionalComponentOptions } from 'vue/types';

type Item = {
  count: number;
  state: string;
};

type Props = {
  item: { progress: Item[] };
};

export default (state?: string | string[]): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    if (!props.item) return createElement('span', '0');

    let value = 0;

    if (!state) {
      value = props.item.progress.reduce((total, current) => total + current.count, 0);
    } else if (state instanceof Array)
      value = state.reduce(
        (total, current) =>
          (total += props.item.progress.find(item => item.state === current)?.count ?? 0),
        0,
      );
    else value = props.item.progress.find(item => item.state === state)?.count ?? 0;

    return createElement('span', value.toString());
  },
});
