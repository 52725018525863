

















































































































































































































































import Vue, { PropType } from "vue";
import _ from "underscore";
import { Project } from "@/types";
import InputNumber from "@/components/inputs/InputNumber.vue";
import InputPercentage from "@/components/inputs/InputPercentage.vue";

export default Vue.extend({
  components: { InputPercentage, InputNumber },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    rules: {
      type: Object,
      default: () => []
    },
    autofocus: {
      type: String,
      default: ""
    }
  },

  mounted() {
    if (!this.value?.zmCustomer) {
      this.$set(this.value, "zmCustomer", "wocozon");
    }

    if (this.value?.conversion === null) {
      this.$set(this.value, "conversion", 0);
    }
  },

  computed: {
    installer(): number | undefined {
      if (!this.value.housingCooperativeId) {
        return undefined;
      }

      const hoco: any = this.housingCooperatives.find(
        i => i.id === this.value.housingCooperativeId
      );

      return hoco?.defaultInstallerId;
    },

    installers(): unknown[] {
      return _.sortBy(
        this.housingCooperative?.installers ||
          this.$store.state.dictionaries.installers.items,
        "name"
      );
    },

    housingCooperatives(): any[] {
      return _.sortBy(
        this.$store.state.dictionaries.housingCooperatives.items,
        "name"
      );
    },

    housingCooperative(): any {
      return this.housingCooperatives.find(
        hoco => this.value.housingCooperativeId === hoco.id
      );
    },

    projectManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.projectManagers.items,
        "name"
      );
    },

    status(): number {
      return this.value.status;
    },

    expectedHouses: {
      get(): number {
        return this.value?.expectedHouses ?? 0;
      },

      set(val: number = 0) {
        if (this.value.status > 0) return;

        if (val < 0) val = 0;

        this.value.expectedHouses = val;
      }
    },
    conversion: {
      get(): number {
        return this.value?.conversion ?? 0;
      },

      set(val: number) {
        if (val > 100) val = 100;
        if (val < 0) val = 0;

        this.value.conversion = val ?? 0;
      }
    },

    zipcodeTwoArea: {
      get(): number {
        return this.value?.zipcodeTwoArea ?? 0;
      },

      set(val: number) {
        this.value.zipcodeTwoArea = val ?? 0;
      }
    },

    expectedInstallations: {
      get(): number {
        if (this.value?.status === 0) {
          return Math.round((this.expectedHouses! / 100) * this.conversion!);
        }

        return Math.round((this.value.housesCount / 100) * this.conversion!);
      },

      set(val: number = 0) {
        if (this.value?.status === 0) {
          this.conversion = val ? (val / this.expectedHouses!) * 100 : 0;
        } else if (this.value.status === 1)
          this.conversion = val ? (val / this.value.housesCount!) * 100 : 0;
      }
    }
  },

  methods: {
    setInstaller() {
      this.$set(
        this.value,
        "installerId",
        this.housingCooperative?.defaultInstallerId
      );
    }
  }
});
