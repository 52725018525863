











































































import Vue, { PropType } from 'vue';

import { AuthorizedRoute } from '@/types';

const RESET_GRACE_PERIOD = 1000;

export default Vue.extend({
  props: {
    routes: {
      type: Array as PropType<AuthorizedRoute[]>,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    visibleRoutes(): AuthorizedRoute[] {
      return this.routes.filter(route => !route.hidden?.());
    },

    hasResetRecently(): boolean {
      const { lastReset } = this.$store.state.account.authentication;
      return lastReset && Date.now() - lastReset < RESET_GRACE_PERIOD;
    },
  },
});
