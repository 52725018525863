import { FunctionalComponentOptions } from 'vue/types';
import moment from 'moment';

type Props = {
  value: string | number;
};

export default (format: string = 'DD/MM/YYYY'): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    let value;

    if (typeof props.value === 'string') value = moment(props.value);
    else if (typeof props.value === 'number') value = moment(props.value * 1000);

    return createElement('span', value?.format(format) ?? '');
  },
});
