<template>
  <v-dialog v-model="handle" max-width="1700">
    <v-card id="period-info">
      <div class="period-info-headers">
        <span v-t="$t('office.labels.periods.period')"></span>
        <span v-t="$t('office.labels.periods.state')"></span>
        <span>P200</span>
        <span>P210</span>
        <span>P220</span>
        <span>P221</span>
        <span>P222</span>
        <span>P230</span>
        <span>P240</span>
        <span>P250</span>
        <span>P297</span>
        <span>P298</span>
        <span>P299</span>
        <span>Extra condition</span>
      </div>
      <div class="period-info-data">
        <span v-t="$t('office.labels.periods.screening')"></span>
        <span v-t="$t('office.labels.status.notStarted')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span v-t="$t('office.labels.periods.empty')"></span>
        <div></div>
        <span v-t="$t('office.labels.status.started')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span v-t="$t('office.labels.periods.filled')"></span>
        <div></div>
        <span v-t="$t('office.labels.status.finished')"></span>
        <span>0</span>
        <span>0</span>
        <span class="screening-span-9">> 0</span>
        <span>N/A</span>
      </div>
      <div class="period-info-data">
        <span v-t="$t('office.labels.periods.conversion')"></span>
        <span v-t="$t('office.labels.status.notStarted')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span></span>
        <div></div>
        <span v-t="$t('office.labels.status.started')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span class="conversion-span-1">> 0</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <div></div>
        <div></div>
        <span v-t="$t('office.labels.status.finished')"></span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span class="conversion-span-8">> 0</span>
        <span>N/A</span>
      </div>
      <div class="period-info-data">
        <span v-t="$t('office.labels.periods.planning')"></span>
        <span v-t="$t('office.labels.status.notStarted')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span></span>
        <div></div>
        <span v-t="$t('office.labels.status.started')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span class="planning-span-2">> 0</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span></span>
        <div></div>
        <span v-t="$t('office.labels.status.finished')"></span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span class="planning-span-6">> 0</span>
        <span>N/A</span>
      </div>
      <div class="period-info-data">
        <span v-t="$t('office.labels.periods.installation')"></span>
        <span v-t="$t('office.labels.status.notStarted')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span></span>
        <div></div>
        <span v-t="$t('office.labels.status.started')"></span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span class="installation-span-2">> 0</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span>N/A</span>
        <span></span>
        <div></div>
        <span v-t="$t('office.labels.status.finished')"></span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span>0</span>
        <span class="installation-span-4">> 0</span>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  computed: {
    handle: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', !this.value);
      },
    },
  },
};
</script>

<style>
#period-info {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 10px;
  padding: 30px;
  text-align: center;
}

.period-info-headers {
  display: grid;
  grid-template-columns: repeat(13, 1fr) 250px;
}

.period-info-data {
  display: grid;
  grid-template-columns: repeat(13, 1fr) 250px;
  grid-template-rows: repeat(3, 1fr);
  border: 2px solid lightblue;
}

.period-info-data > * {
  padding: 10px;
}

.screening-span-9 {
  background-color: #32c9ba;
  grid-column: auto / span 8;
}

.conversion-span-1 {
  background-color: #ce93d8;
  grid-column: auto / span 1;
}

.conversion-span-8 {
  background-color: #ce93d8;
  grid-column: auto / span 7;
}

.planning-span-2 {
  background-color: #f6a700;
  grid-column: auto / span 2;
}

.planning-span-6 {
  background-color: #f6a700;
  grid-column: auto / span 5;
}

.installation-span-2 {
  background-color: #42c5fe;
  grid-column: auto / span 2;
}

.installation-span-4 {
  background-color: #42c5fe;
  grid-column: auto / span 4;
}
</style>
