import ExternalLink from "./ExternalLink.vue";
import DownloadLink from "./DownloadLink.vue";

import Counter from "./Counter";
import Moment from "./Moment";
import PlaceHolder from "./Placeholder";
import Random from "./Random";
import Route from "./Route";
import Tags from "./Tags.vue";
import TranslatedList from "./TranslatedList";
import Material from "./Material";
import Number from "./Number";
import Percentage from "./Percentage";
import ProjectProcessState from "./ProjectProcessState";
import CustomLink from "./CustomLink";
import Conversion from "./Conversion";
import ConversionDate from "./ConversionDate";
import ConversionDuration from "./ConversionDuration";
import ConversionInstallations from "./ConversionInstallations";
import StateIcon from "./StateIcon";
import SimpleRoute from "./SimpleRoute";
import ProcessAndDocument from "./processStateAndDocumentation";
import Chip from "./Chip";
import StartAndEndDate from "./StartAndEndDate";
import Inverters from "./Inverters";
import BreakerStatus from "./BreakerStatus";
import PercentageRoof from "./PercentageRoof";
import TranslatedItemId from "./TranslatedItemId";
import ZonmaatEmployeeTypes from "./ZonmaatEmployeeTypes";
import PanelType from "./PanelType";
import InverterConfig from "./InverterConfig";
import AverageHoursZmEmployee from "./AverageHoursZmEmployee";

export default {
  Counter,
  Conversion,
  ConversionDate,
  ConversionDuration,
  ConversionInstallations,
  CustomLink,
  DownloadLink,
  ExternalLink,
  Material,
  Moment,
  Number,
  Percentage,
  PlaceHolder,
  ProjectProcessState,
  Random,
  Route,
  StateIcon,
  Chip,
  Tags,
  TranslatedList,
  SimpleRoute,
  ProcessAndDocument,
  StartAndEndDate,
  Inverters,
  BreakerStatus,
  PercentageRoof,
  TranslatedItemId,
  ZonmaatEmployeeTypes,
  PanelType,
  InverterConfig,
  AverageHoursZmEmployee
};
