






































































import Vue, { PropType } from 'vue';
import Breadcrumb from '@/components/breadcrumb';
import { RouteConfig } from 'vue-router';

export default Vue.extend({
  components: {
    Breadcrumb,
  },

  props: {
    appName: {
      type: String,
      default: 'App',
    },
    user: {
      type: String,
    },
    environment: {
      type: String,
    },
    routes: {
      type: Array,
    },
  },
});
