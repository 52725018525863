




























import Vue from "vue";
import TableToolbar from "@/components/TableToolbar";
import _ from "underscore";

import {
  Pagination,
  TableSettings,
  Nullable,
  TablePreset,
  TableAction,
  ParamSettings
} from "@/types";

export default Vue.extend({
  components: {
    TableToolbar
  },

  inheritAttrs: false,

  props: {
    resource: {
      type: String,
      default: () => ""
    },

    settings: String,

    relationId: {
      type: String,
      default: undefined
    },

    actions: {
      type: Array as () => TableAction[],
      default: () => []
    },

    params: {
      type: Object as () => ParamSettings
    },

    filters: {
      type: Object as () => Record<string, boolean>
    }
  },

  data() {
    return {
      showActionDialog: false,
      action: null as Nullable<TableAction>
    };
  },

  computed: {
    tableSettings(): TableSettings {
      return this.$store.getters[`${this.settings}/get`];
    },

    lastUsed(): TablePreset {
      return this.tableSettings.lastUsed;
    },

    activeFilters(): number {
      const params = _.pick(this.lastUsed.params, Object.keys(this.filters));

      let filters = Object.keys(params).reduce((amount, key) => {
        if (this.params.readonly?.hasOwnProperty(key)) return amount;
        const param: any = params[key];

        switch (true) {
          case param === undefined:
          case param === null:
          case param instanceof Array && param.length === 0:
            return amount;
          default:
            return amount + 1;
        }
      }, 0);

      if (this.lastUsed.searchTerm) {
        filters++;
      }

      return filters;
    },

    currentPage: {
      get(): number {
        return this.tableSettings.currentPage;
      },

      set(val: number) {
        this.$store.commit(`${this.settings}/set:currentPage`, val);
      }
    },

    showFilters: {
      get(): boolean {
        return !!this.tableSettings.isVisible;
      },

      set(val: boolean) {
        this.$store.commit(`${this.settings}/set:isVisible`, val);
      }
    },

    page(): Pagination<unknown> {
      const items: Pagination<unknown> = this.$store.getters[
        `${this.resource}/page`
      ](1, this.relationId);

      return items;
    },

    items(): unknown[] {
      return this.page?.data ?? [];
    },

    pages(): number {
      return this.page?.meta.last_page;
    },

    numberOfItems(): number {
      return this.page?.meta.total;
    }
  },

  watch: {
    showActionDialog(val) {
      if (!val) {
        setTimeout(() => (this.action = null), 500);
      }
    },

    activeFilters: {
      immediate: true,
      handler(val: number) {
        this.$store.commit("ui/set:activeFilters", val);
      }
    }
  },

  methods: {
    refresh() {
      this.$store.dispatch(`${this.settings}/search`);
    },

    handleAction(action: TableAction) {
      this.action = action;
      this.showActionDialog = true;
    }
  }
});
