













































































































































































import Vue from "vue";
import _ from "underscore";
import { ListItem } from "@/types";
import DateTypeSelect from "@/components/date-type-select/DateTypeSelect.vue";

export default Vue.extend({
  components: { DateTypeSelect },
  props: {
    filters: {
      type: Object as () => Record<string, any>
    },

    params: {
      type: Object as () => Record<string, any>
    },

    readonly: {
      type: Object as () => Record<string, true>
    },

    limit: {
      type: Object as () => Record<string, any>
    },

    setParam: {
      type: Function
    },

    clearParam: {
      type: Function
    }
  },

  computed: {
    monitoringOnlyInstallationType(): Boolean {
      return this.$store.state.route.name === "monitoringonlyInstallation";
    },
    installers(): ListItem[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, "name");
    },

    housingCooperatives(): ListItem[] {
      return _.sortBy(
        this.$store.state.dictionaries.housingCooperatives.items,
        "name"
      );
    },

    installationTypes(): ListItem[] {
      let types: ListItem[] = this.$t(
        "office.dictionaries.installationTypes"
      ) as any;

      if (!this.limit.installationType) return types;

      return types
        .filter(type => this.limit.installationType?.includes(type.value))
        .map(type => ({
          ...type,
          disabled: true
        }));
    },

    projectStates(): ListItem[] {
      let states: ListItem[] =
        this.$store.state.route.name != "monitoringonlyInstallation"
          ? this.$t("office.dictionaries.projectStates")
          : (this.$t("office.dictionaries.moProjectStates") as any);

      if (!this.limit.projectStates) return states;

      return states
        .filter(state => this.limit.projectStates.includes(state.value))
        .map(state => ({
          ...state,
          disabled:
            this.params.projectStates?.length === 1 &&
            this.params.projectStates.includes(state.value)
        }));
    }
  }
});
