import Vue from 'vue';

import { VIcon, VTooltip } from 'vuetify/lib';

export default (icon: string, size = 20, hint = '') =>
  Vue.extend({
    components: {
      VIcon,
      VTooltip,
    },

    data() {
      return {
        icon,
        size,
        hint,
      };
    },

    template: `
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="icon" left v-text="icon" :size="size" />
          <v-icon v-if="hint" size="16" v-bind="attrs" v-on="on" color="info" right>mdi-information</v-icon>
        </template>
      <span v-t="hint" />
    </v-tooltip>
    `,
  });
