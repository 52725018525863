import { AsyncModule } from 'vuex-async-mutations';

interface RegistrationState {
  isRegistered: boolean;
}

const factory = (): RegistrationState => ({ isRegistered: false });

export const module: AsyncModule<RegistrationState, any> = {
  state: factory,

  getters: {
    ['isRegistered'](state) {
      return state.isRegistered;
    },
  },

  actionsAsync: {
    ['signUp']: {
      handler(
        { commitAsync },
        {
          email,
          password,
          passwordConfirmation,
          zipcode,
          houseNumber,
          houseNumberAddition,
          meterNumber,
        },
      ) {
        return commitAsync(
          this.$axios.post(
            '/users/profile',
            {
              email,
              password,
              password_confirmation: passwordConfirmation,
              zipcode,
              house_number: houseNumber,
              house_number_addition: houseNumberAddition,
              meter_number: meterNumber,
            },
            { headers: { Authorization: false } },
          ),
        );
      },

      pending(state) {
        state.isRegistered = false;
      },

      resolved(state) {
        state.isRegistered = true;
      },
    },
  },
};

export default module;
