import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

Vue.use(Router);

import AppLayout from '@/apps/office/layouts/app.vue';
import DefaultLayout from '@/layouts/default.vue';

import batches from './routes.batches';
import projects from './projects/routes.projects';
import projectHouses from './projects/routes.projects.houses';
import projectHighrise from './projects/routes.projects.highrise';
import account from './routes.account';
import installers from './installations/routes.installations';
import planning from './planning/routes.planning';

const app: RouteConfig[] = [
  {
    path: '/',
    meta: { authorize: true },
    component: AppLayout,
    redirect: '/projecten/regular',
    children: [
      ...batches,
      ...projectHouses,
      ...projects,
      ...projectHighrise,
      ...installers,
      ...planning,
    ],
  },
  {
    path: '/projecten',
    redirect: '/projecten/regular',
  },
];

const auth: RouteConfig[] = [
  {
    path: '/',
    component: DefaultLayout,
    props: {
      appName: 'Office',
    },
    children: account,
  },
];

const router = new Router({
  mode: 'history',
  routes: [...app, ...auth],
});

export default router;
