import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import { House, ActionDialogAttributes, Project } from '@/types';

type FormData = {
  selectedHouses: House[];
  project: Project;
};

export default WithSteps<FormData, ActionDialogAttributes<House>>({
  title() {
    return this.$t('office.dialogs.houses.add.title', [
      this.$data.project?.region,
      this.$data.project?.code,
    ]).toString();
  },

  formData() {
    const project: Project = this.$store.getters['projects/details'](
      this.$store.state.route.params.id,
    );

    return {
      selectedHouses: this.$attrs.items.slice(),
      project,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedHouses.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['pchn', 'address', 'city', 'tenantName'],
          settings: 'houses/settings',
          itemKey: 'pchn',
          showSelect: true,
        };
      },

      labels: {
        action() {
          return this.$t('office.dialogs.houses.add.action', [
            this.$data.selectedHouses.length,
          ]).toString();
        },
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },

      async afterStep() {
        await this.$store.dispatch('projects/addHouses', {
          id: this.$data.project.id,
          pchn: this.$data.selectedHouses.map(item => item.pchn),
        });

        const proposition = this.$store.state.route.name;
        this.$store.dispatch(`projects/${proposition}/settings/search`);
      },
    },
  ],
});
