import { RouteConfig, Route } from "vue-router";

import PlanningNavigation from "@/apps/office/pages/planning/containers/Navigation";
import TableFilters from "@/apps/office/containers/table-filters";
import Calendar from "@/apps/office/pages/planning/Calendar.vue";
import CalendarFilters from "@/apps/office/containers/table/planning/calendar/Filters.vue";

import { TableViewProps, AuthorizedRoute } from "@/types";

import filters from "@/apps/office/components/table/shared/items/filters";
function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: "/planning",
      title: "office.navigation.root.planning",
      icon: "mdi-calendar-month",
      permission: "CanViewInstallations"
    },
    ...path
  ];
}

const tableViewProps = (props: TableViewProps) => (
  route: Route
): TableViewProps => ({
  relationId: route.params.id,
  resource: "planning/calendar",
  settings: "planning/calendar/settings",
  dictionaries: "projects/dictionaries",
  filters: CalendarFilters,
  ...props,
  scope: { ...props.scope, query: route.query, filters }
});

export default [
  {
    path: "planning/calendar",
    components: {
      default: Calendar,
      navigation: PlanningNavigation,
      sidepanel: TableFilters
    },
    meta: {
      authorize: ["CanViewProjects"],
      breadcrumb: breadcrumb([{ title: "office.navigation.planning.gantt" }]),
      hasFilters: true,
      hasSideNav: true
    },
    props: {
      settings: "planning/calendar/settings",
      sidepanel: tableViewProps({
        scope: {
          limit: {
            [filters.PROJECT_STATUS]: [0, 1]
          }
        },
        showColumns: false,
        showPagination: false,
        settings: "planning/calendar/settings"
      })
    }
  }
] as RouteConfig[];
