import Vue, { PropType } from 'vue';

import { VIcon } from 'vuetify/lib';

type RouteFactory<T> = (item: T) => { url?: string; state: string };

export default <T = any>(
  factory: RouteFactory<T>,
  icons?: Record<string, string>,
  colors?: Record<string, string>,
) =>
  Vue.extend({
    props: {
      item: Object as PropType<T>,
    },

    components: {
      VIcon,
    },

    data() {
      const props = factory(this.item);
      return {
        icons,
        color: colors?.[props.state] || 'grey lighten-2',
        url: props.url,
        active: 'none',
      };
    },

    template: `<div>
     <v-icon :color="color" size="16" v-text="icons.stateIcon" />
     <span style="color: #c9c9c9">|</span>
     <a :href="url" target="_empty" v-bind:style="[!url ? { pointerEvents: active } : {}]">
      <v-icon v-if="url" color="success" left v-text="icons.iconWithUrl" size="20" />
      <v-icon v-else color="grey lighten-2" left v-text="icons.iconNoUrl" size="20" />
     </a>
     </div>`,
  });
