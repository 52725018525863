var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"elevation-0",attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[(_vm.editPreset)?_c('v-card',[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.edit'),expression:"'office.dialogs.presets.edit'"}]}),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('office.dialogs.presets.name'),"hide-details":"","filled":""},model:{value:(_vm.editPreset.name),callback:function ($$v) {_vm.$set(_vm.editPreset, "name", $$v)},expression:"editPreset.name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('dialogs.cancel'),expression:"'dialogs.cancel'"}],attrs:{"text":""},on:{"click":_vm.cancel}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('dialogs.save'),expression:"'dialogs.save'"}],attrs:{"text":"","color":"primary"},on:{"click":_vm.save}})],1)],1):_vm._e()],1),(_vm.showExportDialog)?_c('export-dialog',{attrs:{"items":_vm.presets},model:{value:(_vm.showExportDialog),callback:function ($$v) {_vm.showExportDialog=$$v},expression:"showExportDialog"}}):_vm._e(),(_vm.showImportDialog)?_c('import-dialog',{attrs:{"settings":_vm.settings},model:{value:(_vm.showImportDialog),callback:function ($$v) {_vm.showImportDialog=$$v},expression:"showImportDialog"}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.presets,"item-text":"name","item-value":"name","return-object":"","hide-details":"","outlined":"","label":_vm.$t('office.labels.fields.presets')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(item.name))])])],1),(item.readonly)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-off")]):_vm._e()]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))]),(_vm.hasChanged)?_c('small',{staticClass:"ml-1 grey--text"},[_vm._v("("+_vm._s(_vm.$t("labels.changed"))+")")]):_vm._e()]}}]),model:{value:(_vm.preset),callback:function ($$v) {_vm.preset=$$v},expression:"preset"}})],1)],1),_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.clone}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.copy'),expression:"'office.dialogs.presets.copy'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":"","disabled":_vm.preset.readonly},on:{"click":_vm.remove}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.delete'),expression:"'office.dialogs.presets.delete'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":"","disabled":_vm.preset.readonly},on:{"click":_vm.edit}},on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.edit'),expression:"'office.dialogs.presets.edit'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":"","disabled":!_vm.hasChanged},on:{"click":_vm.undo}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.undo'),expression:"'office.dialogs.presets.undo'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":"","disabled":_vm.preset.readonly || !_vm.hasChanged},on:{"click":_vm.save}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.presets.save'),expression:"'office.dialogs.presets.save'"}]})]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":_vm.download}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.export.title'),expression:"'office.dialogs.export.title'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"small":"","icon":""},on:{"click":_vm.upload}},on),[_c('v-icon',[_vm._v("mdi-application-import")])],1)]}}])},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('office.dialogs.import.title'),expression:"'office.dialogs.import.title'"}]})]),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }