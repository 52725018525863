import { AsyncModule } from 'vuex-async-mutations';

export default {
  namespaced: true,

  async: true,

  modules: {},

  getters: {},

  actionsAsync: {
    fetch: {
      handler(context, moduleType: string) {
        return this.$axios.get(`/delivery-documents/drafts/${moduleType}`);
      },
    },
  },
} as AsyncModule<any, any>;
