import axios, { AxiosInstance } from 'axios';
import { Store } from 'vuex';

export interface AxiosPluginConfig {
  token: string;
  apiVersion: string;
  baseURL: string;
  locale: string;
}

const defaultConfig = (): AxiosPluginConfig => ({
  token: '',
  apiVersion: '1.0',
  baseURL: '',
  locale: 'nl',
});

export default (config: () => AxiosPluginConfig = defaultConfig) => (store: Store<any>): void => {
  store.$axios = axios.create();
  store.$axios.defaults.headers.Authorization = true;

  store.$axios.interceptors.request.use(request => {
    const { token, apiVersion, baseURL, locale } = config();

    request.baseURL = request.baseURL ?? baseURL;

    if (request.headers.Authorization) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    if (apiVersion) {
      request.headers['x-api-version'] = apiVersion;
    }

    if (locale) {
      request.headers['x-api-locale'] = locale;
    }

    // Do something before request is sent
    return request;
  });

  store.$axios.interceptors.response.use(response => {
    if (response.data?.data instanceof Array) {
      // return higher level response object if one of these metadata props is found
      const metaProps = ['links', 'meta', 'allCount'];
      if (metaProps.some(prop => prop in response.data)) {
        return response.data;
      }
    }

    return response.data?.data || response.data || response;
  });
};

/**
 * This overwrites the default interface definition for AxiosResponse.
 * Because the interceptor defined above will transform the contents of each response,
 * the default definition is not correct in our application.
 */
declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

declare module 'vuex/types' {
  interface Store<S> {
    $axios: AxiosInstance;
  }
}
