import DocumentPreview from '@/apps/office/containers/table/houses/dialogs/steps/DocumentPreview.vue';
import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';
import BatchProgress from '@/apps/office/containers/table/shared/dialogs/steps/BatchProgress.vue';
import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import EditTestimonial from '@/apps/office/containers/table/houses/dialogs/steps/EditTestimonial.vue';
import { House, ActionDialogAttributes, Nullable, ExportBatch, Project } from '@/types';

type FormData = {
  selectedHouses: House[];
  batch?: ExportBatch;
  updateTestimonial?: boolean;
  testimonialCity: Nullable<string>;
  testimonialSentence: Nullable<string>;
  testimonialTenant: Nullable<string>;
  updateTestimonialValid?: boolean;
  project: Project;
};

export default WithSteps<FormData, ActionDialogAttributes<House>>({
  title: 'office.dialogs.houses.generateAndSendContracts.title',

  formData() {
    const project: Project = this.$store.getters['projects/details'](
      this.$store.state.route.params.id,
    );

    return {
      selectedHouses: this.$attrs.items.slice(),
      updatePanelsAndInverters: false,
      testimonialCity: null,
      testimonialSentence: null,
      testimonialTenant: null,
      updateTestimonial: false,
      updateTestimonialValid: false,
      project,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedHouses.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['pchn', 'address', 'city', 'tenantName', 'contractState', 'panel', 'inverter'],
          settings: 'houses/settings',
          itemKey: 'pchn',
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },
    },

    {
      component: EditTestimonial,

      props() {
        return {
          testimonialType: 'generate-contracts',
        };
      },

      sync: {
        testimonialCity: 'testimonialCity',
        testimonialSentence: 'testimonialSentence',
        testimonialTenant: 'testimonialTenant',
        updateTestimonial: 'updateTestimonial',
        updateTestimonialValid: 'updateTestimonialValid',
      },

      isValid() {
        return !this.$data.updateTestimonial || !!this.$data.updateTestimonialValid;
      },
    },

    {
      component: DocumentPreview,

      props() {
        return {
          selectedHouses: this.$data.selectedHouses,
          testimonialCity: this.$data.testimonialCity,
          testimonialSentence: this.$data.testimonialSentence,
          testimonialTenant: this.$data.testimonialTenant,
          updateTestimonial: this.$data.updateTestimonial,
          previewTitle: 'office.dialogs.houses.generateAndSendContracts.steps[0].previewTitle',
          previewType: 'office.dialogs.houses.generateAndSendContracts.steps[0].previewType',
          type: 'contract',
        };
      },
    },

    {
      component: Confirm,

      value: {
        get() {
          let message: string = 'office.dialogs.houses.generateAndSendContracts.steps[1].title';

          return this.$t(message, [
            `<span class="font-weight-bold">${this.$data.selectedHouses.length}</span>`,
          ]);
        },
      },

      labels: {
        action() {
          return this.$t('office.dialogs.houses.updateExpiredContracts.action', [
            this.$data.selectedHouses.length,
          ]).toString();
        },
      },

      async afterStep() {
        this.$data.batch = await this.$store.dispatch(
          'projects/regularHouses/bulk:generateContract',
          {
            projectId: this.$data.project.id,
            houses: this.$data.selectedHouses,
            ...(this.$data.updateTestimonial
              ? {
                  testimonialCity: this.$data.testimonialCity,
                  testimonialSentence: this.$data.testimonialSentence,
                  testimonialTenant: this.$data.testimonialTenant,
                }
              : {}),
          },
        );
      },
    },

    {
      component: BatchProgress,

      value: {
        get() {
          return this.$data.batch;
        },

        set(val: ExportBatch) {
          this.$data.batch = val;
        },
      },

      props() {
        return {
          batch: this.$data.batch,
          doesSendZip: true,
        };
      },

      labels: {
        action: 'dialogs.done',
      },

      isValid() {
        return !!this.$data.batch && !!this.$data.batch.finishedAt && !!this.$data.batch.progress;
      },

      afterStep() {
        const proposition = this.$store.state.route.name;
        return this.$store.dispatch(`projects/${proposition}/settings/search`);
      },
    },
  ],
});
