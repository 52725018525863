import activation from './activation';
import authentication from './authentication';
import registration from './registration';
import { AsyncModule } from 'vuex-async-mutations';

export default {
  namespaced: true,

  async: true,

  modules: {
    activation,
    authentication,
    registration,
  },
} as AsyncModule<any, any>;
