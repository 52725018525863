


























import Vue, { PropType } from 'vue';
import { CellRenderers } from '@/types';

import _ from 'underscore';

export default Vue.extend({
  props: {
    renderers: {
      type: Object as PropType<CellRenderers>,
      default: () => {},
    },

    showSearch: Boolean,

    searchTerm: String,
  },

  computed: {
    propName() {
      return (slot: string) => slot.split('.').pop();
    },

    findValue() {
      return (item: any, slot: string) => {
        return _.property(slot.split('.').slice(1))(item);
      };
    },
  },
});
