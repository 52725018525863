import SideNav from '@/containers/side-nav';
import { Project, AuthorizedRoute } from '@/types';
import propositions from '@/apps/office/static/project-propositions.json';

export default SideNav({
  async init(route, store) {
    store.dispatch('projects/fetch', route.params.id);
  },

  routes: (route, store) => {
    const id: string = route.params.id;

    const project: Project = store.getters['projects/details'](id);

    if (!project) return [];

    function proposition() {
      return propositions[project?.installationType].project_name;
    }

    return [
      {
        title: 'office.navigation.main',
      },
      {
        to: `/projecten/${id}`,
        title: 'office.navigation.projects.houses.dashboard',
        icon: 'mdi-view-dashboard',
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/huidig`,
        title: 'office.navigation.projects.houses.houses',
        icon: 'mdi-home',
      },
      {
        title: 'office.navigation.projects.highrise.root',
        hidden() {
          return project.buildingType !== 1;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/hoogbouw`,
        title: 'office.navigation.projects.highrise.dashboard',
        icon: 'mdi-domain',
        hidden() {
          return project.buildingType !== 1;
        },
      },
      {
        title: 'office.navigation.projects.duplex.root',
        hidden() {
          return project.buildingType !== 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/duplex`,
        title: 'office.navigation.projects.highrise.dashboard',
        icon: 'mdi-domain',
        hidden() {
          return project.buildingType !== 2;
        },
      },
      {
        title: 'office.navigation.projects.houses.bulk',
        permission: 'CanEditProjects',
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/verwijderen`,
        title: 'office.navigation.projects.houses.removeHouses',
        permission: 'CanEditProjects',
        icon: 'mdi-home-minus',
        tooltip: 'office.navigation.projects.tooltip.regular.removeHouses',
        hidden() {
          return project.status === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/toevoegen`,
        title: 'office.navigation.projects.houses.addHouses',
        permission: 'CanEditProjects',
        icon: 'mdi-home-plus',
        tooltip: 'office.navigation.projects.tooltip.regular.addHouses',
        hidden() {
          return project.status === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/GenereerProjectlancering`,
        title: 'office.navigation.projects.houses.generateProjectLaunch',
        permission: 'CanEditProjects',
        icon: 'mdi-rocket-launch-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.generateProjectLaunch',
        hidden() {
          return (
            project.status === 0 ||
            project.status === 2 ||
            project.type === 2 ||
            project.type === 3 ||
            project.type === 4 ||
            project.installationType === 2
          );
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/genereerEnVerstuurContracten`,
        title: 'office.navigation.projects.houses.generateAndSendContracts',
        permission: 'CanEditProjects',
        icon: 'mdi-text-box-plus-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.generateAndSendContracts',
        hidden() {
          return project.status === 0 || project.status === 2 || project.installationType === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/afsluitenContractFase`,
        title: 'office.navigation.projects.houses.closeContractPhase',
        permission: 'CanEditProjects',
        icon: 'mdi-text-box-check-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.closeContractPhase',
        hidden() {
          return project.status === 0 || project.status === 2 || project.installationType === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/vrijgevenVoorInstallatie`,
        title: 'office.navigation.projects.houses.clearForInstallation',
        permission: 'CanEditProjects',
        icon: 'mdi-home-assistant',
        tooltip:
          project.installationType != 2
            ? 'office.navigation.projects.tooltip.regular.clearForInstallation'
            : 'office.navigation.projects.tooltip.monitoring-only.clearForInstallation',
        hidden() {
          return project.status === 0 || project.status === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/genereerNieuweContracten`,
        title: 'office.navigation.projects.houses.updateExpiredContracts',
        permission: 'CanEditProjects',
        icon: 'mdi-text-box-multiple-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.updateExpiredContracts',
        hidden() {
          return project.status === 0 || project.status === 2 || project.installationType === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/deleteContracts`,
        title: 'office.navigation.projects.houses.deleteContracts',
        permission: 'CanEditProjects',
        icon: 'mdi-text-box-remove-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.deleteContracts',
        hidden() {
          return project.status === 0 || project.status === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/woningenOverzetten`,
        title: 'office.navigation.projects.houses.transferHouses',
        permission: 'CanEditProjects',
        icon: 'mdi-home-export-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.transferHouses',
        hidden() {
          return project.status === 0 || project.status === 2 || project.installationType === 2;
        },
      },
      {
        to: `/projecten/${id}/${proposition()}/woningen/verstuurDocumentType`,
        title: 'office.navigation.projects.houses.sendDocumentFiles',
        permission: 'CanEditProjects',
        icon: 'mdi-file-download-outline',
        tooltip: 'office.navigation.projects.tooltip.regular.sendDocumentFiles',
      },
    ] as AuthorizedRoute[];
  },
});
