import cells from "@/apps/office/components/table/shared/renderers/cell";

import { CellRenderers, Project, Installation, House } from "@/types";
import { EmployeeTypes } from "@/components/average-hours-input/helpers";

const allItems = {
  "item.installationStartAt": cells.Moment("DD/MM/YYYY"),
  "item.installationEndAt": cells.Moment("DD/MM/YYYY"),
  "item.createdAt": cells.Moment("DD/MM/YYYY"),
  "item.updatedAt": cells.Moment("DD/MM/YYYY"),
  "item.approved": cells.Moment("DD/MM/YYYY"),
  "item.contractSentDate": cells.Moment("DD/MM/YYYY"),
  "item.conversion": cells.Percentage(),
  "item.contractConversion": cells.Percentage(),
  "item.actualConversion": cells.Percentage(),
  "item.planned.conversion": cells.Conversion(0),
  "item.planned.expectedInstallations": cells.ConversionInstallations(0),
  "item.planned.installationStartAt": cells.ConversionDate(
    0,
    "installationStartAt"
  ),
  "item.planned.installationEndAt": cells.ConversionDate(
    0,
    "installationEndAt"
  ),
  "item.planned.installationDuration": cells.ConversionDuration(0),
  "item.active.conversion": cells.Conversion(1),
  "item.active.expectedInstallations": cells.ConversionInstallations(1),
  "item.active.installationStartAt": cells.ConversionDate(
    1,
    "installationStartAt"
  ),
  "item.active.installationEndAt": cells.ConversionDate(1, "installationEndAt"),
  "item.active.installationDuration": cells.ConversionDuration(1),
  "item.finished.conversion": cells.Conversion(0),
  "item.finished.expectedInstallations": cells.ConversionInstallations(0),
  "item.expectedHouses": cells.Number(),
  "item.expectedInstallations": cells.Number(),
  "item.housesCount": cells.Number(),
  "item.housesCountFinal": cells.ProjectProcessState(),
  "item.teamsUrl": cells.SimpleRoute((project: Project) => ({
    url: project.teamsUrl,
    title: project.teamsUrl
  })),
  "item.urlProjectFiles": cells.SimpleRoute((project: Project) => ({
    url: project.urlProjectFiles,
    title: project.urlProjectFiles
  })),
  "item.urlRegion": cells.Route((project: Project) => ({
    url: `/projecten/${project.id}`,
    title: project.region
  })),
  "item.breakerStatus": cells.BreakerStatus((item: Installation) => ({
    breakerStatus: item.breakerStatus
  })),
  "item.panelTypeId": cells.TranslatedItemId("panelTranslation"),
  "item.inverterConfigurationId": cells.TranslatedItemId(
    "inverterConfigurationTranslation"
  ),
  "item.panelsLocation": cells.TranslatedList(
    "office.dictionaries.panelLocation"
  ),
  "item.roofType": cells.TranslatedList("office.dictionaries.roofTypes"),
  "item.buildingType": cells.TranslatedList(
    "office.dictionaries.buildingTypes"
  ),
  "item.status": cells.TranslatedList("office.dictionaries.projectStatus"),
  "item.type": cells.TranslatedList("office.dictionaries.projectTypes"),
  "item.installationType": cells.TranslatedList(
    "office.dictionaries.installationTypes"
  ),
  "item.zmScreeningReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmVgmTraReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmBlueprintReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmProjectPlanReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmQuoteReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmHandoverReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmWiringDiagramReady": cells.TranslatedList(
    "office.dictionaries.standardSelect"
  ),
  "item.zmCustomer": cells.TranslatedList(
    "office.dictionaries.zonmaatCustomers"
  ),
  "item.projectCertainty": cells.TranslatedList(
    "office.dictionaries.projectCertainty"
  ),
  "item.powerMainsType": cells.TranslatedList(
    "office.dictionaries.powerMainsType"
  ),
  "item.planningType": cells.TranslatedList("office.dictionaries.planningType"),
  "item.inverterType": cells.TranslatedList("office.dictionaries.inverterType"),
  "item.progress.NotInProcess": cells.ProjectProcessState("NotInProcess"),
  "item.installationsCount": cells.ProjectProcessState("P250"),
  "item.expectedWorkQueue": cells.Number(),
  "item.workQueue": cells.ProjectProcessState(["P221", "P222", "P230", "P240"]),
  "item.NotInProcess": cells.ProjectProcessState("NotInProcess"),
  "item.P200": cells.ProjectProcessState("P200"),
  "item.P210": cells.ProjectProcessState("P210"),
  "item.P220": cells.ProjectProcessState("P220"),
  "item.P221": cells.ProjectProcessState("P221"),
  "item.P222": cells.ProjectProcessState("P222"),
  "item.P230": cells.ProjectProcessState("P230"),
  "item.P240": cells.ProjectProcessState("P240"),
  "item.P250": cells.ProjectProcessState("P250"),
  "item.P297": cells.ProjectProcessState("P297"),
  "item.P298": cells.ProjectProcessState("P298"),
  "item.P299": cells.ProjectProcessState("P299"),
  "item.screeningStartedAt": cells.Moment("DD/MM/YYYY"),
  "item.webinarAt": cells.Moment("DD/MM/YYYY @ H:mm"),
  "item.tenantMeetingDate": cells.Moment("DD/MM/YYYY @ H:mm"),
  "item.preAnnouncementAt": cells.Moment("DD/MM/YYYY"),
  "item.contractsSentAt": cells.Moment("DD/MM/YYYY"),
  "item.contractsDeadlineAt": cells.Moment("DD/MM/YYYY"),
  "item.moInstallationsCount": cells.ProjectProcessState("M250"),
  "item.moWorkQueue": cells.ProjectProcessState(["M221", "M230"]),
  "item.M200": cells.ProjectProcessState("M200"),
  "item.M221": cells.ProjectProcessState("M221"),
  "item.M230": cells.ProjectProcessState("M230"),
  "item.M250": cells.ProjectProcessState("M250"),
  "item.M299": cells.ProjectProcessState("M299"),
  "item.panel": cells.Material,
  "item.inverter": cells.Material,
  "item.crmUrl": cells.CustomLink(
    (item: Installation) => ({
      url: item.crmUrl
    }),
    "mdi-open-in-new"
  ),
  "item.mechUrl": cells.CustomLink(
    (item: Installation) => ({
      url: item.mechUrl
    }),
    "mdi-open-in-new"
  ),
  "item.issues": cells.Chip(
    (item: Installation) => ({
      value: item.issues
    }),
    {
      color: "red lighten-1",
      label: true
    }
  ),
  "item.tags": cells.Chip(
    (item: House) => ({
      value: item.tags
    }),
    {
      color: "grey lighten-3",
      label: false
    }
  ),
  "item.inverters": cells.Inverters((item: House) => ({
    value: item.inverters
  })),
  "item.zmRequiredExpertise": cells.ZonmaatEmployeeTypes(
    (project: Project) => ({
      value: project.zmRequiredExpertise
    })
  ),
  "item.panelTypeLabel": cells.PanelType((project: Project) => ({
    panelType: project.panelTypeLabel
  })),
  "item.inverterConfigurationLabel": cells.InverterConfig(
    (project: Project) => ({
      inverterConfigurationLabel: project.inverterConfigurationLabel
    })
  ),
  "item.panelProcessStateAndDocumentation": cells.ProcessAndDocument(
    (item: Installation) => ({
      url: item.panelsDocument,
      state: item.panelsState
    }),
    {
      stateIcon: "fa-solar-panel",
      iconWithUrl: "fa fa-file-pdf",
      iconNoUrl: "far fa-file-pdf"
    },
    {
      P5999: "success"
    }
  ),
  "item.meterProcessStateAndDocumentation": cells.ProcessAndDocument(
    (item: Installation) => ({
      url: item.meterDocument,
      state: item.meterState
    }),
    {
      stateIcon: "fa-digital-tachograph",
      iconWithUrl: "fa fa-file-pdf",
      iconNoUrl: "far fa-file-pdf"
    },
    {
      P6999: "success"
    }
  ),
  "item.averageHoursPvMechanic": cells.AverageHoursZmEmployee(
    (project: Project) => ({
      project,
      norm: project?.zmAverageHoursPvMechanic,
      employee: EmployeeTypes.PvMechanic
    })
  ),
  "item.averageHoursElectrician": cells.AverageHoursZmEmployee(
    (project: Project) => ({
      project,
      norm: project?.zmAverageHoursElectrician,
      employee: EmployeeTypes.Electrician
    })
  ),
  "item.planned.installationStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[0].installationStartAt,
      endDate: project.conversionValues[0].installationEndAt
    })
  ),
  "item.planned.screeningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[0].screeningStartAt,
      endDate: project.conversionValues[0].screeningEndAt
    })
  ),
  "item.planned.conversionStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[0].conversionStartAt,
      endDate: project.conversionValues[0].conversionEndAt
    })
  ),
  "item.planned.planningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[0].planningStartAt,
      endDate: project.conversionValues[0].planningEndAt
    })
  ),
  "item.active.installationStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[1].installationStartAt,
      endDate: project.conversionValues[1].installationEndAt
    })
  ),
  "item.active.screeningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[1].screeningStartAt,
      endDate: project.conversionValues[1].screeningEndAt
    })
  ),
  "item.active.conversionStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[1].conversionStartAt,
      endDate: project.conversionValues[1].conversionEndAt
    })
  ),
  "item.active.planningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[1].planningStartAt,
      endDate: project.conversionValues[1].planningEndAt
    })
  ),
  "item.finished.installationStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[2].installationStartAt,
      endDate: project.conversionValues[2].installationEndAt
    })
  ),
  "item.finished.screeningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[2].screeningStartAt,
      endDate: project.conversionValues[2].screeningEndAt
    })
  ),
  "item.finished.conversionStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[2].conversionStartAt,
      endDate: project.conversionValues[2].conversionEndAt
    })
  ),
  "item.finished.planningStartAndEndDate": cells.StartAndEndDate(
    (project: Project) => ({
      startDate: project.conversionValues[2].planningStartAt,
      endDate: project.conversionValues[2].planningEndAt
    })
  ),
  "item.planned.percentageSlantedRoof": cells.PercentageRoof(
    (project: Project) => ({
      percentage: project.percentageSlantedRoof
    })
  ),
  "item.planned.percentageFlatRoof": cells.PercentageRoof(
    (project: Project) => ({
      percentage: 1 - project.percentageSlantedRoof
    })
  )
} as CellRenderers;

export default allItems;
