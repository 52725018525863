






















import Vue, { PropType } from 'vue';

type Item = { value: number; text: string; color: string; label: string };

export default Vue.extend({
  props: {
    items: Array as PropType<Item[]>,
    height: {
      type: [String, Number],
      default: 20,
    },
  },

  computed: {
    totalSize(): number {
      return this.items.reduce((total, item) => (total += item.value), 0);
    },

    itemSize() {
      return (item: Item) => (item.value / this.totalSize) * 100;
    },
  },
});
