import Vue from 'vue';
import { ItemId, BaseItem } from './base';
import { AsyncModule } from 'vuex-async-mutations';
import { Project } from '@/types';

interface DetailsState<T extends BaseItem> {
  itemDetails: Record<string, T>;
}

const stateFactory = <T extends BaseItem>(): DetailsState<T> => ({
  itemDetails: {} as Record<string, T>,
});

export const module = <T extends BaseItem>(endpoint: string, idProp: ItemId = 'id') =>
  ({
    state: stateFactory,

    mutations: {
      ['item:delete'](state, item: T) {
        Vue.delete(state.itemDetails, item[idProp]!);
      },

      ['item:update'](state, item: T) {
        Vue.set(state.itemDetails, item[idProp]!, item);
      },

      ['reset'](state) {
        Object.assign(state, stateFactory());
      },
    },

    getters: {
      ['details'](state) {
        return (id: string) => state.itemDetails[id];
      },
      // ['proposition'](state) {
      //   return (id: string) => {
      //     const project: Project = state.itemDetails[id];
      //     return project?.installationType;
      //   };
      // },
    },

    actions: {
      ['reset']: {
        root: true,
        handler({ commit }) {
          commit('reset');
        },
      },
    },

    actionsAsync: {
      ['fetch']: {
        handler({ commitAsync }, id: string) {
          return commitAsync('fetch', this.$axios.get<T>(`${endpoint}/${id}`));
        },

        resolved(state, item: T) {
          Vue.set(state.itemDetails, item[idProp]!, item);
        },
      },
    },
  } as AsyncModule<DetailsState<T>, any>);

export default module;
