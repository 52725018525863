import Vue, { PropType } from 'vue';
import { VNode } from 'vue';
import { Progress } from '@/types';

import { formatPercentage } from '@/util/formatters';

export default Vue.extend({
  props: {
    item: Object as PropType<any>,
    value: Object as PropType<Progress>,
  },

  render(h): VNode {
    const link = h('span', `${this.value.progress}`);

    return link;
  },
});
