





















































import Vue, { PropType } from 'vue';
import _ from 'underscore';
import { Material, Panel, InverterConfiguration, Nullable } from '@/types';

export default Vue.extend({
  props: {
    panelId: Number,

    inverterConfigurationId: Number,

    updatePanelsAndInverters: Boolean,
  },

  mounted() {
    this.$emit('update:panelId', this.panels.find(item => item.default)?.id);
    this.$emit(
      'update:inverterConfigurationId',
      this.inverterConfigurations.find(item => item.default)?.id,
    );
  },

  computed: {
    panels(): Panel[] {
      return _.sortBy(
        this.$store.state.dictionaries.panels.items
          .filter((item: Material) => item.available)
          .map((item: Panel) => ({
            ...item,
            disabled: !item.available,
            text: `${item.brand} - ${item.model} (${item.output} Wp)`,
          })),
        'text',
      );
    },

    inverterConfigurations(): InverterConfiguration[] {
      return _.sortBy(
        this.$store.state.dictionaries.inverterConfigurations.items
          .filter((item: InverterConfiguration) => item.available)
          .map((item: InverterConfiguration) => ({
            ...item,
            disabled: !item.available,
            text: `${item.name}`,
          })),
        'text',
      );
    },
  },
});
