import SideNav from '@/containers/side-nav';

export default SideNav({
  routes: [
    {
      title: 'office.navigation.main',
    },
    {
      to: '/projecten/allprojects',
      title: 'Alle projecten',
      icon: 'mdi-briefcase-eye',
    },
    {
      to: '/projecten/regular',
      title: 'office.navigation.projects.regular',
      icon: 'mdi-home-variant-outline',
    },
    {
      to: '/projecten/powershare',
      title: 'office.navigation.projects.powershare',
      icon: 'mdi-domain',
    },
    {
      to: '/projecten/monitoringonly',
      title: 'office.navigation.projects.monitoringonly',
      icon: 'fa-digital-tachograph',
    },
    {
      to: '/projecten/pvt',
      title: 'office.navigation.projects.pvt',
      icon: 'mdi-water-boiler',
    },
    {
      to: '/projecten/powermagnet',
      title: 'office.navigation.projects.powermagnet',
      icon: 'mdi-magnet-on',
    },
    {
      to: '/projecten/postalcoderose',
      title: 'office.navigation.projects.postalcoderose',
      icon: 'mdi-hexagon-multiple-outline',
    },
  ],
});
