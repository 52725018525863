














import Vue, { PropType } from 'vue';
import { Column, CellRenderers, DataTableOptions } from '@/types';

import WDataTable from '@/components/table';

export default Vue.extend({
  props: {
    settings: String,
    columns: Array as PropType<string[]>,
    items: Array as PropType<any[]>,
    value: Array as PropType<any[]>,
    itemKey: {
      type: String,
      default: () => 'id',
    },
  },

  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      } as DataTableOptions,
    };
  },

  components: {
    WDataTable,
  },

  computed: {
    headers(): Column[] {
      return this.$store.getters[`${this.settings}/columns:selection`](this.columns);
    },

    renderers(): CellRenderers {
      return this.$store.getters[`${this.settings}/renderers`];
    },
  },
});
