<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue';
import Hotjar from 'vue-hotjar';

Vue.use(Hotjar, {
  id: '2985482',
  isProduction: process.env.NODE_ENV === 'production',
});

export default {};
</script>
