




















































































import Vue from 'vue';
import { AxiosError } from 'axios';

import rules from '@/util/forms';
import { Nullable } from '@/types';

export default Vue.extend({
  props: {
    forgotPasswordLink: String,
    registerLink: String,
    signInAsLink: String,
    allowRegistration: Boolean,
  },

  data() {
    return {
      error: null as Nullable<AxiosError>,
      valid: false,
      showPassword: false,
      email: '',
      password: '',
      rules,
    };
  },

  computed: {
    errorMessage(): string {
      return this.error?.response?.data?.message ?? this.error?.message;
    },

    isAuthenticating(): boolean {
      return this.$store.getters['account/isPending'];
    },

    isAuthenticated(): boolean {
      return this.$store.getters['account/isAuthenticated'];
    },

    isAdmin(): boolean {
      return this.$store.state.profile.roles.admin;
    },
  },

  mounted() {
    this.error = null;
  },
  methods: {
    async signIn() {
      const form: any = this.$refs.form;

      if (form.validate()) {
        try {
          this.error = null;

          await this.$store.dispatch('account/signIn', {
            email: this.email,
            password: this.password,
          });

          if (this.signInAsLink && this.isAdmin) {
            this.$router.replace(this.signInAsLink);
          } else {
            this.$router.replace('/');
          }
        } catch (error) {
          this.error = error;
        }
      }
    },
  },
});
