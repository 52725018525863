import { Module } from 'vuex/types';
import _ from 'underscore';
import { MockScenario, HttpMock } from '@/util/xhr-mock';

export type MockState = {
  current: 0 | string;
  availableScenarios: string[];
};

let _scenarios: MockScenario[] = [];

export default {
  namespaced: true,

  state: {
    current: 0,
    availableScenarios: [],
  },

  getters: {
    isProduction(): boolean {
      return process.env.NODE_ENV !== 'development';
    },

    availableScenarios(state): string[] {
      return state.availableScenarios;
    },

    current(state): MockScenario | undefined {
      return _scenarios.find(scenario => scenario.name === state.current);
    },

    getMocksFromScenario(state, getters) {
      return (scenario: MockScenario): HttpMock[] => {
        const dependencies =
          scenario.dependencies?.flatMap(dep => getters.getMocksFromScenario(dep) as HttpMock[]) ??
          [];

        return [...dependencies, ...(scenario.mocks ?? [])];
      };
    },

    mocks(state, getters): HttpMock[] {
      const current: MockScenario = getters.current;

      if (!current) return [];

      return getters.getMocksFromScenario(current);
    },
  },

  mutations: {
    ['set:current'](state, scenarioName: string) {
      state.current = scenarioName;
    },

    ['set:scenarios'](state, scenarios: MockScenario[]) {
      _scenarios = scenarios;
      state.availableScenarios = _.sortBy(scenarios.map(scenario => scenario.name));
    },
  },

  actions: {
    ['setup']({ commit, dispatch }, scenarios: MockScenario[]) {
      commit('set:scenarios', scenarios);

      return dispatch('set:current');
    },

    async ['set:current']({ commit, state, getters }, scenarioName: 0 | string = state.current) {
      commit('set:current', scenarioName);

      if (getters.isProduction) return;

      let scenarios: HttpMock[] = getters.mocks;

      if (scenarios.length) {
        const { injectMocks } = await import('@/util/xhr-mock');

        injectMocks(scenarios, {
          allowXHRPassthrough: true,
        });
      }
    },
  },
} as Module<MockState, any>;
