























import Vue from 'vue';

import TableFilters from '@/containers/TableFilters';

import { ParamSettings } from '@/types';

export default Vue.extend({
  props: {
    filters: {
      type: Function,
    },

    resource: {
      type: String,
    },

    settings: {
      type: String,
    },

    dictionaries: {
      type: [String, Array],
    },

    scope: {
      type: Object as () => ParamSettings,
      default: () => ({} as ParamSettings),
    },

    showColumns: {
      type: Boolean,
      default: true,
    },

    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    TableFilters,
  },

  computed: {
    limit(): Record<string, any> {
      return this.scope.limit || {};
    },

    includedFilters(): Record<string, boolean> {
      const filters = Object.values(this.scope.filters!).filter(
        key =>
          (!this.scope.include?.length || this.scope.include?.includes(key)) &&
          !this.scope.exclude?.includes(key),
      );

      return filters.reduce(
        (params, key) => ({
          ...params,
          [key]: true,
        }),
        {},
      );
    },

    tableSettings(): string {
      return this.settings || `${this.resource}/settings`;
    },
  },
});
