export function sanitise<T extends Record<string, any>>(payload: T, depth: number = 0): Partial<T> {
  return Object.keys(payload).reduce((obj, key) => {
    // TODO: please Michiel take a look if that breaks anything, we want empty fields to be send as nulls...
    //if (payload[key] === null) return obj;

    if (typeof payload[key] === 'object' && !(payload[key] instanceof Array) && depth > 0) {
      return { ...obj, [key]: sanitise(payload[key], depth - 1) };
    }

    return { ...obj, [key]: payload[key] };
  }, {} as Partial<T>);
}
