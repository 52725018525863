import { FunctionalComponentOptions } from 'vue/types';

type Props = {
  value: number;
};

export default (
  digits: number = 0,
  locale: string = 'nl-NL',
): FunctionalComponentOptions<Props> => ({
  functional: true,
  render(createElement, { props }) {
    let text = '';

    if (typeof props.value === 'number') {
      text = (props.value / 100).toLocaleString(locale, {
        style: 'percent',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    }

    return createElement('span', text);
  },
});
