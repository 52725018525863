












import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex/types';

export default Vue.extend({
  props: {
    documentType: Array as PropType<string[][]>,
  },
  data() {
    return {
      documents: this.$t('office.dictionaries.documentTypes'),
    };
  },
  computed: {
    selected: {
      get(): string[][] {
        return this.documentType;
      },
      set(val: string[][]) {
        this.$emit('update:documentType', val);
      },
    },
  },
});
