import Vue from 'vue';
import App from './App.vue';

import '@/plugins/chartist';
import '@/plugins/vee-validate';
import '@/plugins/moment';
import '@/plugins/clone';
import '@/plugins/roles-permissions';
import '@/plugins/flags';
import '@/plugins/clipboard';
import '@/plugins/debounce';
import vuetify from '@/plugins/vuetify';
import i18n from './i18n';
import '@/global.components';
import './globalDirectives';
import routerAuth from '@/router.auth';
import './router-sync';
import '@/assets/scss/main.scss';

import router from './routes/routes';
import store from './store';

import mocks from './static/mock';

routerAuth(router, store);

async function start() {
  await fetch('/settings.json')
    .then(res => res.json())
    .then(data =>
      store.commit('inject-axios-variables', {
        baseUrl: data['VUE_APP_API'],
        wocozoneUrl: data['VUE_APP_WOCOZONE_FRONTEND'],
      }),
    );

  try {
    await store.dispatch('created');
    await store.dispatch('mock/setup', mocks);
  } finally {
    /* eslint-disable no-new */
    let app = new Vue({
      el: '#app',
      vuetify: vuetify(i18n),
      i18n,
      render: h => h(App),
      router,
      store,
    });

    if (window.Cypress) {
      window.app = app;
    }
  }
}

start();
