import Vue, { PropType } from 'vue';
import { VNode } from 'vue/types/umd';

type RouteFactory = (item: any) => { url: string; title: string };

export default (factory: RouteFactory) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    computed: {
      url(): string {
        return factory(this.item)?.url;
      },

      title(): string {
        return factory(this.item)?.title;
      },
    },

    render(h): VNode {
      const link = h(
        'router-link',
        {
          props: { to: this.url },
          nativeOn: {
            click: function(e: Event) {
              e.stopPropagation();
            },
          },
        },
        this.title,
      );

      return link;
    },
  });
