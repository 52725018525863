import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.ID',
    title: 'office.tables.headers.id',
    align: 'left',
    sortable: false,
    value: 'id',
    width: 50,
  },
  { value: 'housingCooperativeName', text: `office.tables.headers.housingCooperative` },
  { value: 'year', text: `office.tables.headers.year`, width: 150 },
  { value: 'target', text: `office.tables.headers.target`, width: 150 },
] as Column[];
