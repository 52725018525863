


























import { DynamicDocumentItemType, DynamicDocumentItemValue } from '@/types.dynamic-document';
import ImageGrid from '@/components/image-grid';
import Vue, { PropType } from 'vue';

type MultipleChoice = {
  count: number;
  answer: string;
};

export default Vue.extend({
  components: {
    ImageGrid,
  },

  props: {
    type: {
      type: String as PropType<DynamicDocumentItemType>,
      default: 'TEXT',
    },
    items: {
      type: Array as PropType<DynamicDocumentItemValue[]>,
      default: () => [],
    },
    unanswered: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    trueCount(): number {
      return this.items.filter(i => i === true).length;
    },

    falseCount(): number {
      return this.items.filter(i => i === false).length;
    },

    comments(): string[] {
      return this.items.filter(i => typeof i === 'string' && !!i) as string[];
    },

    images(): string[] {
      return this.items.flatMap(i => {
        if (i instanceof Array && i.length) return i as string[];

        return [];
      });
    },

    signatures(): string[] {
      return this.items.flatMap(i => {
        if (i instanceof Array && i.length && typeof i[1] === 'string') return [i[1]];

        return [];
      });
    },

    choices(): MultipleChoice[] {
      const answers: string[] = this.items.flatMap(i => {
        if (i instanceof Array && i.length) return i as string[];

        return [];
      });

      const uniq: Record<string, number> = answers.reduce((unique, val) => {
        if (unique[val]) {
          unique[val] += 1;
        } else {
          unique[val] = 1;
        }

        return unique;
      }, {} as Record<string, number>);

      return Object.keys(uniq).map(key => ({ answer: key, count: uniq[key] } as MultipleChoice));
    },
  },
});
