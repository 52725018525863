import { FetchParams } from '@/types';
import { EndpointStrategy, Endpoint } from './base';
import { AsyncModule } from 'vuex-async-mutations';

export const module = (endpoint: string | EndpointStrategy) =>
  ({
    actionsAsync: {
      ['export:selection']: {
        async handler(
          context,
          { selectedIds, relationId }: { selectedIds: number[] | string[]; relationId?: string },
        ) {
          let url: Endpoint;

          if (typeof endpoint !== 'string') {
            url = endpoint(relationId);
          } else {
            url = { endpoint };
          }

          return this.$axios.get(`${url.endpoint}`, {
            params: {
              selectedIds,
            },
          });
        },
      },

      ['export:all']: {
        async handler({ getters }) {
          const { relationId, query, params } = getters['lastFetch'] as FetchParams;

          let url: Endpoint;

          if (typeof endpoint !== 'string') {
            url = endpoint(relationId);
          } else {
            url = { endpoint };
          }

          return this.$axios.get(`${url.endpoint}`, {
            params: {
              query: query === null || query === '' ? undefined : query,
              ...params,
              ...url.params,
            },
          });
        },
      },
    },
  } as AsyncModule<any, any>);

export default module;
