import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.pchn',
    title: 'office.tables.headers.pchn',
    align: 'left',
    sortable: true,
    value: 'pchn',
  },

  {
    text: 'office.tables.headers.address',
    align: 'left',
    sortable: false,
    value: 'address',
  },

  {
    text: 'office.tables.headers.city',
    align: 'left',
    sortable: true,
    value: 'city',
  },

  {
    text: 'office.tables.headers.installer',
    align: 'left',
    sortable: true,
    value: 'installerName',
  },

  {
    text: 'office.tables.headers.housingCooperative',
    align: 'left',
    sortable: true,
    value: 'housingCooperativeName',
  },

  {
    text: 'office.labels.breakerStatus',
    sortable: false,
    align: 'center',
    value: 'breakerStatus',
  },

  {
    text: 'office.labels.dictionaries.projectStates',
    sortable: true,
    value: 'projectState',
  },

  { value: 'buildingType', text: `office.tables.headers.buildingType`, sortable: true },

  {
    text: 'office.tables.headers.CRM',
    title: 'office.tables.headers.linkToCRM',
    sortable: false,
    value: 'crmUrl',
    width: 60,
    align: 'center',
  },

  {
    text: 'office.tables.headers.panelsState',
    value: 'panelProcessStateAndDocumentation',
    hint: 'office.tables.hints.panelStateAndDocument',
    sortable: false,
  },

  {
    text: 'office.tables.headers.meterState',
    value: 'meterProcessStateAndDocumentation',
    hint: 'office.tables.hints.meterStateAndDocument',
    sortable: false,
    align: 'center',
  },

  {
    text: 'office.tables.headers.mechanicsApp',
    sortable: false,
    value: 'mechUrl',
    hint: 'office.tables.hints.mechanicsApp',
    width: 60,
    align: 'center',
  },

  {
    text: 'office.tables.headers.datePlanned',
    sortable: true,
    value: 'planned',
  },

  {
    text: 'office.tables.headers.dateMeterRegistered',
    sortable: true,
    value: 'registered',
  },

  {
    text: 'office.tables.headers.dateApproved',
    sortable: true,
    value: 'approved',
  },

  {
    text: 'office.tables.headers.meterInstalled',
    sortable: true,
    value: 'meterInstalled',
  },

  {
    text: 'office.tables.headers.panelsInstalled',
    sortable: true,
    value: 'panelsInstalled',
  },

  {
    text: 'office.tables.headers.panelOrientation',
    sortable: false,
    value: 'panelOrientation',
  },

  {
    text: 'office.tables.headers.issues',
    align: 'left',
    value: 'issues',
  },

  {
    text: 'office.tables.headers.inverters',
    align: 'left',
    sortable: false,
    value: 'inverters',
  },

  {
    text: 'office.tables.headers.housingCooperativeNorm',
    align: 'center',
    value: 'housingCooperativeNorm',
  },

  {
    text: 'office.tables.headers.panelsLocation',
    align: 'left',
    value: 'panelsLocation',
  },

  {
    text: 'office.tables.headers.roofType',
    align: 'left',
    value: 'roofType',
  },

  {
    text: 'office.tables.headers.orientation',
    align: 'center',
    value: 'orientation',
  },

  {
    text: 'office.tables.headers.roofInclination',
    align: 'center',
    value: 'roofInclination',
  },

  {
    text: 'office.tables.headers.radiationFreedom',
    align: 'center',
    value: 'radiationFreedom',
  },
] as Column[];
