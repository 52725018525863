
















import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
});
