import moment from 'moment';

import FormEdit from './steps/FormEdit.vue';

import { AddDialogAttributes, InstallerCapacity } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';

type FormData = {
  item: InstallerCapacity;
  formValid: boolean;
};

export default WithSteps<FormData, AddDialogAttributes>({
  title: 'office.dialogs.installations.capacity.edit.title',

  maxWidth: 600,

  formData() {
    const now = moment();

    return {
      item: {
        installerId: +this.$store.state.route.params.id,
        capacity: 0,
        year: now.get('year'),
        week: this.$store.state.route.params.id ? 1 : now.get('week'),
      },

      formValid: false,
    };
  },

  steps: [
    {
      component: FormEdit,

      isValid() {
        return this.$data.formValid;
      },

      props() {
        return {
          item: this.$data.item,
          installerEditable: !this.$store.state.route.params.id,
        };
      },

      value: {
        get() {
          return this.$data.formValid;
        },

        set(isValid: boolean) {
          this.$data.formValid = isValid;
        },
      },

      labels: {
        action: 'dialogs.save',
      },

      async afterStep() {
        await this.$store.dispatch('planning/capacity/item:create', {
          item: this.$data.item,
        });

        this.$store.dispatch('planning/capacity/settings/search');
      },
    },
  ],
});
