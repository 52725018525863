import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import BatchProgress from '@/apps/office/containers/table/shared/dialogs/steps/BatchProgress.vue';

import SmartTable from '@/apps/office/containers/smart-table';
import { House, ActionDialogAttributes, Project, ExportBatch } from '@/types';

type FormData = {
  selectedHouses: House[];
  batch?: ExportBatch;
  project: Project;
};

export default WithSteps<FormData, ActionDialogAttributes<House>>({
  title: 'office.dialogs.houses.deleteContracts.title',

  formData() {
    const project: Project = this.$store.getters['projects/details'](
      this.$store.state.route.params.id,
    );

    return {
      selectedHouses: this.$attrs.items.slice(),
      project,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedHouses.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['pchn', 'address', 'city', 'tenantName', 'contractState', 'contractSentDate'],
          settings: 'houses/settings',
          itemKey: 'pchn',
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },
    },

    {
      component: Confirm,

      value: {
        get() {
          return this.$t('office.dialogs.houses.deleteContracts.steps.confirm', [
            `<span class="font-weight-bold">${this.$data.selectedHouses.length}</span>`,
          ]);
        },
      },

      labels: {
        action() {
          return this.$t('office.dialogs.houses.closeContractPhase.action', [
            this.$data.selectedHouses.length,
          ]).toString();
        },
      },

      async afterStep() {
        this.$data.batch = await this.$store.dispatch(
          'projects/regularHouses/bulk:deleteContracts',
          {
            projectId: this.$data.project.id,
            houses: this.$data.selectedHouses,
          },
        );
      },
    },

    {
      component: BatchProgress,

      value: {
        get() {
          return this.$data.batch;
        },

        set(val: ExportBatch) {
          this.$data.batch = val;
        },
      },

      props() {
        return {
          batch: this.$data.batch,
        };
      },

      labels: {
        action: 'dialogs.done',
      },

      isValid() {
        return !!this.$data.batch && !!this.$data.batch.finishedAt && !!this.$data.batch.progress;
      },

      afterStep() {
        const proposition = this.$store.state.route.name;
        return this.$store.dispatch(`projects/${proposition}/settings/search`);
      },
    },
  ],
});
