





































































import Vue, { PropType } from "vue";
import { Project } from "@/types";

import DatePicker from "@/components/date-picker";
import DateTimePicker from "@/components/date-time-picker";

export default Vue.extend({
  components: {
    DatePicker,
    DateTimePicker
  },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    autofocus: {
      type: String,
      default: ""
    }
  }
});
