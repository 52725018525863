import Vue, { PropType } from 'vue';

import { VIcon } from 'vuetify/lib';

type RouteFactory<T> = (item: T) => { breakerStatus?: Boolean | null };

export default <T = any>(factory: RouteFactory<T>) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>,
    },

    data() {
      const props = factory(this.item);

      return {
        breakerStatus: props.breakerStatus,
      };
    },

    components: {
      VIcon,
    },

    computed: {
      color() {
        switch (this.breakerStatus) {
          case true:
            return 'green';
          case false:
            return 'red';
          default:
            return 'grey';
        }
      },

      icon() {
        switch (this.breakerStatus) {
          case true:
            return 'mdi-toggle-switch';
          case false:
            return 'mdi-toggle-switch-off';
          default:
            return 'mdi-toggle-switch-off-outline';
        }
      },
    },

    template: `
      <v-icon x-large :color="color">
        {{ icon }}
      </v-icon>
    `,
  });
