import { AsyncModule } from 'vuex-async-mutations';

import meter from './meter';

import regularInstallation from './installationTypes/regular';
import monitoringonlyInstallation from './installationTypes/monitoringonly';
import powershareInstallation from './installationTypes/powershare';
import pvtInstallation from './installationTypes/pvt';
import powermagnetInstallation from './installationTypes/powermagnet';
import postalcoderoseInstallation from './installationTypes/postalcoderose';
import withoutprojects from './installationTypes/withoutprojects';

export default {
  namespaced: true,

  async: true,

  modules: {
    regularInstallation,
    monitoringonlyInstallation,
    powershareInstallation,
    pvtInstallation,
    powermagnetInstallation,
    postalcoderoseInstallation,
    withoutprojects,
    meter,
  },

  getters: {},

  actionsAsync: {},
} as AsyncModule<any, any>;
