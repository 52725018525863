import SideNav from '@/containers/side-nav';

export default SideNav({
  routes: [
    {
      title: 'office.navigation.planning.forecasting',
    },
    {
      to: `/planning/calendar`,
      title: 'office.navigation.planning.gantt',
      icon: 'mdi-calendar-text',
    },
    {
      to: `/planning/forecast`,
      title: 'office.navigation.planning.forecast',
      icon: 'mdi-chart-bar',
    },
    {
      to: '/planning/capaciteit',
      title: 'office.navigation.planning.weeklyCapacity',
      icon: 'mdi-calendar-clock',
    },
    {
      to: '/planning/wocotargets',
      title: 'office.navigation.planning.targets',
      icon: 'mdi-target',
    },
    {
      title: 'office.navigation.projects.houses.bulk',
    },
    {
      to: '/planning/capaciteit/vullen',
      title: 'office.navigation.planning.weeklyCapacityFill',
      icon: 'fa-fill',
    },
  ],
});
