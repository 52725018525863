




import { DynamicDocumentItem } from '@/types.dynamic-document';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    item: Object as PropType<DynamicDocumentItem>,
  },

  computed: {
    icon(): string {
      switch (this.item.type) {
        case 'TEXT':
          return 'far fa-comment-dots';
        case 'BOOL':
          return 'fa-check';
        case 'IMAGE':
          return 'far fa-images';
        case 'SIGNATURE':
          return 'fa-signature';
        case 'MULTIPLE_CHOICE':
          return 'fa-clipboard-list';
        default:
          return '';
      }
    },
  },
});
