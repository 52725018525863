import { RouteConfig, Route } from "vue-router";
import Filters from "@/apps/office/containers/table/projects/Filters.vue";

import Table from "@/apps/office/layouts/table.vue";
import ProjectDetails from "@/apps/office/pages/projects/_id/index.vue";

import ProjectDialogEdit from "@/apps/office/containers/table/projects/dialogs/Edit";
import ProjectDialogAdd from "@/apps/office/containers/table/projects/dialogs/Add";
import ProjectDialogRemove from "@/apps/office/containers/table/projects/dialogs/Remove";
import DialogExportAll from "@/apps/office/containers/table/projects/dialogs/ExportAll";
import DialogExportSelection from "@/apps/office/containers/table/projects/dialogs/ExportSelection";

import NavProjects from "@/apps/office/pages/projects/containers/Navigation";
import NavHouses from "@/apps/office/pages/projects/_id/containers/Navigation";

import {
  Project,
  TableViewProps,
  AuthorizedRoute,
  Breadcrumb,
  Proposition
} from "@/types";
import router from "../routes";
import propositions from "@/apps/office/static/project-propositions.json";

import filters from "@/apps/office/components/table/shared/items/filters";

const tableViewProps = (props: TableViewProps) => (
  route: Route
): TableViewProps => ({
  filters: Filters,
  ...props,
  scope: {
    ...props.scope,
    query: route.query,
    filters
  }
});

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: "/projecten",
      title: "office.navigation.root.projects",
      icon: "mdi-briefcase",
      permission: "CanViewInstallations"
    },
    ...path
  ];
}

const sharedActions = [
  {
    name: "add",
    component: ProjectDialogAdd,
    permission: "CanEditProjects",
    icon: "mdi-plus",
    label: "office.dialogs.toolbar.add"
  },

  {
    name: "edit",
    component: ProjectDialogEdit,
    permission: "CanEditProjects",
    requiresSelection: true,
    icon: "mdi-pencil",
    label: "office.dialogs.toolbar.edit"
  },

  {
    name: "remove",
    component: ProjectDialogRemove,
    permission: "CanEditProjects",
    requiresSelection: true,
    icon: "mdi-minus",
    label: "office.dialogs.toolbar.remove"
  },
  {
    name: "exportAll",
    component: DialogExportAll,
    requiresSelection: false,
    icon: "mdi-file-export-outline",
    label: "office.dialogs.toolbar.export"
  },
  {
    name: "exportSelection",
    component: DialogExportSelection,
    requiresSelection: true,
    icon: "mdi-table-arrow-right",
    label: "office.dialogs.toolbar.exportSelection"
  }
];

export default [
  {
    name: "allprojects",
    path: "/projecten/allprojects",
    components: { default: Table, navigation: NavProjects },
    meta: {
      authorize: ["CanViewProjects"],
      breadcrumb: breadcrumb([
        { title: "office.navigation.projects.allprojects" }
      ]),
      hasFilters: true,
      hasSideNav: true
    },
    props: {
      default: tableViewProps({
        scope: {},
        resource: "projects/allprojects",
        settings: "projects/allprojects/settings",
        dictionaries: "projects/dictionaries",
        actions: sharedActions,
        navigateTo(item: Project) {
          router.push(`/projecten/${item.id}`);
        }
      })
    }
  },
  ...propositions.map((item: Proposition, i: number) => ({
    name: item.project_name,
    path: `/projecten/${item.project_name}`,
    components: { default: Table, navigation: NavProjects },
    meta: {
      authorize: ["CanViewProjects"],
      breadcrumb: breadcrumb([
        { title: `office.navigation.projects.${item.project_name}` }
      ]),
      hasFilters: true,
      hasSideNav: true
    },
    props: {
      default: tableViewProps({
        scope: {
          limit: {
            [filters.INSTALLATION_TYPE]: [i]
          },
          readonly: {
            [filters.INSTALLATION_TYPE]: i
          },
          values: {
            [filters.INSTALLATION_TYPE]: i
          }
        },
        resource: `projects/${item.project_name}`,
        settings: `projects/${item.project_name}/settings`,
        dictionaries: "projects/dictionaries",
        actions: sharedActions,
        navigateTo(item: Project) {
          router.push(`/projecten/${item.id}`);
        }
      })
    }
  })),
  {
    path: "projecten/:id",
    meta: {
      authorize: ["CanViewProjects"],
      hasFilters: false,
      hasSideNav: true,
      breadcrumb: ((route, store) => {
        const project: Project = store.getters["projects/details"](
          route.params.id
        );

        return breadcrumb([
          {
            to: project
              ? `/projecten/${
                  propositions[project.installationType].project_name
                }`
              : "/projecten/regulier",
            title: project
              ? `office.navigation.projects.${
                  propositions[project.installationType].project_name
                }`
              : "Projecten",
            // icon: 'mdi-briefcase-clock',
            permission: "CanViewProjects"
          },
          {
            to: `/projecten/${route.params.id}`,
            title: project
              ? `${project?.region} (${project?.housingCooperativeName})`
              : route.params.id,
            custom: true,
            clipboard: project?.code
          }
        ]);
      }) as Breadcrumb
    },
    components: { default: ProjectDetails, navigation: NavHouses }
  }
] as RouteConfig[];
