import { Project, ActionDialogAttributes } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';
import Confirm from '@/apps/office/containers/table/shared/dialogs/steps/Confirm.vue';

type FormData = {
  selectedProjects: Project[];
  proposition: string;
};

export default WithSteps<FormData, ActionDialogAttributes<Project>>({
  title: 'office.dialogs.export.title',

  formData() {
    const proposition = this.$store.state.route.name;
    return {
      selectedProjects: this.$attrs.items.slice(),
      proposition: proposition,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedProjects.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: [
            'pchn',
            'address',
            'housingCooperativeName',
            'projectCode',
            'buildingType',
            'installationType',
          ],
          settings: `installations/${this.$data.proposition}/settings`,
          showSelect: true,
        };
      },

      value: {
        get() {
          return this.$data.selectedProjects;
        },

        set(items: Project[]) {
          this.$data.selectedProjects = items;
        },
      },
    },
    {
      component: Confirm,

      value: {
        get() {
          return this.$t('office.dialogs.export.message', [
            `<span class="font-weight-bold">${this.$data.selectedProjects.length}</span>`,
            `<span class="font-weight-bold">${this.$store.state.profile.user.email}</span>`,
          ]);
        },
      },

      labels: {
        action: 'office.dialogs.export.action',
      },

      async afterStep() {
        await this.$store.dispatch(`installations/${this.$data.proposition}/export:selection`, {
          selectedIds: this.$data.selectedProjects.map(item => item.id),
          relationId: this.$attrs.relationId,
        });
      },
    },
  ],
});
