




































































import Vue from 'vue';
import _ from 'underscore';
import { ListItem, Project } from '@/types';

export default Vue.extend({
  props: {
    filters: {
      type: Object as () => Record<string, any>,
    },

    params: {
      type: Object as () => Record<string, any>,
    },

    readonly: {
      type: Object as () => Record<string, true>,
    },

    limit: {
      type: Object as () => Record<string, any>,
    },

    setParam: {
      type: Function,
    },
  },

  computed: {
    project(): Project {
      return this.$store.getters['projects/details'](this.$router.currentRoute.params.id);
    },
    installationType(): number {
      return this.project?.installationType;
    },
    projectStates(): ListItem[] {
      let states: ListItem[] =
        this.$store.state.route.name != 'monitoringonlyHouses'
          ? this.$t('office.dictionaries.projectStates')
          : (this.$t('office.dictionaries.moProjectStates') as any);

      if (!this.limit.projectStates) return states;

      return states
        .filter(state => this.limit.projectStates.includes(state.value))
        .map(state => ({
          ...state,
          disabled:
            this.params.projectStates.length === 1 &&
            this.params.projectStates.includes(state.value),
        }));
    },

    contractStates(): ListItem[] {
      const states: ListItem[] = this.$t('office.dictionaries.contractStates') as any;

      if (!this.limit.contractStates) return states;

      return states.filter(state => this.limit.contractStates.includes(state.value));
    },

    tags(): unknown[] {
      return _.sortBy(this.$store.state.dictionaries.tags.items, 'name');
    },
  },
});
