import { AsyncModule } from 'vuex-async-mutations';

import details from '@/store/base.details';
import paginated from '@/store/base.items.paginated';
import table from '@/store/modules/tables';
import itemsExport from '@/store/base.items.export';

import houseColumns from '@/apps/office/components/table/houses/other/columns';
import housePresets from '@/apps/office/components/table/houses/other/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

import { House, Project } from '@/types';

export const mod: AsyncModule<any, any> = {
  namespaced: true,

  async: true,

  modules: {
    details: details<House>('/houses', 'pchn'),
    items: paginated<House>(id => ({ endpoint: `/projects/${id!}/houses` }), 'pchn'),
    settings: table('projects/pvt/houses', housePresets, houseColumns, renderers),
    export: itemsExport(id => ({ endpoint: `/projects/${id}/houses/export` })),
  },
};

export default mod;
