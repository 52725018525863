import { BlockHouse, HighriseBlock, House, StepDialogAttributes } from '@/types';

import ResourceTable from '@/apps/office/containers/resource-table';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';

type FormData = {
  selectedHouses: House[];
};

type FormAttrs = StepDialogAttributes & {
  block: HighriseBlock;
  houses: BlockHouse[];
  house: BlockHouse;
  index: number;
};

export default WithSteps<FormData, FormAttrs>({
  title: 'office.dialogs.highrise.assignHouses.title',

  formData() {
    return {
      selectedHouses: [],
    };
  },

  steps: [
    {
      component: ResourceTable,

      props() {
        return {
          columns: ['pchn', 'address', 'city', 'tenantName', 'contractState', 'projectState'],

          resource: 'projects/regularHouses',
          settings: 'projects/regularHouses/settings',
          showSelect: true,
          singleSelect: false,
          relationId: this.$attrs.relationId,
          showSearch: true,
          params: {},
          selectableFirst: true,
          isSelectable: (item: House) => !this.$attrs.houses.find(house => house.id === item.id),
          itemClass: (item: House) =>
            this.$attrs.houses.find(house => house.id === item.id) ? 'v-data-row--unavailable' : '',
        };
      },

      isValid() {
        return this.$data.selectedHouses.length >= 1;
      },

      labels: {
        action: 'office.dialogs.highrise.assignHouses.action',
      },

      value: {
        get() {
          return this.$data.selectedHouses;
        },

        set(items: House[]) {
          this.$data.selectedHouses = items;
        },
      },

      async afterStep() {
        this.$emit('commit', this.$data.selectedHouses);
      },
    },
  ],
});
