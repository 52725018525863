import { RouteConfig, Route } from 'vue-router';

import Table from '@/apps/office/layouts/table.vue';

import PlanningNavigation from '@/apps/office/pages/planning/containers/Navigation';
import TargetDialogEdit from '@/apps/office/containers/table/planning/targets/dialogs/Edit';
import TargetDialogAdd from '@/apps/office/containers/table/planning/targets/dialogs/Add';
import TargetDialogRemove from '@/apps/office/containers/table/planning/targets/dialogs/Remove';
import TargetFilters from '@/apps/office/containers/table/planning/targets/Filters.vue';

import { TableViewProps, AuthorizedRoute } from '@/types';

import filters from '@/apps/office/components/table/shared/items/filters';

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: '/planning',
      title: 'office.navigation.root.planning',
      icon: 'mdi-calendar-month',
      permission: 'CanViewInstallations',
    },
    ...path,
  ];
}

const tableViewProps = (props: TableViewProps) => (route: Route): TableViewProps => ({
  relationId: route.params.id,
  resource: 'planning/targets',
  settings: 'planning/targets/settings',
  dictionaries: 'planning/targets/dictionaries',
  filters: TargetFilters,
  ...props,
  scope: { ...props.scope, query: route.query, filters },
});

export default [
  {
    path: '/planning/wocotargets',
    components: { default: Table, navigation: PlanningNavigation },
    meta: {
      authorize: ['CanViewInstallations'],
      hasFilters: true,
      hasSideNav: true,
      breadcrumb: breadcrumb([{ title: 'office.navigation.planning.targets' }]),
    },
    props: {
      default: tableViewProps({
        actions: [
          {
            name: 'add',
            component: TargetDialogAdd,
            permission: 'CanEditInstallations',
            icon: 'mdi-plus',
            label: 'office.dialogs.toolbar.add',
          },

          {
            name: 'edit',
            component: TargetDialogEdit,
            permission: 'CanEditInstallations',
            requiresSelection: true,
            icon: 'mdi-pencil',
            label: 'office.dialogs.toolbar.edit',
          },

          {
            name: 'remove',
            component: TargetDialogRemove,
            permission: 'CanEditInstallations',
            requiresSelection: true,
            icon: 'mdi-minus',
            label: 'office.dialogs.toolbar.remove',
          },
        ],
      }),
    },
  },
] as RouteConfig[];
