


















































































































import Vue, { PropType } from "vue";
import { Project } from "@/types";

import _ from "underscore";

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: Object as PropType<Project>
    },
    rules: {
      type: Object,
      default: () => []
    },
    autofocus: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      accountManagerName: ""
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val && val.accountManagerName) {
          this.accountManagerName = val.accountManagerName;
        } else if (val && val.housingCooperativeId) {
          this.accountManagerName = this.getAccountManager(
            val.housingCooperativeId
          );
        } else {
          this.accountManagerName = "";
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    getAccountManager(id: number): string {
      const hoco = this.housingCooperatives.find(hoco => hoco.id === id);
      return hoco?.accountManagerName || "";
    }
  },

  computed: {
    projectManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.projectManagers.items,
        "name"
      );
    },
    housingCooperatives(): any[] {
      return _.sortBy(
        this.$store.state.dictionaries.housingCooperatives.items,
        "name"
      );
    },
    zonmaatUsers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.zonmaatUsers.items,
        "name"
      );
    },
    installers(): unknown[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, "name");
    }
  }
});
