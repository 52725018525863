import FormEdit from './steps/FormEdit.vue';

import { InstallerCapacity, EditDialogAttributes } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';

type FormData = {
  item: InstallerCapacity;
  formValid: boolean;
};

export default WithSteps<FormData, EditDialogAttributes<InstallerCapacity>>({
  title: 'office.dialogs.installations.capacity.edit.title',

  maxWidth: 600,

  formData() {
    return {
      item: this.$attrs.item,

      formValid: false,
    };
  },

  steps: [
    {
      component: FormEdit,

      isValid() {
        return this.$data.formValid;
      },

      props() {
        return {
          item: this.$data.item,
          installerEditable: !this.$store.state.route.params.id,
        };
      },

      value: {
        get() {
          return this.$data.formValid;
        },

        set(isValid: boolean) {
          this.$data.formValid = isValid;
        },
      },

      labels: {
        action: 'dialogs.save',
      },

      async afterStep() {
        await this.$store.dispatch('planning/capacity/item:update', this.$data.item);

        this.$store.dispatch('planning/capacity/settings/search');
      },
    },
  ],
});
