









































































import { Column, Project } from "@/types";
import Vue, { PropType } from "vue";
import TableList from "@/components/table-list";
import DialogFinishProject from "@/apps/office/containers/table/projects/dialogs/FinishProject";

import columns from "@/apps/office/components/table/projects/allprojects/columns";
import columnsMO from "@/apps/office/components/table/projects/monitoring-only/columns";
import renderers from "@/apps/office/components/table/shared/items/renderers";

export default Vue.extend({
  components: {
    TableList,
    DialogFinishProject
  },

  props: {
    project: Object as PropType<Project>
  },

  data() {
    return {
      showDialog: false,
      renderers
    };
  },

  computed: {
    columns(): Column[] {
      if (this.project.installationType === 2) return columnsMO;

      return columns;
    },

    editable(): string[] {
      if (this.project.status === 1) {
        return [
          "active.conversion",
          "active.expectedInstallations",
          "active.installationStartAndEndDate",
          "active.planningStartAndEndDate",
          "active.conversionStartAndEndDate",
          "active.screeningStartAndEndDate"
        ];
      }

      return [];
    },

    values(): string[] {
      if (this.project.status <= 1 && this.project.installationType === 2) {
        return [
          "housesCount",
          "active.conversion",
          "active.expectedInstallations",
          "notConvertedYet",
          "expectedWorkQueue",
          "moWorkQueue",
          "moInstallationsCount",
          "actualConversion"
        ];
      }
      if (this.project.status <= 1) {
        return [
          "housesCount",
          "active.conversion",
          "active.expectedInstallations",
          "notConvertedYet",
          "expectedWorkQueue",
          "workQueue",
          "installationsCount"
        ];
      }

      return ["housesCountFinal", "conversion", "active.expectedInstallations"];
    },

    missingValues(): string[] {
      const allowedStates =
        this.project.installationType === 2
          ? ["M250"]
          : ["P250", "P297", "P298", "P299"];

      return this.project.progress
        .filter(item => !allowedStates.includes(item.state) && item.count > 0)
        .map(item => item.state);
    },

    isValid(): boolean {
      return !this.missingValues.length || this.project.type === 5;
    }
  }
});
