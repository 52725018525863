


































import { BlockHouse, HouseSize, Nullable, House, MeterStatuses } from '@/types';
import Vue, { PropType } from 'vue';
import draggable from 'vuedraggable';

import BlockHouseC from './BlockHouse.vue';

type DragMoveEvent = {
  draggedContext: {
    element: BlockHouse;
    futureIndex: number;
    index: number;
  };

  relatedContext: {
    element: BlockHouse;
    index: number;
    list: BlockHouse[];
  };
};

export default Vue.extend({
  components: {
    BlockHouse: BlockHouseC,
    draggable,
  },

  props: {
    size: String as PropType<HouseSize>,
    rows: Number,
    columns: Number,
    houses: Array as PropType<BlockHouse[]>,
    loading: Boolean,
    editMode: Boolean,
  },

  data() {
    return {
      isVisible: false,
      interval: null as Nullable<number>,
      cols: [] as BlockHouse[][][],
      dragEvent: null as Nullable<DragMoveEvent>,
      meterStatuses: null as Nullable<MeterStatuses>,
    };
  },

  async mounted() {
    await this.getMeterStatuses();
  },

  computed: {
    column() {
      return (column: number): BlockHouse[][] => {
        return new Array(this.rows)
          .fill(null)
          .map((d, row) => {
            const house = this.houses.find(h => h.column === column && h.row === row);

            if (house) return [house];

            return [{ column, row }];
          })
          .reverse();
      };
    },
    powershareNames(): string[] {
      return this.allHouseDetails
        .map(house => house?.powershareName ?? null)
        .filter((element, index, array) => array.indexOf(element) === index && element != null);
    },
    allHouseDetails(): House[] {
      return this.houses
        .map(house => {
          return this.$store.getters['projects/regularHouses/details'](house.pchn) as House;
        })
        .filter(house => !!house);
    },
  },

  watch: {
    rows(): void {
      this.createGrid();
    },

    columns(): void {
      this.createGrid();
    },

    houses: {
      immediate: true,
      handler() {
        this.createGrid();
        this.getMeterStatuses();
      },
    },
  },

  methods: {
    async getMeterStatuses() {
      const houses = this.houses.map(house => house.pchn);
      if (houses.length === 0) {
        return;
      }
      const result: MeterStatuses = await this.$store.dispatch(
        'installations/meter/fetch:meterStatus',
        {
          projectId: this.$router.currentRoute.params.id,
          pchns: houses,
        },
      );
      this.meterStatuses = result;
    },
    updateHouses() {
      this.$nextTick(() => {
        const houses: BlockHouse[] = this.cols
          .flatMap(column => column.flatMap(col => col[0]))
          .filter(house => !!house.id);

        this.$emit('update:houses', houses);
      });
    },

    onChange() {
      if (!this.dragEvent) {
        this.updateHouses();
        return;
      }

      const [target, source] = this.dragEvent!.relatedContext.list;

      target.column = source.column;
      target.row = source.row;

      this.createGrid();
    },

    onMoveCallback(evt: DragMoveEvent) {
      this.dragEvent = evt;

      if (!!evt.draggedContext.element.pchn && !evt.relatedContext.element.pchn) return -1;

      return false;
    },

    createGrid() {
      this.cols = new Array(this.columns).fill(null).map((d, column) => this.column(column));
    },
  },
});
