import Vue from 'vue';

function install(Vue: any) {
  Vue.prototype.$permission = function(this: Vue, requiredPermission?: string | string[]) {
    if (requiredPermission instanceof Array)
      return Vue.prototype.$permissions.call(this, requiredPermission);

    if (!requiredPermission) return true;

    const permissions: string[] = this.$store.getters['profile/permissions'] || [];
    const isAuthenticated: boolean = this.$store.getters['account/isAuthenticated'];

    return isAuthenticated && permissions.includes(requiredPermission);
  };

  Vue.prototype.$permissions = function(this: Vue, requiredPermissions?: string[]) {
    if (!requiredPermissions?.length) return true;

    const permissions: string[] = this.$store.getters['profile/permissions'] || [];
    const isAuthenticated: boolean = this.$store.getters['account/isAuthenticated'];

    return (
      isAuthenticated && permissions.some(permission => requiredPermissions.includes(permission))
    );
  };

  Vue.prototype.$role = function(this: Vue, requiredRole?: string) {
    if (!requiredRole) return true;

    const roles: string[] = this.$store.getters['profile/roles'] || [];
    const isAuthenticated: boolean = this.$store.getters['account/isAuthenticated'];

    return isAuthenticated && roles.includes(requiredRole);
  };

  Vue.prototype.$roles = function(this: Vue, requiredRoles?: string[]) {
    if (!requiredRoles?.length) return true;

    const roles: string[] = this.$store.getters['profile/roles'] || [];
    const isAuthenticated: boolean = this.$store.getters['account/isAuthenticated'];

    return isAuthenticated && roles.some(role => requiredRoles.includes(role));
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $role: (role: string) => boolean;
    $roles: (roles: string[]) => boolean;
    $permission: (permission?: string | string[]) => boolean;
    $permissions: (permissions?: string[]) => boolean;
  }
}

Vue.use({ install });
