



















































































import Vue from 'vue';
import { TableAction } from '@/types';

export default Vue.extend({
  props: {
    activeFilters: {
      type: Number,
      default: 0,
    },

    selected: {
      type: Array as () => any[],
      default: () => [],
    },

    currentPage: {
      type: Number,
      default: 1,
    },

    numberOfPages: {
      type: Number,
      default: 1,
    },

    numberOfItems: {
      type: Number,
      default: 0,
    },

    showFilters: {
      type: Boolean,
      default: true,
    },

    actions: {
      type: Array as () => TableAction[],
      default: () => [],
    },
  },

  computed: {
    allowedActions(): TableAction[] {
      return this.actions.filter(
        action => this.$permission(action.permission) && action.name !== 'edit',
      );
    },

    totalVisible(): number {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 25;
      }

      if (this.$vuetify.breakpoint.lgOnly) {
        return 20;
      }

      if (this.$vuetify.breakpoint.mdOnly) {
        return 10;
      }

      return 5;
    },
  },
});
