import { Module } from "vuex/types";

import resource from "../resource";

export default {
  namespaced: true,

  modules: {
    installers: resource("/dictionary/installers"),
    housingCooperatives: resource("/dictionary/housingCooperatives"),
    projectManagers: resource("/dictionary/projectManagers"),
    accountManagers: resource("/dictionary/accountManagers"),
    tags: resource("/dictionary/tags"),
    panels: resource("/dictionary/panels"),
    inverters: resource("/dictionary/inverters"),
    inverterConfigurations: resource("/dictionary/inverterConfigurations"),
    zonmaatUsers: resource("/dictionary/zonmaat/office"),
    zonmaatProjectManagers: resource("/dictionary/zonmaat/projectManagers"),
    zonmaatPlanners: resource("/dictionary/zonmaat/planners"),
    zonmaatForemans: resource("/dictionary/zonmaat/foremans")
  },

  actions: {
    ["fetch"]({ dispatch }) {
      dispatch("installers/fetch");
      dispatch("housingCooperatives/fetch");
      dispatch("projectManagers/fetch");
      dispatch("accountManagers/fetch");
      dispatch("tags/fetch");
      dispatch("panels/fetch");
      dispatch("inverters/fetch");
      dispatch("zonmaatUsers/fetch");
      dispatch("zonmaatProjectManagers/fetch");
      dispatch("zonmaatPlanners/fetch");
      dispatch("zonmaatForemans/fetch");
    }
  }
} as Module<any, any>;
