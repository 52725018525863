
































import Vue, { PropType } from "vue";
import { Project } from "@/types";

import TableList from "@/components/table-list";

import columns from "@/apps/office/components/table/projects/allprojects/columns";
import renderers from "@/apps/office/components/table/shared/items/renderers";

export default Vue.extend({
  components: {
    TableList
  },

  data() {
    return {
      columns,
      renderers
    };
  },

  props: {
    project: {
      type: Object as PropType<Project>
    },
    values: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    title: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  }
});
