import SideNav from '@/containers/side-nav';

export default SideNav({
  routes: [
    {
      title: 'office.navigation.main',
    },
    {
      to: '/installaties/withoutprojects',
      title: 'office.navigation.installations.withoutprojects',
      icon: 'mdi-briefcase-off-outline',
    },
    {
      to: '/installaties/regular',
      title: 'office.navigation.installations.regular',
      icon: 'mdi-solar-panel',
    },
    {
      to: `/installaties/powershare`,
      title: 'office.navigation.projects.powershare',
      icon: 'mdi-domain',
    },
    {
      to: `/installaties/monitoringonly`,
      title: 'office.navigation.projects.monitoringonly',
      icon: 'fa-digital-tachograph',
    },
    {
      to: `/installaties/pvt`,
      title: 'office.navigation.projects.pvt',
      icon: 'mdi-water-boiler',
    },
    {
      to: `/installaties/powermagnet`,
      title: 'office.navigation.projects.powermagnet',
      icon: 'mdi-magnet-on',
    },
    {
      to: `/installaties/postalcoderose`,
      title: 'office.navigation.projects.postalcoderose',
      icon: 'mdi-hexagon-multiple-outline',
    },
  ],
});
