


















































import Vue from 'vue';
import {
  BlockHouse,
  HighriseBlock,
  House,
  HouseSize,
  Nullable,
  Pagination,
  ProjectExtra,
} from '@/types';

import Block from './Block.vue';
import BlockEditor from './BlockEditor.vue';
import blocks from '@/apps/office/static/mock/scenarios/data/blocks.json';

export default Vue.extend({
  components: {
    BlockEditor,
  },

  props: {
    id: String,
  },

  data() {
    return {
      size: 'M' as HouseSize,
      loading: null as Nullable<number>,
      activeBlocks: [],
      empty: {
        rows: 1,
        columns: 1,
        name: '',
        houses: [],
      } as HighriseBlock,
    };
  },

  computed: {
    isPending(): boolean {
      return this.$store.getters['projects/regularHouses/isPending'];
    },

    blocksWithHouses(): HighriseBlock[] {
      return this.$store.getters['projects/highrise/blocks'](this.id);
    },

    houses(): BlockHouse[] {
      return this.blocksWithHouses.flatMap(block => block.houses);
    },
  },

  methods: {
    removeBlock(index: number) {
      this.$store.dispatch('projects/highrise/remove:block', { id: this.id, index });
    },

    updateBlock(index: number, block: HighriseBlock) {
      this.$store.dispatch('projects/highrise/edit:block', { id: this.id, index, block });
    },

    addBlock() {
      this.$store.dispatch('projects/highrise/add:block', { id: this.id, block: this.empty });
      this.empty = { rows: 1, columns: 1, name: '', houses: [] };
    },

    async fetchStatus(houses: BlockHouse[]) {
      await this.$store.dispatch('projects/regularHouses/fetch:selected', {
        relationId: this.id,
        selectedIds: houses.map(house => house.id).filter(id => !!id || id === 0),
      });
    },
  },
});
