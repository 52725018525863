




















































































import Vue, { PropType } from "vue";
import { Project, Nullable } from "@/types";
import moment from "moment";
import rules from "@/util/forms";

import ProcessDateInput from "@/components/process-date-input";
import AverageHoursInput from "@/components/average-hours-input/AverageHoursInput.vue";
import { EmployeeTypes } from "@/components/average-hours-input/helpers";

export default Vue.extend({
  components: {
    AverageHoursInput,
    ProcessDateInput
  },
  props: {
    value: {
      type: Object as PropType<Project>
    },
    autofocus: {
      type: String,
      default: ""
    },
    rules: {
      type: Object,
      default: () => []
    }
  },

  watch: {
    "value.installationStartAt": {
      handler(newVal: string | null, oldVal: string | undefined) {
        if (
          newVal !== oldVal &&
          newVal != null &&
          !this.value.planningStartAt &&
          !this.value.conversionStartAt &&
          !this.value.screeningStartAt
        ) {
          this.setInitialDate("planning", "installation", 4);
          this.setEndsAt("planning", 4);

          this.setInitialDate("conversion", "planning", 6);
          this.setEndsAt("conversion", 6);

          this.setInitialDate("screening", "conversion", 4);
          this.setEndsAt("screening", 4);
        }
      }
    },
    "value.installationDuration": {
      handler(newVal: string | null, oldVal: string | undefined) {
        if (
          newVal !== oldVal &&
          newVal != null &&
          !this.value.planningStartAt &&
          !this.value.conversionStartAt &&
          !this.value.screeningStartAt
        ) {
          this.setInitialDate("planning", "installation", 4);
          this.setEndsAt("planning", 4);

          this.setInitialDate("conversion", "planning", 6);
          this.setEndsAt("conversion", 6);

          this.setInitialDate("screening", "conversion", 4);
          this.setEndsAt("screening", 4);
        }
      }
    }
  },

  computed: {
    EmployeeTypes() {
      return EmployeeTypes;
    },
    processDateInputRules() {
      return {
        ...rules,
        dateExceeds: (type: string) => [
          (value: number | "" | undefined | null) =>
            value === "" ||
            value === undefined ||
            value === null ||
            this.dateExceeds(type) ||
            this.$t("validation.dateExceeds", [
              type,
              moment(this.possibleMaxDate(type)!).format("DD-MM-YYYY")
            ])
        ]
      };
    }
  },
  methods: {
    dateExceeds(type: string): boolean {
      const diff = moment(this.possibleMaxDate(type)!).diff(
        this.value[`${type}EndAt` as keyof Nullable<String>],
        "days"
      );

      return diff >= 1;
    },

    setInitialDate(type: string, otherType: string, weeks: number): void {
      const date: string = this.value[
        `${otherType}StartAt` as keyof Nullable<String>
      ];

      const subtractWeeks: string = moment(this.setDateToMonday(date))
        .subtract(weeks, "weeks")
        .format("YYYY-MM-DD");
      this.$set(this.value, `${type}StartAt`, subtractWeeks);
    },
    setEndsAt(type: string, weeks: Nullable<number>): void {
      if (weeks != 0 && !weeks) {
        this.$set(this.value, `${type}EndAt`, null);
        return;
      }

      if (
        this.value[`${type}StartAt` as keyof Nullable<String>] &&
        !this.value[`${type}Duration` as keyof Nullable<String>]
      ) {
        this.$set(this.value, `${type}Duration`, weeks);
      }

      if (this.value[`${type}StartAt` as keyof Nullable<String>]) {
        const startAtDate: string = this.value[
          `${type}StartAt` as keyof Nullable<String>
        ];
        const duration: string = this.value[
          `${type}Duration` as keyof Nullable<String>
        ];
        this.$set(
          this.value,
          `${type}EndAt`,
          moment(startAtDate)
            .add(duration || 0, "weeks")
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        );
      }
    },
    endsAtFormatted(type: string): string {
      const startAtDate: string = this.value[
        `${type}EndAt` as keyof Nullable<String>
      ];

      if (startAtDate) {
        return moment(startAtDate).format("dddd, DD MMMM YYYY");
      }

      return "";
    },
    maxDate(endDate: Nullable<string>, weeks: Nullable<number>): null | string {
      if (!endDate || !weeks) return null;
      return moment(endDate)
        .subtract(weeks, "weeks")
        .format("YYYY-MM-DD");
    },

    possibleMinDate(type: string): Nullable<string> {
      switch (type) {
        case "screening":
          return null;
        case "conversion":
          return this.value?.screeningEndAt || this.value?.screeningStartedAt;
        case "planning":
          return (
            this.value?.conversionEndAt ||
            this.value?.conversionStartAt ||
            this.value?.screeningEndAt ||
            this.value?.screeningStartedAt
          );
        case "installation":
          return (
            this.value?.planningEndAt ||
            this.value?.planningStartAt ||
            this.value?.conversionEndAt ||
            this.value?.conversionStartAt ||
            this.value?.screeningEndAt ||
            this.value?.screeningStartedAt
          );
        default:
          return null;
      }
    },

    possibleMaxDate(type: string): Nullable<string> {
      switch (type) {
        case "screening":
          return (
            this.value?.conversionStartAt ||
            this.value?.planningStartAt ||
            this.value?.installationStartAt
          );
        case "conversion":
          return this.value?.planningStartAt || this.value?.installationStartAt;
        case "planning":
          return this.value?.installationStartAt;
        case "installation":
          return null;
        default:
          return null;
      }
    },

    setDateToMonday(date: string) {
      let weekNumber = moment(date).isoWeekday();

      if (weekNumber != 1) {
        date = moment(date)
          .subtract(weekNumber - 1, "days")
          .format("YYYY-MM-DD");
      }
      return date;
    }
  }
});
