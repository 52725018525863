























type Tag = {
  id: number;
  name: string;
};

import Vue from 'vue';
export default Vue.extend({
  props: {
    value: Array as () => Tag[],
  },
});
