










































































































































import Vue, { PropType } from 'vue';
import _ from 'underscore';
import {
  BlockHouse,
  HighriseBlock,
  House,
  HouseSize,
  Nullable,
  Pagination,
  ProjectExtra,
} from '@/types';

import Block from './Block.vue';
import AssignHouse from './dialogs/AssignHouse';
import blocks from '@/apps/office/static/mock/scenarios/data/blocks.json';

export default Vue.extend({
  components: {
    Block,
    AssignHouse,
  },

  props: {
    id: String,
    index: {
      type: Number,
      default: -1,
    },
    assignable: {
      type: Boolean,
      default: true,
    },
    size: String as PropType<HouseSize>,
    isActive: Boolean,
    forceEdit: Boolean,
    saveOnly: Boolean,
    block: Object as PropType<HighriseBlock>,
    blocks: Array as PropType<HighriseBlock[]>,
    currentHouses: Array as PropType<BlockHouse[]>,
    loading: Boolean,
  },

  data() {
    return {
      name: '',
      columns: 1,
      rows: 1,
      validation: [] as string[],
      interval: null as Nullable<number>,
      removeTime: null as Nullable<number>,
      removeInterval: null as Nullable<number>,
      editMode: false,
      showAssignDialog: false,
      houses: [] as BlockHouse[],
      activeHouse: null as Nullable<BlockHouse>,
    };
  },

  destroyed() {
    if (this.interval) window.clearInterval(this.interval);
  },

  computed: {
    minColumns(): number {
      return this.houses.reduce((min, house) => (house.column > min ? house.column : min), 0);
    },

    minRows(): number {
      return this.houses.reduce((min, house) => (house.row > min ? house.row : min), 0);
    },
  },

  methods: {
    edit() {
      this.editMode = true;
    },

    cancel() {
      this.editMode = this.forceEdit;
    },

    save() {
      this.block.name = this.name;
      this.block.rows = this.rows;
      this.block.columns = this.columns;
      this.block.houses = this.houses;
      this.$emit('save');
      this.editMode = this.forceEdit;
    },

    handleUnassign(house: BlockHouse) {
      const index = this.houses?.findIndex(h => h.column === house.column && h.row === house.row);

      if (index! >= 0) {
        this.houses?.splice(index!, 1);
      }
    },

    handleRemove() {
      this.removeTime = 10000;

      this.removeInterval = window.setInterval(() => {
        this.removeTime! -= 500;

        if (this.removeTime! <= 0) window.clearInterval(this.removeInterval!);
      }, 500);
    },

    remove() {
      this.$emit('remove');
    },

    assignHouses(houses: House[]) {
      let sortedHouses = _.sortBy(houses, 'pchn');
      const house = this.activeHouse!;

      for (let row = 0; row < houses.length; row++) {
        if (house.row + row >= this.rows) return;

        const cell: BlockHouse = {
          row: house.row + row,
          column: house.column,
          pchn: sortedHouses[row]?.pchn,
          id: sortedHouses[row]?.id,
        };

        const index = this.houses?.findIndex(h => h.column === cell.column && h.row === cell.row);

        if (index >= 0) {
          this.houses?.splice(index, 1, cell);
        } else {
          this.houses?.push(cell);
        }
      }
    },

    handleAssign(house: BlockHouse) {
      this.activeHouse = house;
      this.showAssignDialog = true;
    },

    fetchStatus() {
      this.$emit('fetch:status', this.editMode ? this.houses : this.block.houses);
    },

    handleUpdateHouses(houses: BlockHouse[]) {
      this.houses = houses;
    },

    checkName() {
      this.validation = [];

      const block = this.blocks.find(block => block.name === this.name);

      if (block && block !== this.block) {
        this.validation = [this.$t('office.validation.nameDuplicate').toString()];
      }
    },
  },

  watch: {
    rows(val: number) {
      if (val < this.minRows) {
        this.rows = this.minRows;
      }

      if (val > 100) {
        this.rows = 100;
      }
    },

    columns(val: number) {
      if (val < this.minColumns) {
        this.columns = this.minColumns;
      }

      if (val > 100) {
        this.columns = 100;
      }
    },

    forceEdit: {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          this.editMode = true;
        }
      },
    },

    editMode: {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          this.name = this.block.name;
          this.rows = this.block.rows;
          this.columns = this.block.columns;
          this.houses = this.$clone(this.block.houses);
        }
      },
    },

    block(block: HighriseBlock) {
      this.name = block.name;
      this.rows = block.rows;
      this.columns = block.columns;
    },

    isActive: {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          setTimeout(() => this.fetchStatus(), 1000);

          this.interval = window.setInterval(() => this.fetchStatus(), 60 * 1000);
        } else if (this.interval) window.clearInterval(this.interval);
      },
    },
  },
});
