import { ListItem } from '@/types';
import Vue from 'vue';

export default (i18nKey: string) =>
  Vue.extend({
    props: {
      value: [Number, String],
    },
    render(createElement) {
      const val: string | ListItem[] = this.$t(i18nKey) as any;

      let text;

      if (val instanceof Array) {
        text = val.find((item: any) => item.value === this.value)?.text || '';
      } else {
        text = val;
      }

      return createElement('span', text ?? i18nKey);
    },
  });
