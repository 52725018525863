import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

interface RequireContext {
  keys(): string[];
  (id: string): any;
  <T>(id: string): T;
  resolve(id: string): string;
  /** The module id of the context module. This may be useful for module.hot.accept. */
  id: string;
}

const messages: LocaleMessages = {
  en: {
    $vuetify: require('vuetify/lib/locale/en').default,
  },
  nl: {
    $vuetify: require('vuetify/lib/locale/nl').default,
  },
};

const $service = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: addLocaleMessages(require.context('../i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)),
});

export function addLocaleMessages(locales: RequireContext): LocaleMessages {
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...messages[locale], ...locales(key) };
    }
  });

  return messages;
}

export const $t = $service.t.bind($service);

export default $service;
