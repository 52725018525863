import { AsyncModule } from 'vuex-async-mutations';

import capacity from './capacity';
import forecast from './forecast';
import targets from './targets';
import calendar from './calendar';

export default {
  namespaced: true,
  async: true,
  modules: {
    capacity,
    forecast,
    targets,
    calendar,
  },
} as AsyncModule<any, any>;
