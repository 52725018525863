import { DynamicDocumentType } from '@/types';
import { DynamicDocumentGroup } from '@/types.dynamic-document';

import meter_maas from './document.meter.maas.json';
import meter_regular from './document.meter.regular.json';
import panels_regular from './document.panels.json';
import tenant from './document.tenant.json';

export default {
  'METER-REGULAR': meter_regular,
  'METER-REGULAR_MAAS': meter_maas,
  'PANELS-REGULAR': panels_regular,
  TENANT: tenant,
} as Record<DynamicDocumentType, DynamicDocumentGroup[]>;
