import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { RootState } from '@/types';

import axios from '@/store/plugins/axios';
import i18n from '@/store/plugins/i18n';
import vuei18n from '@/apps/office/i18n';

import dictionaries from '@/apps/office/store/modules/dictionaries';
import batches from '@/apps/office/store/modules/batches';
import projects from '@/apps/office/store/modules/projects';
import installations from '@/apps/office/store/modules/installations';
import planning from '@/apps/office/store/modules/planning';
import installers from '@/apps/office/store/modules/installers';
import houses from '@/apps/office/store/modules/houses';
import profile from '@/apps/office/store/modules/profile';
import deliveryDocuments from '@/apps/office/store/modules/delivery-documents';
import preferences from '@/apps/office/store/modules/preferences';
import projectProcess from '@/apps/office/store/modules/projectProcess';
import { plugin as asyncPlugin, wrapModules } from 'vuex-async-mutations';

import ui from '@/store/modules/ui';
import account from '@/store/modules/account';
import mock from '@/store/modules/mock';

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
    axiosVariables: {
      baseUrl: '',
      wocozoneUrl: '',
    },
  },
  modules: wrapModules({
    account,
    batches,
    dictionaries,
    deliveryDocuments,
    projects,
    installations,
    planning,
    installers,
    houses,
    mock,
    profile,
    projectProcess,
    preferences,
    ui,
  }),
  plugins: [
    i18n(vuei18n),
    asyncPlugin,
    axios(() => ({
      baseURL: store.state.axiosVariables.baseUrl,
      apiVersion: '1.0',
      token: store.getters['account/apiToken'],
      locale: 'nl',
    })),
    createPersistedState({
      key: 'helios.office',
      paths: [
        'ui',
        'mock',
        'account',
        'profile',
        'houses.settings',
        'projects.highrise',
        'installers.settings',
        'projects.regular.settings',
        'projects.powershare.settings',
        'projects.monitoringonly.settings',
        'projects.pvt.settings',
        'projects.powermagnet.settings',
        'projects.postalcoderose.settings',
        'projects.regularHouses.settings',
        'projects.powershareHouses.settings',
        'projects.monitoringonlyHouses.settings',
        'projects.pvtHouses.settings',
        'projects.powermagnetHouses.settings',
        'projects.postalcoderoseHouses.settings',
        'projects.forecast.settings',
        'installations.monitoringOnly.settings',
        'installations.regularInstallation.settings',
        'installations.monitoringonlyInstallation.settings',
        'installations.powershareInstallation.settings',
        'installations.pvtInstallation.settings',
        'installations.powermagnetInstallation.settings',
        'installations.postalcoderoseInstallation.settings',
        'installations.withoutprojects.settings',
        'installations.meter',
        'planning.capacity.settings',
        'planning.forecast.settings',
        'planning.targets.settings',
        'planning.calendar.settings',
      ],
    }),
  ],
  mutations: {
    ['inject-axios-variables'](state, { baseUrl, wocozoneUrl }) {
      state.axiosVariables.baseUrl = baseUrl;
      state.axiosVariables.wocozoneUrl = wocozoneUrl;
    },
  },
};

const store = new Store<RootState>(storeOptions);

export default store;
