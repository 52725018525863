import moment from 'moment';

import { ForecastOverview, WeekForecast } from '@/types';

function getYearWeek(items: WeekForecast[]): string[] {
  return items.map(item => {
    const date = moment()
      .set('year', item.year)
      .set('week', item.week)
      .set('weekday', 0);

    return date.format('YYYY-MM-DD');
  });
}

export function getForecastWeeks(forecast: ForecastOverview) {
  const weeks = [
    ...getYearWeek(forecast.planned),
    ...getYearWeek(forecast.expectedWorkQueue),
    ...getYearWeek(forecast.workQueue),
    ...getYearWeek(forecast.distributedWorkQueue),
    ...getYearWeek(forecast.finished),
  ]
    .filter((date, i, arr) => arr.indexOf(date) === i)
    .sort();

  const first = weeks.shift();
  const last = weeks.pop();
  let week = moment(first);

  const items: string[] = [first!];

  while (week.isBefore(moment(last))) {
    week = week.add(1, 'week');
    items.push(week.format('YYYY-MM-DD'));
  }

  return items;
}
