import { ActionDialogAttributes, TablePreset } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import PresetList from './PresetList.vue';
import { createDownload } from '@/util/file';

type FormData = {
  selected: TablePreset[];
};

export default WithSteps<FormData, ActionDialogAttributes<TablePreset>>({
  title: 'office.dialogs.export.title',

  width: 500,

  formData() {
    return {
      selected: [],
    };
  },

  steps: [
    {
      component: PresetList,

      isValid() {
        return this.$data.selected.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
        };
      },

      value: {
        get() {
          return this.$data.selected;
        },

        set(items: TablePreset[]) {
          this.$data.selected = items;
        },
      },

      async afterStep() {
        createDownload(
          `snelvoorkeuren-${this.$data.selected.map(item => item.name).join('-')}.json`,
          JSON.stringify(this.$data.selected),
        );
      },

      labels: {
        action: 'office.dialogs.export.action',
      },
    },
  ],
});
