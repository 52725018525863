import Agree from '../../shared/dialogs/steps/Agree.vue';

import { Project, EditDialogAttributes } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';

type FormData = {
  item: Project;
  agreedToTerms: boolean;
};

export default WithSteps<FormData, EditDialogAttributes<Project>>({
  title: 'office.dialogs.projects.activate.title',

  maxWidth: 500,

  formData() {
    return {
      item: this.$attrs.item,

      agreedToTerms: false,
    };
  },

  steps: [
    {
      component: Agree,

      isValid() {
        return this.$data.agreedToTerms;
      },

      props: {
        message: 'office.dialogs.projects.activate.message',
      },

      value: {
        get() {
          return this.$data.agreedToTerms;
        },

        set(isValid: boolean) {
          this.$data.agreedToTerms = isValid;
        },
      },

      labels: {
        action: 'office.dialogs.projects.activate.action',
      },

      async afterStep() {
        if (!this.$data.agreedToTerms) return;

        await this.$store.dispatch('projects/item:update', { ...this.$data.item, status: 1 });
        await this.$store.dispatch('projects/fetch', this.$data.item.id);
      },
    },
  ],
});
