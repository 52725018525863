import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import nl from 'vuetify/es5/locale/nl';

Vue.use(Vuetify);

const darkTheme = {
  primary: '#F7A611',
  // secondary: '#9C27b0',
  // accent: '#9C27b0',
  // info: '#00CAE3',
  ganttContainer: '#1E1E1E',
  ganttBlockColor: '#1E1E1E',
};

const lightTheme = {
  primary: '#F7A611',
  // secondary: '#9C27b0',
  // accent: '#9C27b0',
  // info: '#00CAE3',
  ganttContainer: 'rgba(189, 189, 189, 0.4)',
  ganttBlockColor: '#ffffff',
};

export default i18n =>
  new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params),
      locales: { nl },
      current: 'nl',
    },
    theme: {
      themes: {
        dark: darkTheme,
        light: lightTheme,
      },
    },
  });
