import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.ID',
    title: 'office.tables.headers.id',
    align: 'left',
    sortable: false,
    value: 'id',
    width: 50,
  },
  { value: 'installerName', text: `office.tables.headers.installer` },
  { value: 'week', text: `office.tables.headers.week`, width: 150 },
  { value: 'year', text: `office.tables.headers.year`, width: 150 },
  { value: 'capacity', text: `office.tables.headers.capacity`, width: 150 },
] as Column[];
