import { Project, ActionDialogAttributes } from '@/types';

import WithSteps from '@/apps/office/containers/table/shared/dialogs/with-steps';
import SmartTable from '@/apps/office/containers/smart-table';

type FormData = {
  selectedProjects: Project[];
  proposition: string;
};

export default WithSteps<FormData, ActionDialogAttributes<Project>>({
  title: 'office.dialogs.projects.remove.title',

  formData() {
    const proposition = this.$store.state.route.name;
    return {
      selectedProjects: this.$attrs.items.slice(),
      proposition: proposition,
    };
  },

  steps: [
    {
      component: SmartTable,

      isValid() {
        return this.$data.selectedProjects.length > 0;
      },

      props() {
        return {
          items: this.$attrs.items,
          columns: ['id', 'region', 'projectManagerName', 'code', 'year'],
          settings: `projects/${this.$data.proposition}/settings`,
          showSelect: true,
        };
      },

      labels: {
        action() {
          return this.$t('office.dialogs.projects.remove.action', [
            this.$data.selectedProjects.length,
          ]).toString();
        },
      },

      value: {
        get() {
          return this.$data.selectedProjects;
        },

        set(items: Project[]) {
          this.$data.selectedProjects = items;
        },
      },

      async afterStep() {
        for (let project of this.$data.selectedProjects) {
          await this.$store.dispatch('projects/item:delete', project);
        }

        this.$store.dispatch(`projects/${this.$data.proposition}/settings/search`);
      },
    },
  ],
});
