import { AsyncModule } from 'vuex-async-mutations';
import { Pagination } from '@/types';

type DictionaryState<T> = {
  items: T[];
};

const stateFactory = (): DictionaryState<any> => ({
  items: [],
});

export default <T>(endpoint: string): AsyncModule<DictionaryState<T>, any> => ({
  namespaced: true,
  async: true,
  state: stateFactory,

  actionsAsync: {
    ['fetch']: {
      handler({ commitAsync, state }) {
        if (state.items.length) {
          return Promise.resolve(state.items);
        }

        return commitAsync(this.$axios.get(endpoint, { params: { perPage: 1000 } }));
      },

      resolved(state, items: T[] | Pagination<T>) {
        if (items instanceof Array) {
          state.items = items;
        } else {
          state.items = items.data;
        }
      },
    },
  },
});
