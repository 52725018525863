import { AsyncModule } from "vuex-async-mutations";

import details from "@/store/base.details";
import paginated from "@/store/base.items.paginated";
import items from "@/store/base.items";
import crud from "@/store/base.crud";
import table from "@/store/modules/tables";
import highrise from "./highrise";
import { Project, Proposition } from "@/types";

// Projects
import regular from "./projects/regular";
import powershare from "./projects/powershare";
import monitoringonly from "./projects/monitoringonly";
import pvt from "./projects/pvt";
import powermagnet from "./projects/powermagnet";
import postalcoderose from "./projects/postalcoderose";
import allprojects from "./projects/allprojects";

// Houses
import regularHouses from "./houses/regular";
import powershareHouses from "./houses/powershare";
import monitoringonlyHouses from "./houses/monitoringonly";
import pvtHouses from "./houses/pvt";
import powermagnetHouses from "./houses/powermagnet";
import postalcoderoseHouses from "./houses/postalcoderose";

import columns from "@/apps/office/components/table/projects/allprojects/columns";
import presets from "@/apps/office/components/table/projects/allprojects/presets";
import renderers from "@/apps/office/components/table/shared/items/renderers";

import propositions from "@/apps/office/static/project-propositions.json";

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<Project>("/projects"),
    items: paginated<Project>("/projects"),
    crud: crud<Project>("/projects"),
    allprojects,
    regular,
    powershare,
    monitoringonly,
    pvt,
    powermagnet,
    postalcoderose,
    regularHouses,
    powershareHouses,
    monitoringonlyHouses,
    pvtHouses,
    powermagnetHouses,
    postalcoderoseHouses,
    forecast: {
      namespaced: true,
      modules: {
        settings: table("projects/forecast", presets, columns, renderers)
      }
    },
    dictionaries: {
      namespaced: true,

      actions: {
        ["fetch"]() {
          this.dispatch("dictionaries/installers/fetch");
          this.dispatch("dictionaries/housingCooperatives/fetch");
          this.dispatch("dictionaries/projectManagers/fetch");
          this.dispatch("dictionaries/accountManagers/fetch");
          this.dispatch("dictionaries/panels/fetch");
          this.dispatch("dictionaries/inverterConfigurations/fetch");
          this.dispatch("dictionaries/zonmaatUsers/fetch");
          this.dispatch("dictionaries/zonmaatProjectManagers/fetch");
          this.dispatch("dictionaries/zonmaatPlanners/fetch");
          this.dispatch("dictionaries/zonmaatForemans/fetch");
        }
      }
    },
    highrise,
    progress: {
      namespaced: true,
      modules: {
        project: items(id => ({ endpoint: `/projects/${id}/progress` })),
        installation: items(id => ({
          endpoint: `/projects/${id}/progress/installations`
        })),
        contract: items(id => ({
          endpoint: `/projects/${id}/progress/contracts`
        })),
        planning: items(id => ({
          endpoint: `/projects/${id}/progress/planning`
        })),
        postInstallation: items(id => ({
          endpoint: `/projects/${id}/progress/post-installation`
        }))
      }
    },
    issues: {
      namespaced: true,
      modules: {
        postInstallation: items(id => ({
          endpoint: `/projects/${id}/issues/post-installation`
        }))
      }
    }
  },

  getters: {
    ["export:all:url"]() {
      return () => "/api/projects/projects/export";
    },
    ["currentProposition"]() {
      return (proposition: string) =>
        propositions.findIndex(
          (prop: Proposition) => prop.project_name === proposition
        );
    }
  },

  actionsAsync: {
    ["addHouses"](context, { pchn, id }: { pchn: string[]; id: string }) {
      return this.$axios.post(`/projects/${id}/attach-houses`, {
        pchn
      });
    },

    ["removeHouse"](context, { houseId, id }: { houseId: string; id: string }) {
      return this.$axios.delete(`/projects/${id}/detach-houses/${houseId}`);
    }
  }
} as AsyncModule<any, any>;
