import { RouteConfig } from 'vue-router';

import DeliveryDocuments from '@/apps/office/containers/delivery-documents';
import InstallersNavigation from '@/apps/office/pages/installations/containers/Navigation';

import { AuthorizedRoute } from '@/types';

function breadcrumb(path: AuthorizedRoute[] = []) {
  return [
    {
      to: '/installaties',
      title: 'office.navigation.root.installations',
      icon: 'mdi-document',
    },
    ...path,
  ];
}

export default [
  {
    path: '/installaties/opleverdocumenten',
    components: { default: DeliveryDocuments, navigation: InstallersNavigation },
    meta: {
      breadcrumb: breadcrumb([{ title: 'office.navigation.installations.deliveryDocuments' }]),
      hasFilters: false,
      hasSideNav: true,
    },
    props: {},
  },
] as RouteConfig[];
