import { AsyncModule } from 'vuex-async-mutations';

import { BlockHouse, HighriseBlock, HighriseProject, ProjectExtra } from '@/types';

import _ from 'underscore';

type State = {
  items: Record<string, ProjectExtra>;
};

export const mod: AsyncModule<State, any> = {
  namespaced: true,

  state: {
    items: {},
  },

  async: true,

  getters: {
    ['find'](state, getters, rootState, rootGetters) {
      return (id: number): HighriseProject => {
        return rootGetters['projects/details'](id);
      };
    },

    ['blocks'](state, getters) {
      return (id: number): HighriseBlock[] => {
        const project: HighriseProject = getters['find'](id);

        return project?.highriseGrid ?? [];
      };
    },

    ['houses'](state, getters) {
      return (id: number): BlockHouse[] => {
        const blocks: HighriseBlock[] = getters['blocks'](id);

        return blocks.flatMap(block => block.houses);
      };
    },
  },

  actionsAsync: {
    ['add:block']({ getters }, { id, block }: { id: number; block: HighriseBlock }) {
      const project: HighriseProject = getters.find(id);

      project.highriseGrid?.push(block);

      project.highriseGrid = _.sortBy(project.highriseGrid!, 'name');

      return this.dispatch('projects/item:update', project);
    },

    ['edit:block'](
      { getters },
      { id, block, index }: { id: number; block: HighriseBlock; index: number },
    ) {
      const project: HighriseProject = getters.find(id);

      project.highriseGrid?.splice(index, 1, block);

      project.highriseGrid = _.sortBy(project.highriseGrid!, 'name');

      return this.dispatch('projects/item:update', project);
    },

    ['remove:block']({ getters }, { id, index }: { id: number; index: number }) {
      const project: HighriseProject = getters.find(id);

      project.highriseGrid?.splice(index, 1);

      return this.dispatch('projects/item:update', project);
    },
  },
};

export default mod;
