import { TablePreset } from '@/types';

export function isTablePreset(obj: Record<string, any>): obj is TablePreset {
  const preset = obj as TablePreset;

  return !!preset.id && preset.columns instanceof Array;
}

export function isTablePresets(objs: any): objs is TablePreset[] {
  return objs instanceof Array && objs.every(obj => isTablePreset(obj));
}
