import { FunctionalComponentOptions } from 'vue/types';
import { Material } from '@/types';

type Props = {
  value: Material;
};

export default {
  functional: true,
  render(createElement, { props }) {
    return createElement(
      'span',
      props.value ? `${props.value?.brand} - ${props.value?.model}` : '',
    );
  },
} as FunctionalComponentOptions<Props>;
