import { AsyncModule } from 'vuex-async-mutations';
import { MeterStatus } from '@/types';

export default {
  namespaced: true,
  async: true,

  actionsAsync: {
    ['fetch:meterStatus']: {
      handler(context, { pchns, projectId }: { pchns: MeterStatus[]; projectId: Number }) {
        return this.$axios.post(`projects/${projectId}/meter-status`, {
          pchn: pchns,
        });
      },
    },
  },
} as AsyncModule<any, any>;
