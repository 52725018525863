
















import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    accept: {
      type: String,
      default: '*',
    },

    guard: {
      type: Function as PropType<(obj: any) => boolean>,
      default: () => () => true,
    },
  },

  data() {
    return {
      error: false,
    };
  },

  methods: {
    onFileChange(file: File) {
      this.error = false;
      this.readFile(file);
    },

    readFile(file: File) {
      const reader = new FileReader();

      reader.onload = e => {
        try {
          if (e.target?.result) {
            const data = JSON.parse(e.target.result.toString());

            if (this.guard(data)) {
              this.$emit('input', data);
              return;
            }
          }

          this.error = true;
        } catch (error) {
          this.error = true;
        }
      };

      reader.readAsText(file);
    },
  },
});
