import { AsyncModule } from 'vuex-async-mutations';

import details from '@/store/base.details';
import paginated from '@/store/base.items.paginated';
import table from '@/store/modules/tables';
import { Project } from '@/types';

import columns from '@/apps/office/components/table/installers/columns';
import presets from '@/apps/office/components/table/installers/presets';
import renderers from '@/apps/office/components/table/shared/items/renderers';

export default {
  namespaced: true,

  async: true,

  modules: {
    details: details<Project>('/dictionary/installers'),
    items: paginated<Project>('/dictionary/installers'),
    settings: table('installers', presets, columns, renderers),
  },

  getters: {},

  actionsAsync: {},
} as AsyncModule<any, any>;
