import { Column } from '@/types';

export default [
  {
    text: 'office.tables.headers.ID',
    title: 'office.tables.headers.id',
    align: 'left',
    sortable: true,
    value: 'id',
    width: 50,
  },
  { value: 'region', text: `office.tables.headers.region`, sortable: true },
  { value: 'createdAt', text: `office.tables.headers.createdAt`, sortable: true },
  { value: 'updatedAt', text: `office.tables.headers.updatedAt`, sortable: true },
  {
    value: 'startAt',
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true,
  },
  {
    value: 'planned.startAt',
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'active.startAt',
    text: `office.tables.headers.startAt`,
    hint: `office.tables.hints.startAt`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'endAt',
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true,
  },
  {
    value: 'planned.endAt',
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'active.endAt',
    text: `office.tables.headers.endAt`,
    hint: `office.tables.hints.endAt`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'duration',
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true,
  },
  {
    value: 'planned.duration',
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'active.duration',
    text: `office.tables.headers.duration`,
    hint: `office.tables.hints.duration`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'expectedHouses',
    text: `office.tables.headers.expectedHouses`,
    hint: `office.tables.hints.expectedHouses`,
    sortable: true,
  },
  {
    value: 'moInstallationsCount',
    text: `office.tables.headers.installationsCount`,
    hint: `office.tables.hints.moInstallationsCount`,
    sortable: false,
  },
  {
    value: 'moWorkQueue',
    text: `office.tables.headers.workQueue`,
    hint: `office.tables.hints.workQueue`,
    sortable: false,
  },
  {
    value: 'expectedWorkQueue',
    text: `office.tables.headers.expectedWorkQueue`,
    hint: `office.tables.hints.expectedWorkQueue`,
    sortable: false,
  },
  {
    value: 'expectedInstallations',
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true,
  },
  {
    value: 'planned.expectedInstallations',
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'active.expectedInstallations',
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallations`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'finished.expectedInstallations',
    text: `office.tables.headers.expectedInstallations`,
    hint: `office.tables.hints.expectedInstallationsPlanned`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'conversion',
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true,
  },
  {
    value: 'notConvertedYet',
    text: `office.tables.headers.pendingConversion`,
    hint: `office.tables.hints.pendingConversion`,
    sortable: true,
  },
  {
    value: 'planned.conversion',
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'active.conversion',
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversion`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'finished.conversion',
    text: `office.tables.headers.conversion`,
    hint: `office.tables.hints.conversionPlanned`,
    sortable: true,
    hidden: true,
  },
  {
    value: 'actualConversion',
    text: `office.tables.headers.actualConversion`,
    hint: `office.tables.hints.actualConversion`,
    sortable: true,
  },
  { value: 'accountManagerName', text: `office.tables.headers.accountManager`, sortable: true },
  { value: 'projectManagerName', text: `office.tables.headers.projectManager`, sortable: true },
  { value: 'code', text: `office.tables.headers.projectCode`, sortable: true },
  { value: 'year', text: `office.tables.headers.projectYear`, sortable: true },
  { value: 'sequence', text: `office.tables.headers.sequenceNumber`, sortable: true },
  { value: 'status', text: 'office.tables.headers.status', sortable: true },
  { value: 'type', text: `office.tables.headers.projectType`, sortable: true },
  { value: 'buildingType', text: `office.tables.headers.buildingType`, sortable: true },
  { value: 'installationType', text: `office.tables.headers.installationType`, sortable: true },
  {
    value: 'housingCooperativeName',
    text: `office.tables.headers.housingCooperative`,
    sortable: true,
  },
  { value: 'installerName', text: `office.tables.headers.installer`, sortable: true },
  {
    value: 'housesCount',
    text: `office.tables.headers.houses`,
    width: 75,
    align: 'right',
    sortable: false,
  },
  {
    value: 'housesCountFinal',
    text: `office.tables.headers.houses`,
    sortable: false,
    hidden: true,
  },
  {
    value: 'M200',
    text: 'M200',
    title: 'office.dictionaries.moProjectStates[0].text',
    hint: 'office.dictionaries.moProjectStates[0].text',
    width: 75,
  },
  {
    value: 'M221',
    text: 'M221',
    title: 'office.dictionaries.moProjectStates[1].text',
    hint: 'office.dictionaries.moProjectStates[1].text',
    width: 75,
  },
  {
    value: 'M230',
    text: 'M230',
    title: 'office.dictionaries.moProjectStates[2].text',
    hint: 'office.dictionaries.moProjectStates[2].text',
    width: 75,
  },
  {
    value: 'M250',
    text: 'M250',
    title: 'office.dictionaries.moProjectStates[3].text',
    hint: 'office.dictionaries.moProjectStates[3].text',
    width: 75,
  },
  {
    value: 'M299',
    text: 'M299',
    title: 'office.dictionaries.moProjectStates[4].text',
    hint: 'office.dictionaries.moProjectStates[4].text',
    width: 75,
  },
  {
    value: 'planned.installationStartAndEndDate',
    text: 'office.labels.periods.installationPeriod',
    hidden: true,
  },
  {
    value: 'planned.screeningStartAndEndDate',
    text: 'office.labels.periods.screeningPeriod',
    hidden: true,
  },
  {
    value: 'planned.conversionStartAndEndDate',
    text: 'office.labels.periods.conversionPeriod',
    hidden: true,
  },
  {
    value: 'planned.planningStartAndEndDate',
    text: 'office.labels.periods.planningPeriod',
    hidden: true,
  },
  {
    value: 'active.installationStartAndEndDate',
    text: 'office.labels.periods.installationPeriod',
    hidden: true,
  },
  {
    value: 'active.screeningStartAndEndDate',
    text: 'office.labels.periods.screeningPeriod',
    hidden: true,
  },
  {
    value: 'active.conversionStartAndEndDate',
    text: 'office.labels.periods.conversionPeriod',
    hidden: true,
  },
  {
    value: 'active.planningStartAndEndDate',
    text: 'office.labels.periods.planningPeriod',
    hidden: true,
  },
] as Column[];
