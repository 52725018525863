import Vue from "vue";
import { Nullable, Pagination, TableSortOptions } from "@/types";
import { ItemId, BaseItem, EndpointStrategy, Endpoint } from "./base";
import { AsyncModule } from "vuex-async-mutations";

type PaginatedState = {
  itemMap: Record<string, Record<number, Pagination<string>>>;
  lastFetch: Nullable<FetchParams>;
};

type FetchParams = {
  page?: number;
  relationId?: string;
  perPage?: number;
  query?: string;
  sort?: TableSortOptions;
  params?: Record<string, any>;
};

const stateFactory = () => ({
  itemMap: {},
  lastFetch: null
});

export const module = <T extends BaseItem>(
  endpoint: string | EndpointStrategy,
  idProp: ItemId = "id"
) =>
  ({
    state: stateFactory,

    mutations: {
      ["reset"](state) {
        Object.assign(state, stateFactory());
      }
    },

    getters: {
      ["get:total"](state) {
        return (relationId: string = "*") =>
          state.itemMap[relationId]?.[0]?.meta.total ?? 0;
      },

      ["page"](state, getters) {
        return (
          page: number = 1,
          relationId: string = "*"
        ): Pagination<T> | null => {
          if (state.itemMap[relationId] && state.itemMap[relationId][page]) {
            return {
              meta: state.itemMap[relationId][page].meta,
              data: state.itemMap[relationId][page].data
                .filter((id, index, items) => items.indexOf(id) === index)
                .map(id => getters.details(id))
                .filter(node => !!node)
            };
          }

          return null;
        };
      },

      ["lastFetch"](state) {
        return state.lastFetch;
      }
    },

    actions: {
      ["reset"]: {
        root: true,
        handler({ commit }) {
          commit("reset");
        }
      },

      ["get:all:page"](_context, fetchParams: FetchParams = {}) {
        let url: Endpoint;
        const {
          page = 1,
          relationId,
          query,
          perPage = 25,
          sort,
          params
        } = fetchParams;

        if (typeof endpoint !== "string") {
          url = endpoint(relationId);
        } else {
          url = { endpoint };
        }

        let sortOptions = {
          sortBy: sort?.sortBy?.map(
            (field, i) => `${field},${sort?.sortDesc[i] ? "desc" : "asc"}`
          )
        };

        return this.$axios.get<Pagination<T>>(`${url.endpoint}`, {
          params: {
            page,
            query: query === null || query === "" ? undefined : query,
            perPage: perPage,
            ...url.params,
            ...params,
            ...sortOptions
          }
        });
      }
    },

    actionsAsync: {
      ["fetch:selected"]: {
        async handler(
          { commitAsync, commit },
          {
            relationId,
            selectedIds
          }: { relationId?: string; selectedIds: number[] }
        ) {
          let url: Endpoint;

          if (typeof endpoint !== "string") {
            url = endpoint(relationId);
          } else {
            url = { endpoint };
          }

          const payload = this.$axios.post<Pagination<T>>(`${url.endpoint}`, {
            ...url.params,
            selectedIds,
            perPage: selectedIds.length || 1
          });

          const items: Pagination<T> = await commitAsync(
            "fetch:all:page",
            payload,
            {
              relationId
            }
          );

          items.data.forEach(item => commit("item:update", item));

          return items;
        }
      },

      ["list:all"]: {
        async handler(_context, fetchParams: FetchParams = {}) {
          let url: Endpoint;
          const {
            page = 1,
            relationId,
            query,
            perPage = 25,
            sort,
            params
          } = fetchParams;

          if (typeof endpoint !== "string") {
            url = endpoint(relationId);
          } else {
            url = { endpoint };
          }

          let sortOptions = {
            sortBy: sort?.sortBy?.map(
              (field, i) => `${field},${sort?.sortDesc[i] ? "desc" : "asc"}`
            )
          };

          return this.$axios.get<Pagination<number | string>>(
            `${url.endpoint}/list`,
            {
              params: {
                query: query === null || query === "" ? undefined : query,
                ...url.params,
                ...params,
                ...sortOptions
              }
            }
          );
        }
      },

      ["fetch:all:page"]: {
        async handler(
          { commit, dispatch, commitAsync },
          fetchParams: FetchParams = {}
        ) {
          fetchParams = fetchParams || {};
          const { page, relationId } = fetchParams;

          const payload = dispatch("get:all:page", fetchParams);

          const items: Pagination<T> = await commitAsync(payload, {
            page: page || 1,
            relationId,
            lastFetch: fetchParams
          });

          items.data.forEach(item => commit("item:update", item));

          return items;
        },

        pending(state, { lastFetch }: { lastFetch: FetchParams }) {
          state.lastFetch = lastFetch;
        },

        resolved(
          state,
          items: Pagination<T>,
          { page, relationId = "*" }: { page: number; relationId?: string }
        ) {
          if (page === 1 || !state.itemMap[relationId]) {
            Vue.set(state.itemMap, relationId, {});
          }

          Vue.set(state.itemMap[relationId], page, {
            meta: items.meta,
            updatedAt: Date.now(),
            data: items.data.map(item => item[idProp])
          });
        }
      }
    }
  } as AsyncModule<PaginatedState, any>);

export default module;
